import { XMarkIcon } from "@heroicons/react/24/outline";
import { PhoneArrowDownLeftIcon, PlusIcon } from "@heroicons/react/24/solid";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";

const SubscriptionFaq = ({ pageData }) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);
  const [open, setOpen] = useState(1);
  return (
    <div className="flex flex-col items-start px-4 lg:px-24 xl:px-64">
      <h1 className="block mt-5 font-urbanist font-bold text-xl md:text-3xl dark:text-white text-gray36 mb-3">
        {pageData?.subscriptionFaqSection?.heading}
      </h1>

      <p className="font-urbanist font-normal text-sm sm:text-base dark:text-white text-gray28 block mb-4 max-w-lg leading-6">
        {pageData?.subscriptionFaqSection?.description1}
      </p>

      {/* questions */}
      <div className="w-full flex flex-col items-start space-y-3 md:space-y-6 mt-7 md:mt-12">
        {pageData?.subscriptionFaqSection?.faqData.map((faq, index) => (
          <div
            key={index}
            className={`w-full flex flex-col space-y-4 py-7 dark:bg-purple2 px-4 md:px-8 cursor-pointer rounded-3xl ${
              open === index ? "bg-gray38" : "bg-gray37"
            }`}
            style={{
              background:
                open === index
                  ? !darkMode
                    ? "linear-gradient(#F5F4FF, #F5F4FF) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box"
                    : "linear-gradient(#270046, #270046) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box"
                  : "",
              border: open === index && "1px solid transparent",
            }}
            onClick={() => setOpen(index)}
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <span className="font-urbanist font-bold text-sm sm:text-base dark:text-white text-gray36">
                  {faq.question}
                </span>
              </div>

              {open === index ? (
                <div className="bg-purple7 flex items-center justify-center h-8 w-8 rounded-full">
                  <XMarkIcon className="h-5 text-white" />
                </div>
              ) : (
                <div className="bg-white flex items-center justify-center h-8 w-8 rounded-full">
                  <PlusIcon className="h-5 text-blue24" />
                </div>
              )}
            </div>

            {open === index && (
              <p className="font-urbanist text-sm dark:text-[#ABABAB] text-gray28 font-normal">
                {faq.answer}
              </p>
            )}
          </div>
        ))}
      </div>

      {/* contact button */}
      <div className="flex flex-col items-center w-full mt-6 md:mt-12">
        <p className="block mt-5 font-urbanist font-bold text-xl md:text-2xl dark:text-white text-black2 mb-8">
          {pageData?.subscriptionFaqSection?.subHeading?.split("&")[0]}{" "}
          <span
            style={{
              backgroundImage: darkMode
                ? "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)"
                : "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {pageData?.subscriptionFaqSection?.subHeading?.split("&")[1]}
          </span>
        </p>
        <Link to="/contact">
          <button
            className="px-5 py-3 text-center text-white font-urbanist font-semibold text-sm sm:text-base rounded-[50px] flex items-center"
            style={{
              background:
                "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
            }}
          >
            <PhoneArrowDownLeftIcon className="h-3 mr-2" />
            {pageData?.subscriptionFaqSection?.buttonText}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SubscriptionFaq;
