import React from "react";

// specification card component
const SpecificationCard = ({ title, description, image, index }) => {
  return (
    <div className="flex flex-col items-center py-10 px-5 md:px-7 dark:bg-purple2 bg-white rounded-xl dark:shadow-shadow11 shadow-shadow10 border dark:border-purple3 border-gray29">
      {/* spec image */}
      <div
        className={`${
          index === 0
            ? "bg-red1"
            : index === 1
            ? "bg-green1"
            : index === 2
            ? "bg-yellow1"
            : "bg-white"
        } py-2 px-2 lg:py-4 lg:px-3 dark:bg-purple5 rounded-xl flex items-center justify-center mb-5`}
      >
        <img
          loading="lazy"
          src={image}
          alt="spec"
          className="object-contain h-16 w-16"
        />
      </div>

      {/* spec title */}
      <span className="font-urbanist font-semibold text-sm md:text-base dark:text-white text-blue9 mb-2 text-center">
        {title}
      </span>

      {/* description */}
      <p className="font-urbanist font-normal text-xs sm:text-sm dark:text-white text-gray2 text-center">
        {description}
      </p>
    </div>
  );
};

export default SpecificationCard;
