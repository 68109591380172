import React, { Fragment, useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../context/user";
import { getURLs } from "../urlConfig";
import BasicInformation from "../components/InstructorCourses/CourseSteps/BasicInformation";
import CoursesMedia from "../components/InstructorCourses/CourseSteps/CoursesMedia";
import Curriculum from "../components/InstructorCourses/CourseSteps/Curriculum";
import Settings from "../components/InstructorCourses/CourseSteps/Settings";

const EditCourse = () => {
  // fetching user conext
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const { state } = useLocation();

  //   navigator
  const navigate = useNavigate();

  const [formData, setFormData] = useState(state);

  // submit button disabled state
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);
    axios
      .put(
        getURLs("edit-course"),
        { updatedCourseData: formData },
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSubmitDisabled(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitDisabled(false);
      });
  };

  return (
    <Fragment>
      <section className="py-4 lg:py-6 bg-purple1">
        <div className="lg:flex items-center justify-between px-10 py-6">
          <div className="mb-4 mb-lg-0">
            <h1 className="text-white mb-1 text-3xl font-inter font-semibold">
              Edit Course
            </h1>
            <p className="mb-0 text-white text-xl font-inter font-medium">
              Edit the fields below and click on save to save the updated
              detials
            </p>
          </div>
          <button className="text-black px-4 py-3 bg-white rounded-md font-inter font-medium text-base w-max hover:scale-105">
            <Link to="/instructor/instructor-my-courses/" replace>
              Back to Course
            </Link>
          </button>
        </div>
      </section>
      <div className="flex flex-col bg-white md:px-6 px-0">
        <BasicInformation
          data={formData}
          handleChange={handleChange}
          usedForEdit
        />

        <CoursesMedia
          data={formData}
          handleChange={handleChange}
          userInfo={userInfo}
          usedForEdit
        />

        <Curriculum data={formData} handleChange={handleChange} usedForEdit />

        <Settings
          data={formData}
          handleChange={handleChange}
          usedForEdit
          disableButton={submitDisabled}
          handleSubmit={handleSubmit}
        />
      </div>

      {/* <SubmitSuccessModal
    showModal={courseSend}
    closeModal={() => setCourseSend(false)}
    message="Course created and send for review Successfully"
  /> */}
    </Fragment>
  );
};

export default EditCourse;
