import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/user";
import { InstructorDataContext } from "../context/instructorData";
import axios from "axios";
import { getURLs } from "../urlConfig";
import BasicInformation from "../components/InstructorCourses/CourseSteps/BasicInformation";
import CoursesMedia from "../components/InstructorCourses/CourseSteps/CoursesMedia";
import Curriculum from "../components/InstructorCourses/CourseSteps/Curriculum";
import Settings from "../components/InstructorCourses/CourseSteps/Settings";
import SubmitSuccessModal from "../components/SubmitSuccessModal/SubmitSuccessModal";
import StepController from "../components/StepController";

const AddNewCourse = () => {
  // getting user from context
  const {
    state: { userInfo },
  } = useContext(UserContext);

  // fetching course context
  const { addCourse } = useContext(InstructorDataContext);

  // fetching app data context
  // const { updateAllCourses } = useContext(AppDataContext);
  // router
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  // course data state
  const [formData, setFormData] = useState({
    category: "",
    field: "",
    image: "",
    videoUrl: "",
    duration: "",
    title: "",
    course_description: "",
    curriculum: [],
    tags: [],
    level: "",
    price: 0,
  });
  // course submitted successfully state
  const [courseSend, setCourseSend] = useState(false);

  // submit button disabled state
  const [submitDisabled, setSubmitDisabled] = useState(false);
  // handle form data change
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  // next step handler
  const next = () => {
    setCurrentStep(currentStep === 4 ? 1 : currentStep + 1);
  };
  // prev step handler
  const previous = () => {
    setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
  };

  // handle course submission
  const handleSubmit = () => {
    setSubmitDisabled(true);
    axios
      .post(
        getURLs("add-course"),
        { courseData: formData },
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          addCourse(res?.data?.courseAdded);
          // updateAllCourses(res?.data?.courseAdded);
          navigate("/instructor/instructor-my-courses/", {
            replace: true,
          });
          setCourseSend(true);
          setSubmitDisabled(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitDisabled(false);
      });
  };

  // course submission steps
  const steps = [
    {
      id: 1,
      title: "Basic Information",
      content: (
        <BasicInformation
          data={formData}
          handleChange={handleChange}
          next={next}
        />
      ),
    },
    {
      id: 2,
      title: "Courses Media",
      content: (
        <CoursesMedia
          data={formData}
          handleChange={handleChange}
          next={next}
          previous={previous}
          userInfo={userInfo}
        />
      ),
    },
    {
      id: 3,
      title: "Curriculum",
      content: (
        <Curriculum
          data={formData}
          handleChange={handleChange}
          next={next}
          previous={previous}
        />
      ),
    },
    {
      id: 4,
      title: "Settings",
      content: (
        <Settings
          data={formData}
          handleChange={handleChange}
          next={next}
          previous={previous}
          handleSubmit={handleSubmit}
          disableButton={submitDisabled}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <section className="py-4 lg:py-6 bg-purple1">
        <div className="lg:flex items-center justify-between px-10 py-10">
          <div className="mb-4 lg:mb-0">
            <h1 className="text-white mb-1 text-3xl font-inter font-semibold">
              Add New Course
            </h1>
            <p className="mb-0 text-white text-xl font-inter font-medium">
              Just fill the form and create your courses.
            </p>
          </div>
          <button className="text-black px-4 py-3 bg-white rounded-md font-inter font-medium text-base w-max hover:scale-105">
            <Link to="/instructor/instructor-my-courses/">Back to Course</Link>{" "}
          </button>
        </div>
      </section>
      <StepController currentStep={currentStep} steps={steps} />

      <SubmitSuccessModal
        showModal={courseSend}
        closeModal={() => setCourseSend(false)}
        message="Course created and send for review Successfully"
      />
    </Fragment>
  );
};

export default AddNewCourse;
