import React from "react";
import ShowAnimatedText from "../ShowAnimatedText/ShowAnimatedText";
import MarkdownRenderWithMath from "../../MarkdownRender/MarkdownRenderWithMath";
// import MarkdownRender from "../../MarkdownRender/MarkdownRender";

const ShowAnswer = ({ answer, animate }) => {
  return (
    <div className="flex flex-col dark:text-white font-normal font-urbanist text-sm">
      {/* if we have our search result map it in a list */}
      {!animate ? (
        <MarkdownRenderWithMath text={answer} />
      ) : (
        <ShowAnimatedText text={answer} />
      )}
      {/* render the images */}
      {/* <div className="grid gird-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-3 w-full text-center md:text-start mt-2">
        {answer?.images?.map((imageLink, i) => (
          <div
            key={i}
            className="w-full bg-bgColor2 px-2 py-2 rounded-lg shadow-shadow2"
          >
            <img
              loading="lazy"
              src={imageLink}
              alt="notFound"
              className="w-full h-32 object-contain"
            />
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ShowAnswer;
