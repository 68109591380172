import React, { Fragment, useContext, useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import ReactQuillEditor from "../ReactQuillEditor";
import FormSelect from "../FormSelect";
import GKTagsInput from "../GKTagsInput";
import axios from "axios";
import { getURLs } from "../../urlConfig";
import { UserContext } from "../../context/user";
import ImageUploadingModal from "../ImageUploadingModal";
import { AppDataContext } from "../../context/appData";

const AddLectureContent = ({
  currentObj,
  setCourseCurriculum,
  setShowArticleModal,
  showArticleModal,
  setCurrentObj,
  id,
}) => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [lectureContent, setLectureContent] = useState(
    currentObj?.lectureContent
  );

  const [contentUploading, setContentUploading] = useState(false);
  const [file, setFile] = useState();
  const [url, setUrl] = useState();
  const [uploadingError, setUploadingError] = useState({
    show: false,
    message: "",
  });

  const handleClose = () => {
    setShowArticleModal(false);
    setCurrentObj({});
  };

  const addLectureContent = (e) => {
    e.preventDefault();

    let updatedSections;
    setCourseCurriculum((prev) => (updatedSections = [...prev]));
    for (let section of updatedSections) {
      if (section.id === id) {
        for (let lecture of section.lectures) {
          if (lecture.id === currentObj?.id) {
            lecture.lectureContent = lectureContent;
            break; // Stop searching once found
          }
        }
        break; // Stop searching once found
      }
    }

    setCourseCurriculum(updatedSections);
    handleClose();
  };

  const handleChange = (e) => {
    setLectureContent({ ...lectureContent, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      setFile(e.target.files[0]);
      axios
        .post(
          getURLs("image-upload-url"),
          { imageFor: "lectureVideos" },
          {
            headers: { "auth-token": userInfo?.authToken },
            withCredentials: true,
          }
        )
        .then((res) => {
          setUrl(res?.data?.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUpload = async () => {
    if (file) {
      // have to do it fetch for s3.
      setContentUploading(true);
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file,
      })
        .then((res) => {
          const content = url?.split("?")[0];
          handleChange({
            target: { name: "videoLink", value: content },
          });
          setContentUploading(false);
          setUploadingError({ show: false, message: "" });
        })
        .catch((err) => {
          console.log(err);
          setContentUploading(false);
          setUploadingError({
            show: true,
            message: err?.message || "Failed to upload Image",
          });
        });
    } else {
      setUploadingError({
        show: true,
        message: "Please select an image to upload",
      });
    }
  };

  return (
    <Fragment>
      <CustomModal show={showArticleModal} onHide={handleClose} centered>
        {showArticleModal && (
          <div className="flex flex-col space-y-1 overflow-y-auto w-full">
            {/* header */}
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Add Lecture Content
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={handleClose}
              />
            </div>

            {/* body */}
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <form onSubmit={addLectureContent}>
                <div className="mb-5">
                  {/* lesson title */}
                  <div className="mb-5 flex flex-col space-y-1">
                    <label
                      htmlFor="lesson-title"
                      className="font-medium font-inter text-black/90 text-sm"
                    >
                      Lesson Title
                    </label>
                    <input
                      placeholder="Lesson title"
                      id="lesson-title"
                      name="lessonTitle"
                      value={lectureContent?.lessonTitle}
                      onChange={handleChange}
                      className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18"
                    />
                  </div>
                  {/* video link */}
                  <div className="mb-5 flex flex-col space-y-1">
                    <label
                      htmlFor="video-link"
                      className="font-medium font-inter text-black/90 text-sm"
                    >
                      Paste Video Link
                    </label>

                    <input
                      placeholder="Video Link"
                      id="video-link"
                      name="videoLink"
                      value={lectureContent?.videoLink}
                      onChange={handleChange}
                      className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18"
                    />
                    <p className="w-full text-center my-2 block font-inter font-medium text-black/80 text-sm">
                      OR
                    </p>

                    <div className="flex flex-col space-y-1">
                      <span className="font-medium font-inter text-black/90 text-sm">
                        Upload here
                      </span>
                      <div className="flex items-center justify-between">
                        <input
                          id="inputfavicon"
                          type="file"
                          className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 flex-1 "
                          onChange={handleFileChange}
                        />
                        <label
                          className="cursor-pointer border border-gray19 outline-none rounded-tr-md rounded-br-md text-base text-gray20 px-4 py-2.5"
                          onClick={handleUpload}
                        >
                          Upload
                        </label>
                      </div>
                      <p className="text-xs text-gray20 font-inter font-medium">
                        Upload your lecture video here. It must meet our course
                        video quality standards to be accepted.
                      </p>
                      <p className="text-xs text-gray20 font-inter font-medium">
                        Please click the upload button to upload
                      </p>
                      <p>
                        {uploadingError?.show && (
                          <span className="login_error_text">
                            {uploadingError?.message}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="mb-5 flex flex-col space-y-1">
                    <label
                      htmlFor="lesson-content"
                      className="font-medium font-inter text-black/90 text-sm"
                    >
                      Lesson Content
                    </label>
                    <ReactQuillEditor
                      initialValue={lectureContent?.lessonContent || ``}
                      id="lesson-content"
                      name="lessonContent"
                      onChange={handleChange}
                      style={{ height: "200px", marginBottom: "40px" }}
                    />
                  </div>

                  <div className="flex flex-col space-y-1 mb-5">
                    <label
                      htmlFor="lesson-category"
                      className="font-medium font-inter text-black/90 text-sm"
                    >
                      Lesson Category
                    </label>
                    <div className="w-full px-4 py-2 border border-gray19 rounded-md">
                      <FormSelect
                        options={
                          appData?.courseCreationHelperData?.categoryOptions
                        }
                        id="courses-categories"
                        name="courseCategory"
                        placeholder="Select Category"
                        defaultselected={lectureContent?.courseCategory}
                        onChange={handleChange}
                        className="text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full outline-none"
                      />
                    </div>
                  </div>

                  <div className="mb-3 flex flex-col space-y-1">
                    <span className="font-medium font-inter text-black/90 text-sm">
                      Lesson Tags
                    </span>
                    <GKTagsInput
                      name="lessonTags"
                      defaulttags={lectureContent?.lessonTags || []}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                {/* footer */}
                <div className="flex items-center justify-end space-x-4 pb-5 w-full px-4">
                  <button className="bg-purple1 px-4 py-2 text-white font-inter font-medium rounded-md text-sm">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </CustomModal>

      <ImageUploadingModal
        modalOpen={contentUploading}
        message={` Please wait your video is being uploaded...`}
      />
    </Fragment>
  );
};

export default AddLectureContent;
