import React, { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../context/appData";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import AuthImageWithHeading from "../components/AuthImageWithHeading";

const ForgetPassword = () => {
  // app info
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const navigate = useNavigate();

  const [emailInput, setEmailInput] = useState("");
  const [formError, setFormError] = useState({ show: false, message: "" });
  const [otpRequest, setOtpRequest] = useState(false);

  const handleRequestSend = (e) => {
    e.preventDefault();
    if (emailInput.trim(" ").length === 0) {
      setFormError({
        show: true,
        message: "Please enter a valid email address",
      });

      return;
    }
    setOtpRequest(true);
    axios
      .post(
        getURLs("forgot-password"),
        { email: emailInput },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/verify-otp", { state: { email: emailInput } });
          setOtpRequest(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setOtpRequest(false);
      });
  };

  useEffect(() => {
    setFormError({ show: false, message: "" });
  }, [emailInput]);

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center lg:items-start py-5 dark:bg-darkBgColor1">
      <AuthImageWithHeading
        imageSrc={appData?.loginPageData?.sectionImage}
        heading={appData?.loginPageData?.heading}
      />

      <div className="lg:basis-1/2 w-full flex flex-col items-center relative mt-4 lg:mt-0">
        <form
          className="z-10 flex flex-col justify-center border dark:border-none bg-white dark:bg-purple2 shadow-shadow13 dark:shadow-none rounded-[30px] w-full max-w-md px-6 py-8"
          onSubmit={handleRequestSend}
        >
          <h2 className="font-urbanist font-bold text-lg dark:text-white text-black block mb-5">
            Forgot Password
          </h2>

          <p className="font-medium font-urbanist dark:text-white text-black2 text-sm block mb-3">
            Reset Your Password
          </p>
          <p className="font-urbanist font-medium text-sm text-gray-500 block mb-10">
            Enter the email address associated with your account and we will
            send you a code to reset your password.
          </p>

          <span className="font-urbanist font-semibold dark:text-white text-black text-base block mb-1">
            Email Id
          </span>
          {/*  email input */}
          <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-5">
            <EnvelopeIcon className="text- h-4 w-4 dark:text-white" />
            <input
              className="flex-grow rounded-lg outline-none px-2 py-2 text-sm placeholder:text-xs font-inter font-normal text-gray-600 dark:text-white bg-transparent"
              type="email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
            />
          </div>

          {formError.show && (
            <span className="font-urbanist font-medium text-sm text-red-500 block mb-5">
              {formError.message}
            </span>
          )}

          <div className="w-full flex items-center justify-center mt-5">
            <button
              className="w-full px-2 py-2 mt-4 max-w-xl font-urbanist font-bold text-white text-xs md:text-base xl:text-lg rounded-lg"
              onClick={handleRequestSend}
              style={{
                background: !otpRequest
                  ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                  : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
              }}
              disabled={otpRequest}
            >
              Continue
            </button>
          </div>

          <Link to={`/login`} replace className="text-center">
            <span className="text-blue-500 font-urbanist font-medium text-sm">
              Back to login
            </span>
          </Link>
        </form>
        <div className="absolute top-24 bottom-0 bg-purple11 blur-[120px] w-full h-full dark:bg-neonPurpleColor dark:opacity-60" />
      </div>
    </div>
  );
};

export default ForgetPassword;
