import React from "react";

const AdminCard = ({ heading, Icon, mainText, subText, handleClick }) => {
  return (
    <div
      className="bg-white shadow-md rounded-md px-4 py-2 cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex items-start justify-between space-x-2">
        <p className="font-inter font-normal text-xs md:text-sm text-gray-600 block mb-5">
          {heading.toUpperCase()}
        </p>
        <Icon className="h-4 w-4 text-purple-600" />
      </div>

      <div>{mainText}</div>

      <div>{subText}</div>
    </div>
  );
};

export default AdminCard;
