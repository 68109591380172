import React, { useEffect, useRef } from "react";

const CustomModal = ({ show, onHide, centered = false, children }) => {
  const ref = useRef();
  // useEffect(() => {
  //   if (show) {
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         onHide();
  //       }
  //     }
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }
  // }, [ref, show]);

  useEffect(() => {
    show
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  return (
    <div
      className={`${
        show
          ? `opacity-100 fixed top-0 left-0 bottom-0 bg-black/40 flex z-[30] px-4 sm:px-0 ${
              centered ? "items-center" : "items-start"
            } items-start justify-center h-screen w-screen !m-0`
          : "opacity-0 h-0 w-0 "
      } cursor-auto`}
    >
      <div
        ref={ref}
        className={` ${
          show
            ? "opacity-100 flex flex-col items-center bg-white justify-center mx-4 my-2 z-[40] max-h-[80%] w-full max-w-[500px] rounded-md md:rounded-xl translate-y-0"
            : "opacity-0 h-0"
        } ease-in-out duration-300 transform transition-all`}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
