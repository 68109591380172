import React, { useContext, useEffect, useState } from "react";
import AdminCard from "../../components/AdminCard";
import {
  AcademicCapIcon,
  BeakerIcon,
  BookOpenIcon,
  CalculatorIcon,
  HandRaisedIcon,
} from "@heroicons/react/24/outline";
import { UserContext } from "../../context/user";
import axios from "axios";
import { getBotURLs, getURLs } from "../../urlConfig";
import { AppDataContext } from "../../context/appData";
import { useNavigate } from "react-router-dom";
import ImageUploadingModal from "../../components/ImageUploadingModal";

const AdminDashboard = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const {
    state: { allCoursesData },
    initializeAllCoursesData,
  } = useContext(AppDataContext);

  const navigate = useNavigate();

  const [updatingBotState, setUpdatingBotState] = useState(false);

  const sendUpdateBotRequest = (botname) => {
    setUpdatingBotState(true);

    axios
      .post(
        getBotURLs("update", { botname }),
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
        setUpdatingBotState(false);
      })
      .catch((err) => {
        setUpdatingBotState(false);
        console.log(err);
      });
  };

  useEffect(() => {
    const isAdmin = userInfo?.role?.find((data) => data === "admin");

    if (
      Object.keys(userInfo)?.length &&
      isAdmin &&
      allCoursesData?.length === 0
    ) {
      axios
        .get(getURLs("get-all-courses"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          initializeAllCoursesData(res.data?.AllCoursesData);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (Object.keys(userInfo)?.length && !isAdmin) {
      navigate("/login");
    }
  }, [userInfo, allCoursesData]);

  return (
    <div className="w-full flex flex-col py-6 px-5 bg-bgColor3">
      <h1 className="block mb-5 font-semibold font-inter text-black text-base sm:text-xl lg:text-2xl">
        Dashboard
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
        <AdminCard
          heading="Courses"
          Icon={BookOpenIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-inter text-black text-base sm:text-xl lg:text-2xl">
              {allCoursesData?.length}
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-inter">
              {/* <span className="text-green-500 mr-2">{`+20.9$`}&nbsp;</span> */}
              {`Number of Courses`}
            </p>
          }
          handleClick={() => navigate("/admin/all-courses")}
        />

        <AdminCard
          heading="Update"
          Icon={CalculatorIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-inter text-black text-base sm:text-lg lg:text-xl">
              Update Math Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-inter">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("math")}
        />

        <AdminCard
          heading="Update"
          Icon={BeakerIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-inter text-black text-base sm:text-lg lg:text-xl">
              Update Science Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-inter">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("science")}
        />

        <AdminCard
          heading="Update"
          Icon={AcademicCapIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-inter text-black text-base sm:text-lg lg:text-xl">
              Update English Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-inter">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("english")}
        />

        <AdminCard
          heading="Update"
          Icon={HandRaisedIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-inter text-black text-base sm:text-lg lg:text-xl">
              Update Social Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-inter">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("social")}
        />
      </div>

      <ImageUploadingModal
        modalOpen={updatingBotState}
        message={`Bot is being updated, Please wait...`}
      />
    </div>
  );
};

export default AdminDashboard;
