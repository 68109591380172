import React from "react";
import SideBG from "../../images/sidesbg.png";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "./Features.css";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../ImageLoader/ImageLoader";

const Features = ({ appData }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center space-x-6 px-4 lg:px-16 xl:px-64 relative pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <ImageLoader
        webpImageUrl={appData?.approachPageData?.featuresSection?.imageWebp}
        pngImageUrl={appData?.approachPageData?.featuresSection?.image}
        styles="w-full h-[450px] object-contain hidden md:inline-flex"
        alt="feature_img"
      />

      <div className="flex flex-col items-start">
        <span className="font-inter font-medium text-base text-blue17 block mb-5">
          {appData?.approachPageData?.featuresSection?.smallText}
        </span>

        <h2 className="text-lg md:text-xl font-inter font-semibold text-blue25 block mb-5">
          {appData?.approachPageData?.featuresSection?.heading}
        </h2>

        <p className="font-montserrat font-normal text-xs text-blue14 block mb-8">
          {appData?.approachPageData?.featuresSection?.subtext}
        </p>

        <h3 className="font-inter font-bold text-base md:text-lg text-blue25 block mb-4">
          {appData?.approachPageData?.featuresSection?.featuresData?.title}
        </h3>

        {appData?.approachPageData?.featuresSection?.featuresData?.data?.map(
          (item) => (
            <div className="flex items-center space-x-4 mb-2" key={item}>
              <CheckIcon className="text-blue17 h-4" />
              <p className="font-inter font-medium text-base md:text-sm">
                {item}
              </p>
            </div>
          )
        )}

        <div
          className="w-max px-5 py-2 rounded-md bg-inherit flex items-center justify-center mt-6 btn-gradient-2 cursor-pointer"
          onClick={() => navigate("/login")}
        >
          {appData?.approachPageData?.featuresSection?.featuresData?.buttonText}
          <ChevronRightIcon className="text-blue25 h-4 ml-2" />
        </div>
      </div>
    </div>
  );
};

export default Features;
