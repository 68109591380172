import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { Helmet } from "react-helmet-async";
import { AppDataContext } from "../context/appData";
import DashboardHeader from "../components/DashboardHeader";
import DashboardSidebar from "../components/DashboardSidebar";
import UserDashboard from "../components/UserDashboard";
import Hero from "../components/Hero";
// import ShowAllCourses from "../components/ShowAllCourses";
// import ShowAllQuizes from "../components/ShowAllQuizes";
// import AttemptedQuizResults from "../components/AttemptedQuizResults";
import { getBotIds, setBotIds } from "../utils/utils";
import ShowAllCourses from "../components/ShowAllCourses";

const Dashboard = () => {
  // navigate state
  const navigate = useNavigate();
  // user info context
  const {
    state: { userInfo, searchesLeftForUser, userBotInfo },
    updateSearchesLeftForUser,
    logoutUser,
    updateUserBotInfo,
    updateUserBotIdInfo,
  } = useContext(UserContext);

  // app data context
  const {
    state: { appData, allCoursesData, allQuizesData, externalCoursesData },
    // initializeAllCoursesData,
    initializeAllQuizesData,
    // initializeAllExternalCoursesData,
  } = useContext(AppDataContext);

  const [loading, setLoading] = useState(true);

  // selected tab
  const [selectedTab, setSelectedTab] = useState(1);

  const handleLogout = () => {
    axios
      .post(
        getURLs("logoutUrl"),
        {},
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("persist", false);
          logoutUser();
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const isPersist = JSON.parse(localStorage.getItem("persist"));
    if (!Object.keys(userInfo).length && !isPersist) {
      navigate("/login", {
        replace: true,
      });
    }
    setLoading(false);
  }, [userInfo]);

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      axios
        .get(getURLs("searches-left"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          updateSearchesLeftForUser(res?.data?.freeSearchesLeft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    // if (
    //   (allCoursesData?.length === 0 || externalCoursesData.length === 0) &&
    //   Object.keys(userInfo).length
    // ) {
    //   axios
    //     .get(getURLs("get-all-courses"), {
    //       headers: {
    //         "auth-token": userInfo?.authToken,
    //       },
    //     })
    //     .then((res) => {
    //       initializeAllCoursesData(res.data?.AllCoursesData);
    //       initializeAllExternalCoursesData(res.data?.externalCourses);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
    if (allQuizesData?.length === 0 && Object.keys(userInfo).length) {
      axios
        .get(getURLs("get-all-quizes"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          initializeAllQuizesData(res.data?.AllQuizesData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    userInfo,
    allCoursesData?.length,
    allQuizesData?.length,
    externalCoursesData.length,
  ]);

  // check for bot's and set
  const checkBotIdsAndSet = () => {
    Promise.all([
      getBotIds("math"),
      getBotIds("science"),
      getBotIds("english"),
      getBotIds("social"),
      getBotIds("french"),
      getBotIds("spanish"),
      getBotIds("counselor"),
    ])
      .then((res) => {
        const botIdsInfo = {
          mathBotId: res[0],
          scienceBotId: res[1],
          englishBotId: res[2],
          socialBotId: res[3],
          frenchBotId: res[4],
          spanishBotId: res[5],
          counselorBotId: res[6],
        };
        setBotIds(botIdsInfo, userInfo?.authToken);
        updateUserBotIdInfo({ ...botIdsInfo });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // check for bot id exist for the user
  useEffect(() => {
    if (Object.keys(userInfo)?.length && !Object.keys(userBotInfo)?.length) {
      const botInfo = sessionStorage.getItem("botInfo");
      if (botInfo) {
        updateUserBotInfo(JSON.parse(botInfo));
      } else {
        checkBotIdsAndSet();
      }
      // axios
      //   .get(getURLs("get-bot-info"), {
      //     withCredentials: true,
      //     headers: {
      //       "auth-token": userInfo?.authToken,
      //     },
      //   })
      //   .then((res) => {
      //     const botInfo = res.data?.userBotInfo;
      //     if (botInfo != null) {
      //       updateUserBotInfo(botInfo);
      //     } else {
      //       checkBotIdsAndSet();
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }, [userInfo, userBotInfo]);

  // useEffect(() => {
  //   if (!appData && !Object.keys(appData || {}).length) {
  //     axios
  //       .get(getURLs("appInfo"))
  //       .then((res) => {
  //         initializeAppData(res?.data?.applicationData);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, []);
  return (
    !loading && (
      <>
        <Helmet>
          <title>Vaisage - Dashboard</title>
          <link rel="canonical" href="https://www.vaisage.com/dashboard" />
        </Helmet>

        <div className="flex flex-col bg-white dark:bg-darkBgColor1">
          <DashboardHeader
            pageData={appData?.homePageData}
            userInfo={userInfo}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            searchesLeft={searchesLeftForUser}
            handleLogout={handleLogout}
          />
          <div className="flex h-full">
            <div className="hidden lg:basis-1/4 w-full h-full lg:inline-flex">
              <DashboardSidebar
                pageData={appData?.homePageData}
                userInfo={userInfo}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                searchesLeft={searchesLeftForUser}
                handleLogout={handleLogout}
              />
            </div>
            <div className="lg:basis-4/5 rounded-md w-full px-2 lg:pr-2">
              {selectedTab === 1 && (
                <UserDashboard
                  allCoursesData={allCoursesData}
                  userInfo={userInfo}
                  pageData={appData}
                  searchesLeft={searchesLeftForUser}
                  setSelectedTab={setSelectedTab}
                  externalCourses={externalCoursesData}
                />
              )}
              {selectedTab === 2 && (
                <ShowAllCourses
                  userInfo={userInfo}
                  allCoursesData={allCoursesData}
                  externalCourses={externalCoursesData}
                />
              )}
              {/* {selectedTab === 3 && (
                <ShowAllQuizes
                  userInfo={userInfo}
                  allQuizesData={allQuizesData}
                />
              )}
              {selectedTab === 4 && <AttemptedQuizResults />} */}
              {selectedTab === 5 && <Hero useInDashboard tutor={"math"} />}
              {selectedTab === 6 && <Hero useInDashboard tutor={"science"} />}
              {selectedTab === 7 && <Hero useInDashboard tutor={"english"} />}
              {selectedTab === 8 && <Hero useInDashboard tutor={"social"} />}
              {selectedTab === 9 && <Hero useInDashboard tutor={"french"} />}
              {selectedTab === 10 && <Hero useInDashboard tutor={"spanish"} />}
              {selectedTab === 11 && (
                <Hero useInDashboard tutor={"counselor"} />
              )}
            </div>
          </div>
        </div>

        {/* <div className="bg-bgColor2 w-full flex flex-col px-4 lg:px-16 xl:px-64">
          <h3 className="text-xl lg:text-2xl xl:text-4xl text-blue4 font-inter font-semibold mb-2 w-full text-center lg:text-start mt-10">
            Hi {userInfo?.firstName},
          </h3>

          <div className="flex flex-col items-center lg:flex-row lg:space-x-10 mt-6 pb-4">
            
            <section className="w-full lg:w-max pb-8 lg:pb-14 basis-1/3 flex items-center justify-center max-w-xl">
              <ProfileSection userInfo={userInfo} searchesLeft={searchesLeft} />
            </section>

            <section className="grid grid-cols-1 gap-5 basis-2/3 max-w-xl w-full pb-8 lg:pb-32">
              
              <section className="w-full">
                <SearchHistorySection userInfo={userInfo} />
              </section>

              
              <section className="w-full ">
                <PaymentHistorySection userInfo={userInfo} />
              </section>
            </section>
          </div>
        </div> */}
      </>
    )
  );
};

export default Dashboard;
