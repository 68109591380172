import { Bars3Icon, UserCircleIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardSidebar from "../DashboardSidebar";
import { DarkModeContext } from "../../context/darkModeContext";

const DashboardHeader = ({
  pageData,
  userInfo,
  selectedTab,
  handleLogout,
  setSelectedTab,
  searchesLeft,
}) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const navigate = useNavigate();

  const [openDashboardSidebar, setOpenDashboardSidebar] = useState(false);

  useEffect(() => {
    setOpenDashboardSidebar(false);
  }, [selectedTab]);

  return (
    <>
      <div className="sticky top-0 flex items-center justify-between dark:bg-darkBgColor1 bg-white px-4 py-3 z-10">
        <div className="flex items-center space-x-4 lg:basis-1/4 w-full">
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => navigate("/")}
          >
            {Object.keys(pageData || {}).length ? (
              <img
                loading="lazy"
                src={darkMode ? pageData?.darkModeLogoUrl : pageData?.logoUrl}
                alt="logo"
                className="w-44 h-14 lg:h-16 object-contain"
              />
            ) : (
              <span>Loading...</span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end space-x-2 lg:basis-3/4 w-full">
          {/* <div className="hidden lg:flex dark:bg-purple2 bg-gray9 rounded-md px-2 items-center flex-1">
            <input
              placeholder="Search Courses"
              className="bg-inherit flex-1 w-full py-3 text-sm outline-none border-none placeholder:text-gray30 text-gray30 font-urbanist font-medium"
            />

            <MagnifyingGlassIcon className="text-gray30 w-5 h-5" />
          </div>

          <div className="hidden lg:flex items-center space-x-4">
            <div className="dark:bg-purple2 bg-gray9 rounded-md px-4 py-3 flex items-center font-urbanist text-sm font-semibold text-black dark:text-white">
              Select Grade
              <ChevronDownIcon className="dark:text-white text-black w-4 h-4 ml-2" />
            </div>

            <div className="dark:bg-purple2 bg-gray9 rounded-md px-4 py-3 flex items-center font-urbanist text-sm font-semibold text-black dark:text-white">
              Select Subject
              <ChevronDownIcon className="dark:text-white text-black w-4 h-4 ml-2" />
            </div>
          </div> */}

          <div
            className="lg:hidden border rounded-3xl cursor-pointer"
            style={{
              background:
                "linear-gradient(white, white) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
              border: "2px solid transparent",
            }}
            onClick={() => navigate("/my-profile")}
          >
            <div className="px-4 py-1 flex items-center space-x-2 bg-white dark:bg-purple2 rounded-[40px]">
              {userInfo?.profileImage ? (
                <img
                  loading="lazy"
                  src={userInfo?.profileImage}
                  alt="profile"
                  className="h-8 w-8 object-cover rounded-full"
                />
              ) : (
                <UserCircleIcon className="h-8 text-gray16 dark:text-white" />
              )}

              <p className="font-inter font-bold text-sm dark:text-white text-blue34 whitespace-nowrap truncate max-w-[100px]">
                {userInfo?.name}
              </p>
            </div>
          </div>

          {/* mobile menu icon */}
          <div
            className="p-4 flex items-center justify-end lg:hidden rounded-lg cursor-pointer"
            style={{
              background: "inherit",
            }}
            onClick={() => setOpenDashboardSidebar((prev) => !prev)}
          >
            <Bars3Icon className={`dark:text-white text-black h-7 w-7`} />
          </div>
        </div>
      </div>

      <div
        className={`${
          openDashboardSidebar
            ? "opacity-100 translate-x-0"
            : "opacity-0 -translate-x-[500px]"
        } fixed top-20 left-0 bottom-0 transition-all ease-in-out duration-300 overflow-scroll dark:bg-darkBgColor1 bg-white z-[50] inline-flex lg:hidden`}
      >
        <div className="mb-24" />
        <DashboardSidebar
          pageData={pageData}
          userInfo={userInfo}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          searchesLeft={searchesLeft}
          handleLogout={handleLogout}
        />
      </div>
    </>
  );
};

export default DashboardHeader;
