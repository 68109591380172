import React from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import PaypalLogo from "../../../../images/paypal.svg";
import StripeLogo from "../../../../images/stripe.svg";

const ActivePlan = ({ planData, activePlanDetails }) => {
  return (
    <div
      className={`relative border dark:border-purple3 dark:bg-purple16 bg-white grid grid-cols-1 lg:grid-cols-3 gap-5 px-5 py-6 rounded-2xl shadow-lg w-max`}
    >
      {/* left */}
      <div className="">
        <button
          className="rounded-lg border-none text-white text-sm md:text-base font-urbanist font-bold w-max px-10 py-2 mb-5"
          style={{
            background:
              "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
          }}
        >
          Active Plan
        </button>
        <div className="flex flex-col space-y-3">
          <h1
            className={`font-bold font-inter text-xl dark:text-white text-gray36 md:text-2xl`}
          >
            {activePlanDetails?.activePlanName}
          </h1>

          <p
            className={`block font-urbanist font-semibold text-sm text-purple3 mb-3`}
          >
            <span className="font-urbanist font-bold text-2xl md:text-4xl">
              {activePlanDetails?.subAmount === 0
                ? "FREE"
                : parseInt(activePlanDetails?.subAmount) === 0
                ? "FREE"
                : `$${activePlanDetails?.subAmount}`}
            </span>
            {activePlanDetails?.subAmount !== 0 &&
              activePlanDetails?.subscriptionEndDate &&
              `valid upto ${new Date(
                activePlanDetails?.subscriptionEndDate
              )?.toDateString()}`}
          </p>

          <p className={`font-urbanist font-normal text-sm text-gray28`}>
            {planData?.planDescription}
          </p>
        </div>
      </div>

      {/* center */}
      <div className="flex flex-col mt-5 space-y-4 mb-8 border-l-0 border-t border-t-gray-200 lg:border-t-0 lg:border-l lg:border-l-gray-200 pt-5 lg:px-5 h-full">
        <p className="font-urbanist font-semibold text-lg text-black dark:text-white">
          Benefits
        </p>

        {planData?.featuresAvailable?.map((feature, index) => (
          <div key={index} className={`flex items-center space-x-2`}>
            <div
              className={`flex items-center justify-center h-5 w-5 rounded-full bg-gray38 dark:bg-purple13`}
            >
              <CheckIcon className="text-blue-500 h-3" />
            </div>
            <p
              className={`font-inter font-medium text-xs dark:text-white text-blue21 `}
            >
              {feature.featureDescription}
            </p>
          </div>
        ))}
      </div>

      {/* right */}
      {planData?.planName?.toLowerCase() !== "basic" && (
        <div className="flex flex-col mt-5 space-y-4 mb-8 border-l-0 border-t border-t-gray-200 lg:border-t-0 lg:border-l lg:border-l-gray-200 pt-5 lg:px-5 h-full">
          <p className="font-urbanist font-semibold text-lg text-black dark:text-white">
            Membership & Billing
          </p>

          <div className="flex items-center space-x-2 font-urbanist font-semibold text-gray36 dark:">
            <p>Start Date: </p>
            <p>
              {activePlanDetails?.subscriptionStartDate &&
                new Date(
                  activePlanDetails?.subscriptionStartDate
                ).toDateString()}
            </p>
          </div>

          <div className="flex items-center space-x-2 font-urbanist font-semibold text-gray36 dark:">
            <p>End Date: </p>
            <p>
              {activePlanDetails?.subscriptionEndDate &&
                new Date(activePlanDetails?.subscriptionEndDate).toDateString()}
            </p>
          </div>

          <div className="">
            {activePlanDetails?.paymentVendor === "paypal" ? (
              <img
                src={PaypalLogo}
                alt="paypal"
                className="object-contain h-8 w-16"
              />
            ) : activePlanDetails?.paymentVendor === "stripe" ? (
              <img
                src={StripeLogo}
                alt="paypal"
                className="object-contain h-8 w-16"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivePlan;
