import {
  ADD_INSTRUCTOR_COURSE,
  ADD_INSTRUCTOR_QUIZ,
  DELETE_QUIZ_QUESTION,
  EDIT_INSTRUCTOR_COURSE,
  EDIT_INSTRUCTOR_QUIZ,
  GET_ALL_INSTRUCTOR_COURSES,
  GET_ALL_INSTRUCTOR_QUIZES,
  REMOVE_INSTRUCTOR_COURSE,
  REMOVE_INSTRUCTOR_QUIZ,
} from "./keys";

export const initialState = {
  instructorInfo: {},
  instructorCourses: [],
  instructorQuizes: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case GET_ALL_INSTRUCTOR_COURSES: {
      // fetching all courses and setting the initial state
      return { ...state, instructorCourses: action.payload };
    }

    case ADD_INSTRUCTOR_COURSE: {
      // add course to the current courses array
      const updatedCourses = [action.payload, ...state?.instructorCourses];

      // setting the data to global object
      return { ...state, instructorCourses: updatedCourses };
    }

    case EDIT_INSTRUCTOR_COURSE: {
      // edit course
      const currentCourses = [...state?.instructorCourses];
      const updatedCourseObj = action.payload;
      currentCourses?.map((item) => {
        if (item._id === updatedCourseObj._id) {
          return updatedCourseObj;
        } else {
          return item;
        }
      });

      // setting the data to global object
      return { ...state, instructorCourses: currentCourses };
    }
    case REMOVE_INSTRUCTOR_COURSE: {
      // delete instructor course that is sent in payload
      const currentCourses = [...state?.instructorCourses];
      const idToDelete = action.payload;

      const updatedCourses = currentCourses?.filter(
        (item) => item._id !== idToDelete
      );

      // setting the data to global object
      return { ...state, instructorCourses: updatedCourses };
    }

    case GET_ALL_INSTRUCTOR_QUIZES: {
      // fetching all quizes and setting the state
      return { ...state, instructorQuizes: action.payload };
    }

    case ADD_INSTRUCTOR_QUIZ: {
      // add quiz to the current quizes array
      const updatedQuizes = [...state?.instructorQuizes, action.payload];

      // setting the data to global object
      return { ...state, instructorQuizes: updatedQuizes };
    }

    case EDIT_INSTRUCTOR_QUIZ: {
      // edit current quiz i.e, (editing and adding of questions inside the quiz to update object)
      const currentQuizes = [...state?.instructorQuizes];

      // destructuring payload
      const { quizId, questionId, updatedQuestionData, isEditing } =
        action.payload;

      const quizToUpdate = currentQuizes?.find((item) => item._id === quizId);
      const remainingQuizes =
        currentQuizes?.filter((item) => item?._id !== quizId) || [];

      let updatedQuiz;
      if (isEditing) {
        // for editing a quiz data
        const updatedQuestions = quizToUpdate?.questions?.map((item) => {
          if (item.id === questionId) {
            return { ...item, ...updatedQuestionData };
          } else {
            return item;
          }
        });

        // updated data
        updatedQuiz = {
          ...quizToUpdate,
          questions: updatedQuestions,
        };

        updatedQuiz = [...remainingQuizes, updatedQuiz];
      } else {
        // for adding question to the current quiz
        const updatedData = {
          ...quizToUpdate,
          questions: [...quizToUpdate.questions, updatedQuestionData],
        };

        // updated data
        updatedQuiz = [...remainingQuizes, updatedData];
      }

      // setting the data to global object
      return { ...state, instructorQuizes: updatedQuiz };
    }

    case DELETE_QUIZ_QUESTION: {
      // delete the quiz question from the quiz object
      const currentQuizes = [...state?.instructorQuizes];
      // desctructuring payload
      const { quizId, questionId } = action.payload;
      // find quiz to update
      const quizToUpdate = currentQuizes?.find((item) => item._id === quizId);
      // separating remaing quizes
      const remainingQuizes = currentQuizes?.filter(
        (item) => item?._id !== quizId
      );
      let updatedQuiz;
      // updating data
      const updatedData = {
        ...quizToUpdate,
        questions: quizToUpdate?.questions?.filter(
          (item) => item.id !== questionId
        ),
      };

      // whole updated data
      updatedQuiz = [...remainingQuizes, updatedData];
      // setting the data to global object
      return { ...state, instructorQuizes: updatedQuiz };
    }

    case REMOVE_INSTRUCTOR_QUIZ: {
      // fetching the current quizes
      const currentQuizes = [...state?.instructorQuizes];
      // fetching to be deleted
      const idToDelete = action.payload;

      // filtering quizes
      const updatedQuizes = currentQuizes?.filter(
        (item) => item._id !== idToDelete
      );

      // setting the data to global object
      return { ...state, instructorQuizes: updatedQuizes };
    }
    default:
      return state;
  }
};

export default reducer;
