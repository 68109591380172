const devQueryUrl = `http://54.81.171.95:5010/query`;
const prodQueryUrl = `https://api.vaisage.com/query`;

const server =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `http://localhost:8082`
    : // : "https://brainjee-main-server.vercel.app"
      "https://api.vaisage.com";

let currentUrl;

const URLs = {
  appInfo: `${server}/api/appData/appInfo`,
  loginUrl: `${server}/api/auth/login`,
  logoutUrl: `${server}/api/auth/logout`,
  signupUrl: `${server}/api/auth/signup`,
  "verify-email": `${server}/api/auth/verify-email`,
  "forgot-password": `${server}/api/auth/forgot-password`,
  "reset-password": `${server}/api/auth/set-new-password`,
  "resend-otp": `${server}/api/auth/resend-otp`,
  "verify-otp": `${server}/api/auth/verify-otp`,
  "set-subscription": `${server}/api/auth/set-subscription`,
  getGoogleLoginUrl: `${server}/api/auth/google-login`,
  "apple-login": `${server}/api/auth/apple-login`,
  getFacebookLoginUrl: `${server}/api/auth//facebook-login`,
  checkout: `${server}/api/checkout`,
  "stripe-checkout": `${server}/api/checkout/stripe-checkout`,
  "paypal-checkout": `${server}/api/checkout/paypal-checkout`,
  getUserDetails: `${server}/api/auth/getuser`,
  "update-information": `${server}/api/auth/update-info`,
  "search-history": `${server}/api/search/get-search-history`,
  "add-search": `${server}/api/search/add-search`,
  "refresh-user": `${server}/api/auth/refresh`,
  "subscription-amount": `${server}/api/checkout/get-subscription-amount`,
  "searches-left": `${server}/api/free/get-free-searches`,
  "set-searches": `${server}/api/free/set-free-searches`,
  "subscribe-newsletter": `${server}/api/subscribe/newsletter`,
  "contact-form": `${server}/api/contact/submit-form`,
  "submit-action": `${server}/api/contact/submit-action`,
  "add-chat": `${server}/api/chats/add-chat`,
  "get-chat": `${server}/api/chats/get-chat`,
  // instrutor api routes
  "add-course": `${server}/api/instructor/add-course`,
  "edit-course": `${server}/api/instructor/update-course`,
  "add-quiz": `${server}/api/instructor/add-quiz`,
  "get-instructor-quizes": `${server}/api/instructor/get-quizes`,
  "get-instructor-courses": `${server}/api/instructor/get-courses`,
  "delete-instructor-course": `${server}/api/instructor/delete-course`,
  "delete-instructor-quiz": `${server}/api/instructor/delete-quiz`,
  "delete-quiz-question": `${server}/api/instructor/delete-quiz-question`,
  "get-quiz": `${server}/api/instructor/get-single-quiz`,
  "edit-quiz-question": `${server}/api/instructor/edit-quiz-question`,
  "image-upload-url": `${server}/api/image/get-upload-url`,
  // allcourse and quiz route
  "get-all-courses": `${server}/api/application-study-material/all-courses`,
  "get-all-quizes": `${server}/api/application-study-material/all-quizes`,
  // student routes
  "submit-quiz": `${server}/api/student/evaluate-quiz`,
  "get-attempted-quiz": `${server}/api/student/get-attempted-quiz`,
  // admin route
  "change-status": `${server}/api/admin/course-status-change`,
  // bot info
  "get-bot-info": `${server}/api/bot-info/get-bot-info`,
  "set-bot-info": `${server}/api/bot-info/set-bot-info`,
  "set-bot-chat-id": `${server}/api/bot-info/set-bot-chat-id`,
  // blogs routes
  "fetch-blogs": `${server}/api/blogs/all-blogs`,
  "fetch-blog": `${server}/api/blogs/blog`,
  "fetch-user-blogs": `${server}/api/blogs/fetch-user-blogs`,
  "upload-blog": `${server}/api/blogs/upload-blog`,
  "delete-blog": `${server}/api/blogs/delete-blog`,
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  currentUrl = devQueryUrl;
} else {
  // production code
  currentUrl = prodQueryUrl;
}

const BOTURLS = {
  bot_id: ({ botName }) => `https://api${botName}.brainjee.com/get_bot_id`,
  text_chat_id: ({ botName, botId }) =>
    `https://api${botName}.brainjee.com/new_chat/${botId}`,
  vision_chat_id: ({ botName, botId }) =>
    `https://api${botName}.brainjee.com/vision_new_chat/${botId}`,
  new_text: ({ botName, botId, chatId, query }) =>
    `https://api${botName}.brainjee.com/response/${botId}/${chatId}?query=${query}`,
  new_vision: ({ botName, botId, visionId }) =>
    `https://api${botName}.brainjee.com/vision_query/${botId}/${visionId}`,
  new_voice: ({ botName, botId, chatId }) =>
    `https://api${botName}.brainjee.com/audio_to_text/${botId}/${chatId}`,
  text: ({ botName }) =>
    `https://api${botName}.brainjee.com/text_${botName}bot/`,
  vision: ({ botName }) =>
    `https://api${botName}.brainjee.com/vision_${botName}bot/`,
  voice: ({ botName }) =>
    `https://api${botName}.brainjee.com/voice_${botName}bot/`,
  update: ({ botName }) =>
    `https://api${botName}.brainjee.com/update_${botName}bot/`,
};

export const getURLs = (urlName) => URLs[urlName];
export const getBotURLs = (searchFor, params) => BOTURLS[searchFor](params);

export { currentUrl };
