import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";

const ChooseTimeline = ({ setSubscriptionTimeline, subscriptionTimeline }) => {
  return (
    <>
      <div
        className="flex items-center space-x-2 cursor-pointer"
        onClick={() => setSubscriptionTimeline("annually")}
      >
        <div
          className={`h-6 w-6 rounded-full flex items-center justify-center ${
            subscriptionTimeline === "annually"
              ? ""
              : "border border-gray-700 dark:border-white"
          }`}
          style={{
            background:
              subscriptionTimeline === "annually" &&
              "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          }}
        >
          {subscriptionTimeline === "annually" && (
            <CheckIcon className="text-white h-4" />
          )}
        </div>
        <span
          className={`font-urbanist font-semibold text-sm dark:text-white ${
            subscriptionTimeline === "annually"
              ? "text-blue37"
              : "text-[#6F787E]"
          }`}
        >
          Yearly
        </span>
      </div>

      <div
        className="flex items-center space-x-2 cursor-pointer"
        onClick={() => setSubscriptionTimeline("monthly")}
      >
        <div
          className={`h-6 w-6 rounded-full flex items-center justify-center ${
            subscriptionTimeline === "monthly"
              ? ""
              : "border border-gray-700 dark:border-white"
          }`}
          style={{
            background:
              subscriptionTimeline === "monthly" &&
              "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          }}
        >
          {subscriptionTimeline === "monthly" && (
            <CheckIcon className="text-white h-4" />
          )}
        </div>
        <span
          className={`font-urbanist font-semibold dark:text-white text-sm ${
            subscriptionTimeline === "monthly"
              ? "text-blue37"
              : "text-[#6F787E]"
          }`}
        >
          Monthly
        </span>
      </div>
    </>
  );
};

export default ChooseTimeline;
