import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const onNavigate = () => {
      window.scrollTo(0, 0);
    };

    // Scroll to top when the location changes
    onNavigate();

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      onNavigate();
      //   unlisten(); // Clean up navigation event listener
    };
  }, [location.pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
