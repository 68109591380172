import axios from "axios";
import { useEffect, useState } from "react";
import { getURLs } from "../../../urlConfig";
import { generateUniqueId } from "../../../utils/utils";
import FormSelect from "../../FormSelect";
import ImageUploadingModal from "../../ImageUploadingModal";
import CustomModal from "../../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import CustomSwitch from "../../CustomSwitch";

const AddNewQuizQuestion = (props) => {
  const questionTypeOptions = [
    { value: "Newest", label: "Newest" },
    { value: "High Rated", label: "High Rated" },
    { value: "Law Rated", label: "Law Rated" },
    { value: "High Earned", label: "High Earned" },
  ];

  // destructuring current question if exists
  const { currentQuestionState } = props;

  const [questionTitle, setQuestionTitle] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState("");
  const [questionPoint, setQuestionPoint] = useState("");
  const [questionImage, setQuestionImage] = useState("");
  const [options, setOptions] = useState(new Array(4).fill(""));
  // image url state
  const [securedUrl, setSecuredUrl] = useState("");
  const [savedImageUrl, setSavedImageUrl] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadingError, setImageUploadingError] = useState({
    show: false,
    message: "",
  });

  const handleInputChange = (e, index) => {
    const newOptions = [...options];
    newOptions[index] = e.target.value;
    setOptions(newOptions);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setQuestionImage(e.target.files[0]);
      axios
        .post(
          getURLs("image-upload-url"),
          { imageFor: "quizQuestionImages" },
          {
            headers: { "auth-token": props?.userInfo?.authToken },
            withCredentials: true,
          }
        )
        .then((res) => {
          setSecuredUrl(res?.data?.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUpload = async () => {
    if (questionImage) {
      setImageUploading(true);
      // have to do it fetch for s3.
      fetch(securedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: questionImage,
      })
        .then((res) => {
          const image = securedUrl?.split("?")[0];
          setSavedImageUrl(image);
          setImageUploading(false);
          setImageUploadingError({ show: false, message: "" });
        })
        .catch((err) => {
          console.log(err);
          setImageUploading(false);
          setImageUploadingError({
            show: true,
            message: err?.message || "Failed to upload Image",
          });
        });
    } else {
      setImageUploadingError({
        show: true,
        message: "Please select an image to upload",
      });
    }
  };

  const addQuestion = () => {
    props.addQuestionHandler({
      id: props?.usedForEditing ? currentQuestionState?.id : generateUniqueId(),
      title: questionTitle,
      type: questionType,
      options: options,
      correctAnswerIndex,
      questionPoint,
      questionImage: savedImageUrl,
    });
    setQuestionTitle("");
    setQuestionType("");
    setCorrectAnswerIndex();
    setQuestionPoint("");
    setOptions(["", "", "", ""]);
    setQuestionImage(null);
    setSavedImageUrl("");
    props.onHide();
  };

  useEffect(() => {
    if (props?.usedForEditing) {
      setQuestionTitle(currentQuestionState?.title);
      setQuestionImage(currentQuestionState?.questionImage);
      setSavedImageUrl(currentQuestionState?.questionImage);
      setOptions(currentQuestionState?.options);
      setQuestionPoint(currentQuestionState?.questionPoint);
      setQuestionType(currentQuestionState?.type);
      setCorrectAnswerIndex(currentQuestionState?.correctAnswerIndex);
    }
  }, [props?.usedForEditing, currentQuestionState]);

  return (
    // props.show && (
    <>
      <CustomModal
        show={props.show}
        onHide={props?.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {props?.show && (
          <div className="flex flex-col space-y-1 overflow-y-auto w-full">
            <div className="flex items-center justify-between w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Add Quiz Question
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={props?.onHide}
              />
            </div>
            <div className="flex flex-col px-4">
              <div className="mb-5">
                <h4 className="mb-3 font-inter font-semibold text-base text-black/80">
                  General
                </h4>
                {/* Write your question */}
                <div className="mb-3 flex flex-col space-y-2">
                  <h2
                    className="text-base text-black/70 font-inter font-medium"
                    htmlFor="quiz-title"
                  >
                    Write your question
                  </h2>
                  <input
                    className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18"
                    placeholder="Question title"
                    id="quiz-title"
                    value={questionTitle}
                    onChange={(e) => setQuestionTitle(e.target.value)}
                  />
                </div>

                {/* Question Type */}
                <div className="mb-3 flex flex-col space-y-2">
                  <h2
                    className="text-base text-black/70 font-inter font-medium"
                    htmlFor="question-type"
                  >
                    Question Type
                  </h2>
                  <div className="w-full px-4 py-2 border border-gray19 rounded-md">
                    <FormSelect
                      placeholder="Select Question Type"
                      id="question-type"
                      options={questionTypeOptions}
                      className="text-sm font-inter font-normal placeholder:text-gray20 text-gray18 w-full outline-none"
                      defaultSelected={questionType}
                      onChange={(e) => setQuestionType(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <h4 className="text-sm font-inter font-medium text-black mb-3">
                  Answer
                </h4>
                {/* Enter possible answers */}
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <div className="mb-2 flex flex-col space-y-2" key={index}>
                      <label
                        className="flex justify-between items-center font-normal fonr-inter text-gray22"
                        htmlFor={`input-choice-${item}`}
                      >
                        <div>
                          <h5 className="mb-0 fw-normal">Choice {item}</h5>
                        </div>
                        <div className="">
                          <div className="flex items-center space-x-2">
                            <span>Correct answer</span>
                            <CustomSwitch
                              itemValue={item}
                              handleClick={(value) => {
                                console.log(value);
                                setCorrectAnswerIndex(value);
                              }}
                              checked={item === correctAnswerIndex}
                            />
                          </div>
                        </div>
                      </label>
                      <input
                        type="text"
                        id={`input-choice-${item}`}
                        placeholder="Write the answer"
                        value={options?.[item - 1]}
                        onChange={(e) => {
                          handleInputChange(e, item - 1);
                        }}
                        className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18"
                      />
                    </div>
                  );
                })}
                <div className="mb-4 mt-6">
                  {/* question image */}
                  <div className="flex items-center justify-between">
                    <input
                      type="file"
                      className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18 flex-1 "
                      onChange={handleFileChange}
                    />
                    <label
                      className="cursor-pointer border border-gray19 outline-none rounded-tr-md rounded-br-md text-base text-gray20 px-4 py-2.5"
                      onClick={handleUpload}
                    >
                      Upload
                    </label>
                  </div>
                  <p className="text-xs text-gray22 font-inter font-normal block mb-1">
                    Upload your question image here.
                  </p>
                  <p className="text-xs text-gray22 font-inter font-normal block mb-4">
                    Please click the upload button to upload
                  </p>
                  <p>
                    {imageUploadingError?.show && (
                      <span className="block mt-2 text-sm md:text-base font-inter font-medium text-red-500">
                        {imageUploadingError?.message}
                      </span>
                    )}
                  </p>
                  {savedImageUrl && (
                    <img
                      src={savedImageUrl}
                      alt="courseImage"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  {/* Point to the answer */}
                  <div className="mb-3 flex flex-col space-y-2">
                    <label
                      className="font-medium font-inter text-black/90 text-sm"
                      htmlFor="point-to-the-answer"
                    >
                      Point for this answer
                    </label>
                    <textarea
                      type="text"
                      placeholder="Give point to the answer"
                      className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18"
                      id="point-to-the-answer"
                      rows={3}
                      value={questionPoint}
                      onChange={(e) => setQuestionPoint(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end space-x-4 pb-5 w-full px-4">
              <button
                className="bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                onClick={props.onHide}
              >
                Close
              </button>
              <button
                className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                onClick={addQuestion}
              >
                {props?.usedForEditing ? "Confirm Edit" : "Add Question"}
              </button>
            </div>
          </div>
        )}
      </CustomModal>
      {/* image uploading modal */}
      <ImageUploadingModal
        modalOpen={imageUploading}
        title={`Image Uploading`}
        message={`Please wait while image is getting uploaded...`}
      />
    </>
    // )
  );
};

export default AddNewQuizQuestion;
