import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import OverviewDetails from "./OverviewDetails/OverviewDetails";

const CourseOverview = ({ courseData, sectionContent }) => {
  const [expanded, setExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const containerHeight = containerRef?.current?.clientHeight;
    const lineHeight = parseFloat(
      window.getComputedStyle(containerRef?.current)?.lineHeight
    );
    const calculatedMaxLines = Math.floor(containerHeight / lineHeight);

    if (calculatedMaxLines > 3) {
      setIsExpandable(true);
    }
  }, [sectionContent?.description]);

  const mobileTextStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "unset",
  };

  const desktopTextStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: isExpandable ? (expanded ? "unset" : 3) : "unset",
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="mt-5">
      <h3 className="font-urbanist font-semibold text-base text-black dark:text-white block mb-3">
        About this course
      </h3>

      {/* description */}
      <div
        className="font-urbanist font-normal text-sm dark:text-white text-gray40 lg:line-clamp-none line-clamp-2"
        dangerouslySetInnerHTML={{ __html: courseData?.course_description }}
      />

      <div className="block lg:hidden">
        {expanded ? (
          <OverviewDetails
            containerRef={containerRef}
            courseData={courseData}
            expanded={expanded}
            isExpandable={false}
            sectionContent={sectionContent}
            textStyle={mobileTextStyle}
            toggleExpand={toggleExpand}
          />
        ) : (
          ""
        )}
      </div>

      <div className="hidden lg:block">
        <OverviewDetails
          containerRef={containerRef}
          courseData={courseData}
          expanded={expanded}
          isExpandable={isExpandable}
          sectionContent={sectionContent}
          textStyle={desktopTextStyle}
          toggleExpand={toggleExpand}
        />
      </div>

      {/* only shown in mobile screen */}
      <div className="lg:hidden flex items-center justify-center my-4">
        <button
          onClick={toggleExpand}
          className="lg:hidden text-blue27 font-urbanist font-semibold text-sm flex items-center space-x-1 cursor-pointer w-max self-center"
        >
          <span>{expanded ? "Hide description" : "Show description"}</span>
          {expanded ? (
            <ChevronUpIcon className="text-blue27 h-4 w-4 mt-0.5" />
          ) : (
            <ChevronDownIcon className="text-blue27 h-4 w-4 mt-0.5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default CourseOverview;
