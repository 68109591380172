import React, { useEffect, useState } from "react";
import MarkdownRenderWithMath from "../../MarkdownRender/MarkdownRenderWithMath";
// import MarkdownRender from "../../MarkdownRender/MarkdownRender";

const ShowAnimatedText = ({ text }) => {
  const [displayResponse, setDisplayResponse] = useState("");
  useEffect(() => {
    let i = 0;
    const stringResponse = text;

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse?.slice(0, i));

      i++;

      if (i > stringResponse.length) {
        clearInterval(intervalId);
        // setCompletedTyping(true);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [text]);
  return <MarkdownRenderWithMath text={displayResponse} />;
};

export default ShowAnimatedText;
