// import node module libraries
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import { InstructorDataContext } from "../context/instructorData";
import { UserContext } from "../context/user";
import { getURLs } from "../urlConfig";

import "./InstructorDashboard.css";
import ActionMenu from "../components/ActionMenu";

const InstructorDashboard = () => {
  // getting the user state
  const {
    state: { userInfo },
  } = useContext(UserContext);

  // getting the instructor context
  const {
    state: { instructorCourses },
    getAllCourses,
    deleteCourse,
  } = useContext(InstructorDataContext);

  // delete course modal state
  const [openDeleteModal, setOpenDeleteModal] = useState({
    show: false,
    idToDelete: "",
  });

  const [toggleMenu, setToggleMenu] = useState({ activeId: 0 });

  const handleToggleClick = (id) => {
    setToggleMenu((prev) =>
      prev?.activeId === id ? { activeId: 0 } : { activeId: id }
    );
  };

  // delete course function
  const deleteInstructorCourse = (id) => {
    axios
      .post(
        getURLs("delete-instructor-course"),
        {
          idToDelete: id,
        },
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // update courses
          deleteCourse(id);
          setOpenDeleteModal({ show: false, idToDelete: "" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetching all the courses for the instructor
  useEffect(() => {
    // getting instructor info
    if (
      userInfo?.role?.find((data) => data === "instructor" || data === "admin")
    ) {
      // getting the courses
      axios
        .get(getURLs("get-instructor-courses"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          getAllCourses(res?.data?.allCourses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);

  return (
    <main className="bg-white border shadow-md w-full">
      <div className="mt-4">
        <h3 className="mb-0 text-base font-inter font-semibold text-black/80 shadow-md py-2 px-4 block">
          Best Selling Courses
        </h3>
        <div className="overflow-x-auto overflow-y-hidden">
          <table
            cellPadding={20}
            className="mb-0 whitespace-nowrap align-middle w-full px-4 py-2 min-w-[500px]"
          >
            <thead className="bg-gray21 text-gray-800 w-full items-start text-sm">
              <tr className="">
                <th
                  scope="col"
                  className="border-0 text-start font-inter font-normal"
                >
                  COURSES
                </th>
                <th
                  scope="col"
                  className="border-0 text-start font-inter font-normal"
                >
                  SALES
                </th>
                <th
                  scope="col"
                  className="border-0 text-start font-inter font-normal"
                >
                  AMOUNT
                </th>
                <th
                  scope="col"
                  className="border-0 text-start font-inter font-normal"
                ></th>
              </tr>
            </thead>
            <tbody className="bg-gray21">
              {instructorCourses?.length > 0 ? (
                instructorCourses
                  .sort((a, b) => b.sales - a.sales)
                  ?.slice(0, 5)
                  .map((item, index) => {
                    return (
                      <tr key={index} className="border-b">
                        <td className="align-middle border-t-0 ">
                          <Link to="#">
                            <div className="lg:flex items-center">
                              <img
                                src={item?.image}
                                alt="course_thumbnail"
                                className="rounded-md h-20 w-20 object-contain"
                              />
                              <h5 className="mb-0 lg:ms-3 lg:mt-0 mt-2 hover:text-purple-200">
                                {item?.title}
                              </h5>
                            </div>
                          </Link>
                        </td>
                        <td className="align-middle border-t-0">
                          {item?.sales}
                        </td>
                        <td className="align-middle border-t-0">
                          ${item?.price}{" "}
                        </td>
                        <td className="align-middle border-t-0">
                          <ActionMenu
                            id={item?._id}
                            courseObj={item}
                            showMenu={toggleMenu?.activeId === item?._id}
                            handleToggleClick={handleToggleClick}
                            setOpenDeleteModal={setOpenDeleteModal}
                            closeActionMenu={() =>
                              setToggleMenu({ activeId: undefined })
                            }
                            menuPlacement={"bottom-4 -left-28"}
                          />
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td className="flex items-center justify-center">
                    No Course added
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* end of Page Content section*/}

      {/* confirm uquiz delete modal */}
      <ConfirmDeleteModal
        showModal={openDeleteModal?.show}
        closeModal={() => {
          setOpenDeleteModal({ show: false, idToDelete: "" });
        }}
        handleConfirm={() =>
          deleteInstructorCourse(openDeleteModal?.idToDelete)
        }
        message={`Are you sure you want to delete this course`}
      />
    </main>
  );
};
export default InstructorDashboard;
