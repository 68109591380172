import { Link } from "react-router-dom";
import CheckedMark from "../../images/checked-mark.svg";
import ProfileBackground from "../../images/profile-bg.jpg";
import { Bars3Icon, UserCircleIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../context/user";
import { useContext, useState } from "react";
import InstructorNavigationMenu from "../InstructorSidebar/InstructorNavigationMenu/InstructorNavigationMenu";

const InstructorDashboardHeader = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="items-center mb-4 bg-white">
      <div
        className="pt-20 rounded-t-md"
        style={{
          background: `url(${ProfileBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <div className="px-4 pt-2 pb-4 rounded-0 rounded-b shadow-sm">
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center space-x-2">
            <div className="relative flex items-center space-x-2">
              {userInfo?.avatar ? (
                <img
                  src={userInfo?.avatar}
                  className="h-10 w-10 md:h-14 md:w-14 rounded-full border-4 border-gray-400 relative"
                  alt="profile_pic"
                />
              ) : (
                <UserCircleIcon className="h-10 w-10 md:h-14 md:w-14 text-gray-400" />
              )}

              {userInfo?.verified ? (
                <Link
                  to="#"
                  className="absolute top-0 right-0"
                  data-bs-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Verified"
                >
                  <img src={CheckedMark} alt="" height={30} width={30} />
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="space-x-4">
              <h2 className="mb-0 text-base md:text-lg">{userInfo?.name} </h2>
              <p className="mb-0 block text-xs md:text-sm text-gray-200">
                {userInfo?.username}
              </p>
            </div>
          </div>
          <div>
            <Link
              to={`/instructor/add-new-course`}
              className={`bg-purple1 rounded-md text-white px-2 py-2 text-xs sm:text-sm ${
                userInfo?.outlinebutton ? "-outline" : ""
              }`}
            >
              Create New Course
            </Link>
          </div>
        </div>

        <div className="flex flex-col lg:hidden mt-4 w-full">
          <div className="flex items-center justify-between">
            <span className="font-inter font-semibold text-sm text-black/80">
              Menu
            </span>
            <div
              className="px-2 py-2 rounded-md cursor-pointer"
              style={{
                background:
                  "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
              }}
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Bars3Icon className="h-4 w-4 text-white" />
            </div>
          </div>

          <div
            className={`${
              openMenu ? "opacity-100 h-max w-full px-8 py-4" : "opacity-0 h-0 "
            } transition-all transform ease-in-out duration-300 `}
          >
            {openMenu && <InstructorNavigationMenu />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorDashboardHeader;
