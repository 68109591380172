import React, { useRef, useState } from "react";
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const ActionMenu = ({
  id,
  setOpenDeleteModal,
  showMenu,
  handleToggleClick,
  courseObj,
  menuPlacement,
  // closeActionMenu,
}) => {
  // ref
  const divRef = useRef();

  const navigate = useNavigate();

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (divRef.current && !divRef.current.contains(event.target)) {
  //       closeActionMenu();
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [divRef]);

  return (
    <div ref={divRef} className="relative">
      <div className="cursor-pointer" onClick={() => handleToggleClick(id)}>
        <EllipsisVerticalIcon className="h-5 w-5 text-gray-400" />
      </div>
      {showMenu && (
        <div
          className={`${
            showMenu
              ? "w-max h-max bg-white opacity-100 border z-30"
              : "h-0 w-0 opacity-0"
          } absolute px-5 py-4 rounded-md flex flex-col space-y-2 ${menuPlacement}`}
        >
          <span className="text-xs text-gray19 font-inter font-normal ">
            SETTINGS
          </span>
          <p
            className="flex items-center font-inter font-normal text-xs cursor-pointer"
            onClick={() =>
              navigate("/instructor/edit-course/", {
                state: courseObj,
              })
            }
          >
            <PencilSquareIcon className="h-4 w-4 text-gray-400 mr-2" /> Edit
          </p>
          <p
            className="flex items-center font-inter font-normal text-xs cursor-pointer"
            onClick={() => setOpenDeleteModal({ show: true, idToDelete: id })}
          >
            <TrashIcon className="text-gray-400 h-4 w-4 mr-2" /> Remove
          </p>
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
