import React from "react";
import CourseCard from "../CourseCard";
import ExternalCourseCard from "../ExternalCourseCard";

const ShowAllCourses = ({ allCoursesData, externalCourses }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 bg-gray-100 dark:bg-darkBgColor1 px-4 py-5">
      {allCoursesData?.map(
        (courseData) =>
          courseData?.status === "approved" && (
            <CourseCard key={courseData?._id} data={courseData} />
          )
      )}

      {externalCourses?.map((book) => (
        <ExternalCourseCard data={book} key={book._id} />
      ))}
    </div>
  );
};

export default ShowAllCourses;
