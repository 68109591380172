import { CheckIcon } from "@heroicons/react/24/solid";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/user";
import { DarkModeContext } from "../../../../../context/darkModeContext";
import CustomModal from "../../../../CustomModal/CustomModal";
import PaymentSection from "../../../../UserDashboard/PaymentSection/PaymentSection";

const AvailablePlanCard = ({
  id,
  name,
  description,
  planPriceMonthly,
  planPriceYearly,
  features,
  setSubscriptionValue,
  subscriptionValue,
  isActivePlan,
}) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const navigate = useNavigate();

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handleUpgradePlan = () => {
    if (Object.keys(userInfo)?.length) {
      if (id === 1) {
        navigate("/contact");
      } else {
        setShowPaymentModal(true);
        // navigate("/checkout", {
        //   state: {
        //     selectedPaymentMethod: "Paypal",
        //     subscriptionAmount:
        //       subscriptionValue === "monthly"
        //         ? planPriceMonthly
        //         : planPriceYearly,
        //     subscriptionTimeline: subscriptionValue,
        //     userInfo: userInfo,
        //   },
        // });
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div
      className={`relative ${
        isActivePlan ? "opacity-60" : "opacity-100"
      } flex flex-col dark:bg-purple16 bg-white p-5 rounded-2xl shadow-lg cursor-pointer w-full min-w-[300px] md:min-w-min border dark:border-purple3 `}
      // onClick={() => setSelectedPlan(id)}
    >
      <h1
        className={`font-bold font-inter text-xl dark:text-purple6 text-gray36 md:text-2xl`}
      >
        {name}
      </h1>

      {name?.toLowerCase() === "enterprise" ? (
        <p
          className={`block font-inter font-bold text-2xl md:text-4xl text-purple3 dark:text-white mb-3`}
        >
          {subscriptionValue === "monthly"
            ? `${planPriceMonthly}`
            : `${planPriceYearly}`}
        </p>
      ) : (
        <p
          className={`block font-urbanist font-semibold text-sm text-purple3 dark:text-white mb-3`}
        >
          <span className="font-urbanist font-bold text-2xl md:text-4xl">
            {subscriptionValue === "monthly"
              ? parseInt(planPriceMonthly) === 0
                ? "FREE"
                : `$${planPriceMonthly}`
              : parseInt(planPriceMonthly) === 0
              ? "FREE"
              : `$${planPriceYearly}`}
          </span>
          {parseInt(planPriceMonthly) !== 0 &&
            `/${subscriptionValue === "monthly" ? "Month" : "Year"}`}
        </p>
      )}

      <p className={`font-urbanist font-normal text-sm text-gray28 `}>
        {description}
      </p>

      <div className={`w-full border-b border-b-gray-400 my-3`} />

      <div className="flex flex-col mt-5 space-y-4 mb-8">
        {features.map((feature, index) => (
          <div key={index} className={`flex items-center space-x-2`}>
            <div
              className={`flex items-center justify-center h-5 w-5 rounded-full bg-gray38 dark:bg-purple13`}
            >
              <CheckIcon className="text-blue-500 h-3" />
            </div>
            <p
              className={`font-inter font-medium text-xs dark:text-white text-blue21`}
            >
              {feature.featureDescription}
            </p>
          </div>
        ))}
      </div>

      <div className="flex-1 flex items-end">
        <button
          className="px-4 py-2 cursor-pointer rounded-lg w-full text-center"
          style={{
            background: !darkMode
              ? "linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box"
              : "linear-gradient(#2E0053, #2E0053) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box",
            border: "1px solid transparent",
          }}
          onClick={handleUpgradePlan}
          disabled={isActivePlan}
        >
          <span
            className="text-sm font-urbanist font-medium"
            style={{
              backgroundImage:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Upgrade Plan
          </span>
        </button>
      </div>
      <CustomModal
        onHide={() => setShowPaymentModal(false)}
        show={showPaymentModal}
        centered
      >
        <div className="w-full px-4 py-4">
          <PaymentSection
            userInfo={userInfo}
            usedAsPopup
            planName={name}
            setSubscriptionValue={setSubscriptionValue}
            subscriptionValue={subscriptionValue}
            priceForSubscription={
              subscriptionValue === "monthly"
                ? planPriceMonthly
                : planPriceYearly
            }
            handlePaymentClick={() => {}}
            closePopupHandler={() => setShowPaymentModal(false)}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default AvailablePlanCard;
