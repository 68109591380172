import { BookOpenIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useLocation } from "react-router-dom";
import AdminSidebarItem from "./AdminSidebarItem/AdminSidebarItem";

const AdminSidebar = () => {
  const location = useLocation();
  const tabsData = [
    {
      id: 1,
      tabName: "All Courses",
      icon: BookOpenIcon,
      routeName: "/admin/all-courses",
    },
  ];
  return (
    <div className="flex flex-col w-full rounded-br-md">
      <div className="flex items-start flex-col space-y-2 py-4 px-6">
        {tabsData?.map((data) => (
          <AdminSidebarItem
            key={data.id}
            Icon={data.icon}
            routeTo={data.routeName}
            title={data.tabName}
            selected={location.pathname === data.routeName}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminSidebar;
