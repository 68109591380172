import axios from "axios";
import { v4 as uuid } from "uuid";
import { getBotURLs, getURLs } from "../urlConfig";

export const extractTextAndLinks = (response) => {
  const regex = /(https?:\/\/[^\s]+)/g;
  const links = [];
  const text = [];

  const extractedLinks = response.match(regex);
  if (extractedLinks) {
    links.push(...extractedLinks);
  }

  const extractedText = response.replace(regex, "").trim();
  const newText = extractedText.replace(/\n/g, "").trim();
  if (extractedText) {
    text.push(newText);
  }

  return { links, text };
};

export const convertToTuple = (arr) => {
  return arr?.map((data) => [data.question, data.answer]);
};

export const convertToQuestionObj = (arr) => {
  return arr?.map((data) => ({ question: data[0], answer: data[1] }));
};

export const numberWithCommas = (x, decimal = 0) => {
  return x?.toLocaleString("en-US", { minimumFractionDigits: decimal }) || 0;
};

export function generateUniqueId() {
  return "_" + Math.random().toString(36).substring(2, 9) + uuid();
}

export function convertMinutesToHoursAndMinutes(
  minutes,
  sendHoursAndMinutes = false
) {
  if (minutes < 0) {
    return "Invalid input";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (sendHoursAndMinutes) {
    return { hours, minutes };
  } else {
    if (hours > 0 && remainingMinutes > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ${remainingMinutes} minute${
        remainingMinutes > 1 ? "s" : ""
      }`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      return `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
    }
  }
}

// Debounce function
export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const getSearchedCourses = (array, searchValue) => {
  return array.filter(
    (course) =>
      // Check if either the title or instructor contains the search term
      course.title.toLowerCase().includes(searchValue) ||
      course.instructor_id.name.toLowerCase().includes(searchValue)
  );
};

export const getTotalLecturesCount = (curriculum) => {
  // Ensure curriculum is an array
  if (!Array.isArray(curriculum)) {
    throw new Error("Invalid curriculum format. Expected an array.");
  }

  // Calculate the total length of lectures across all curriculum objects
  const totalLecturesCount = curriculum.reduce((total, currentCurriculum) => {
    // Ensure each curriculum object has a lectures array
    if (currentCurriculum && Array.isArray(currentCurriculum.lectures)) {
      return total + currentCurriculum.lectures.length;
    } else {
      return total;
    }
  }, 0);

  return totalLecturesCount;
};

export const getSentenceWithoutLastWord = (sentence) => {
  if (!sentence) {
    return "";
  }
  // Split the sentence by spaces
  const words = sentence?.split(" ");
  // Remove the last word from the array
  words?.pop();
  // Join the remaining words back into a sentence
  return words?.join(" ");
};

export const getLastWord = (sentence) => {
  if (!sentence) {
    return "";
  }
  // Split the sentence by spaces
  const words = sentence?.split(" ");
  // Return the last word
  return words?.[words.length - 1];
};

// get botids
export const getBotIds = async (tutor) => {
  return new Promise((resolve, reject) => {
    axios
      .post(getBotURLs("bot_id", { botName: tutor }))
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data?.bot_id);
        }
      })
      .catch((err) => {
        resolve("");
      });
  });
};

// get text chatid's
export const getTextChatIds = async (tutor, botId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(getBotURLs("text_chat_id", { botName: tutor, botId }))
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data?.chat_id);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get text chatid's
export const getVisionChatIds = async (tutor, botId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(getBotURLs("vision_chat_id", { botName: tutor, botId }))
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data?.vision_id);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// set chatid's
export const setChatIds = async (chatIds, authToken) => {
  // return new Promise((resolve, reject) => {
  //   axios
  //     .post(
  //       getURLs("set-bot-chat-id"),
  //       {
  //         chatIdsInfo: chatIds,
  //       },
  //       {
  //         headers: {
  //           "auth-token": authToken,
  //         },
  //         withCredentials: true,
  //       }
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         resolve(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       reject(err);
  //     });
  // });
  const userBotInfo = sessionStorage.getItem("botInfo");
  if (userBotInfo) {
    const botInfo = JSON.parse(userBotInfo);
    const newBotInfo = {
      ...botInfo,
      botChatIds: {
        ...botInfo?.botChatIds,
        ...chatIds,
      },
    };
    sessionStorage.setItem("botInfo", JSON.stringify(newBotInfo));
  }
};

// set bot ids
export const setBotIds = async (botIdsInfo, authToken) => {
  // axios
  //   .post(
  //     getURLs("set-bot-info"),
  //     { botIdsInfo: botIdsInfo },
  //     {
  //       headers: {
  //         "auth-token": authToken,
  //       },
  //       withCredentials: true,
  //     }
  //   )
  //   .then((res) => {})
  //   .catch((err) => {
  //     console.log(err);
  //   });

  const userBotInfo = sessionStorage.getItem("botInfo");
  if (userBotInfo) {
    const botInfo = JSON.parse(userBotInfo);
    const newBotInfo = {
      ...botInfo,
      botIds: {
        ...botInfo?.botIds,
        ...botIdsInfo,
      },
    };
    sessionStorage.setItem("botInfo", JSON.stringify(newBotInfo));
  } else {
    const botInfo = {
      botIds: { ...botIdsInfo },
    };
    sessionStorage.setItem("botInfo", JSON.stringify(botInfo));
  }
};

// function to save search history in DB
export const saveSearchHistory = (searchQuery, authToken) => {
  axios
    .post(
      getURLs("add-search"),
      {
        searchTerm: searchQuery,
      },
      {
        headers: {
          "auth-token": authToken,
        },
      }
    )
    .then((res) => {})
    .catch((err) => {
      console.log(err);
    });
};

//function to save chat history to db
export const saveChat = (tutor, chatObj, authToken) => {
  axios
    .post(
      getURLs("add-chat"),
      {
        tutor: tutor,
        question: chatObj.question,
        answer: chatObj.answer,
      },
      {
        headers: {
          "auth-token": authToken,
        },
        withCredentials: true,
      }
    )
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
};

// functin to save chat history to session
export const saveChatToSession = (tutor, history) => {
  sessionStorage.setItem(`${tutor}-chat-history`, JSON.stringify(history));
};

// utility func to add suffix to number
export function addSuffixToNumber(number) {
  const num = parseInt(number);

  if (isNaN(num)) {
    return "Invalid input";
  }

  const lastDigit = num % 10;
  const secondLastDigit = Math.floor(num / 10) % 10;

  if (secondLastDigit === 1) {
    return num + "th";
  }

  switch (lastDigit) {
    case 1:
      return num + "st";
    case 2:
      return num + "nd";
    case 3:
      return num + "rd";
    default:
      return num + "th";
  }
}

export const saveUserFeedbackAction = (actionType, data, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getURLs("submit-action"),
        {
          actionType,
          data,
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Recursive function to extract plain text from the result-content element
// const getResultContentText = (element) => {
//   let text = "";
//   const childNodes = element.childNodes;
//   for (let i = 0; i < childNodes.length; i++) {
//     const node = childNodes[i];
//     if (node.nodeType === Node.TEXT_NODE) {
//       text += node.textContent;
//     } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName !== "A") {
//       text += getResultContentText(node);
//     }
//   }
//   return text;
// };

// useEffect(() => {
//     // fetching the initial chat history
//     if (Object.keys(userInfo).length) {
//       axios
//         .get(getURLs("get-chat"), {
//           headers: {
//             "auth-token": userInfo?.authToken,
//           },
//           withCredentials: true,
//         })
//         .then((res) => {
//           const history = res.data.chatHistory;
//           const convertedMathChatHistory = history?.mathChatHistory?.map(
//             (item) => [item.question, item.answer]
//           );
//           const convertedScienceChatHistory = history?.scienceChatHistory?.map(
//             (item) => [item.question, item.answer]
//           );
//           setMathsTotalChats(convertedMathChatHistory);
//           setScienceTotalChats(convertedScienceChatHistory);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [userInfo]);
