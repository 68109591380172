import {
  ADD_INSTRUCTOR_COURSE,
  ADD_INSTRUCTOR_QUIZ,
  DELETE_QUIZ_QUESTION,
  EDIT_INSTRUCTOR_COURSE,
  EDIT_INSTRUCTOR_QUIZ,
  GET_ALL_INSTRUCTOR_COURSES,
  GET_ALL_INSTRUCTOR_QUIZES,
  REMOVE_INSTRUCTOR_COURSE,
  REMOVE_INSTRUCTOR_QUIZ,
} from "./keys";

export const getAllCourses = (dispatch) => (data) => {
  dispatch({
    type: GET_ALL_INSTRUCTOR_COURSES,
    payload: data,
  });
};
export const addCourse = (dispatch) => (data) => {
  dispatch({
    type: ADD_INSTRUCTOR_COURSE,
    payload: { ...data },
  });
};
export const editCourse = (dispatch) => (data) => {
  dispatch({
    type: EDIT_INSTRUCTOR_COURSE,
    payload: { ...data },
  });
};
export const deleteCourse = (dispatch) => (id) => {
  dispatch({
    type: REMOVE_INSTRUCTOR_COURSE,
    payload: id,
  });
};
export const getAllQuizes = (dispatch) => (data) => {
  dispatch({
    type: GET_ALL_INSTRUCTOR_QUIZES,
    payload: data,
  });
};
export const addQuiz = (dispatch) => (data) => {
  dispatch({
    type: ADD_INSTRUCTOR_QUIZ,
    payload: { ...data },
  });
};
export const editQuiz = (dispatch) => (data) => {
  dispatch({
    type: EDIT_INSTRUCTOR_QUIZ,
    payload: { ...data },
  });
};
export const deleteQuizQuestion = (dispatch) => (data) => {
  dispatch({
    type: DELETE_QUIZ_QUESTION,
    payload: { ...data },
  });
};
export const deleteQuiz = (dispatch) => (id) => {
  dispatch({
    type: REMOVE_INSTRUCTOR_QUIZ,
    payload: id,
  });
};
