import ReactQuillEditor from "../../ReactQuillEditor";
import FormSelect from "../../FormSelect";
import "react-quill/dist/quill.snow.css";
import { useContext } from "react";
import { AppDataContext } from "../../../context/appData";

const BasicInformation = (props) => {
  const {
    state: { appData },
  } = useContext(AppDataContext);
  const { next } = props;

  const CoursesLevel = [
    { value: "Intermediate", label: "Intermediate" },
    { value: "Beignners", label: "Beignners" },
    { value: "Advance", label: "Advance" },
  ];

  const initialValue =
    props?.data?.course_description?.length > 0
      ? props?.data?.course_description
      : `<p className="text-gray19 font-inter font-medium text-sm">Insert course description</p>
                      <p><br /></p>        
                      <p>Some initial <strong>bold</strong> text</p>
                      <p><br /></p><p><br /></p><p><br /></p><p><br /></p>`;

  return (
    <>
      {/* Card */}
      <div className="w-full mb-3 bg-white shadow-md rounded-md border flex flex-col items-start">
        <div className="border-b px-6 py-3 w-full">
          <h4 className="text-base font-inter font-semibold text-black">
            Basic Information
          </h4>
        </div>
        {/* Card body */}
        <div className="px-6 py-3 flex flex-col space-y-4 w-full">
          {/* Title  */}
          <div className="flex flex-col space-y-1">
            <label
              htmlFor="courseTitle"
              className="font-medium font-inter text-black/90 text-sm"
            >
              Course Title
            </label>
            <input
              type="text"
              placeholder="Course Title"
              id="course_title"
              name="title"
              value={props?.data?.title}
              onChange={props.handleChange}
              className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18"
            />
            <p className="text-xs text-black/80 font-inter font-medium">
              Write a 60 character course title.
            </p>
          </div>

          {/* course field */}
          <div className="flex flex-col space-y-1">
            <label className="font-medium font-inter text-black/90 text-sm">
              Course Field
            </label>
            <div className="w-full px-4 py-2 border border-gray19 rounded-md">
              <FormSelect
                options={appData?.courseCreationHelperData?.courseFields}
                id="courses_field"
                name="field"
                placeholder="Select Field"
                defaultselected={props?.data?.field}
                onChange={props.handleChange}
                className="text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full outline-none"
              />
            </div>
          </div>

          {/* Category */}
          <div className="flex flex-col space-y-1">
            <label className="font-medium font-inter text-black/90 text-sm">
              Category category
            </label>
            <div className="w-full px-4 py-2 border border-gray19 rounded-md">
              <FormSelect
                options={appData?.courseCreationHelperData?.categoryOptions}
                id="category_category"
                name="category"
                placeholder="Select Category"
                defaultselected={props?.data?.category}
                onChange={props.handleChange}
                className="text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full outline-none"
              />
            </div>
            <p className="text-xs text-black/80 font-inter font-medium">
              Help people find your courses by choosing categories that
              represent your course.
            </p>
          </div>

          {/* Courses level */}
          <div className="flex flex-col space-y-1">
            <label className="font-medium font-inter text-black/90 text-sm">
              Courses level
            </label>
            <div className="w-full px-4 py-2 border border-gray19 rounded-md">
              <FormSelect
                options={CoursesLevel}
                id="courses_level"
                name="level"
                placeholder="Select level"
                defaultselected={props?.data?.level}
                onChange={props.handleChange}
                className="text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full outline-none"
              />
            </div>
          </div>

          {/* Course Description*/}
          <div className="flex flex-col space-y-1">
            <label className="font-medium font-inter text-black/90 text-sm">
              Course Description
            </label>
            <ReactQuillEditor
              initialValue={initialValue}
              id="course_description"
              name="course_description"
              onChange={props.handleChange}
            />
            <p className="text-xs text-black/80 font-inter font-medium">
              A brief summary of your courses.
            </p>
          </div>
        </div>
      </div>
      {/* Button */}
      {props?.next && (
        <button
          className="bg-purple1 px-4 py-2 text-white font-inter font-medium rounded-md text-sm"
          onClick={next}
        >
          Next
        </button>
      )}
    </>
  );
};
export default BasicInformation;
