import React from "react";

const SubscriptionToggle = ({ subscriptionValue, setSubscriptionValue }) => {
  return (
    <div className="flex items-center space-x-4">
      <button
        className={`font-urbanist text-sm cursor-pointer px-6 py-3 rounded-[30px] ${
          subscriptionValue === "monthly"
            ? "text-white bg-purple6 font-bold"
            : "text-black1 font-medium bg-white border"
        }`}
        onClick={() => setSubscriptionValue("monthly")}
      >
        Monthly plan
      </button>

      <button
        className={`font inter text-sm cursor-pointer px-6 py-3 rounded-[30px] ${
          subscriptionValue === "annually"
            ? "text-white bg-purple6 font-bold"
            : "text-black1 font-medium bg-white border"
        }`}
        onClick={() => setSubscriptionValue("annually")}
      >
        Annual plan
      </button>
    </div>
  );
};

export default SubscriptionToggle;
