import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";
import React from "react";

const CourseDetailsSidebar = ({
  courseData,
  setExpanded,
  expanded,
  setSectionContent,
}) => {
  return (
    <div className="bg-white dark:bg-darkBgColor1 rounded-md px-2 py-4 h-full">
      <div className="border-b dark:border-b-purple2 px-4 pb-2 font-urbanist font-semibold dark:text-white text-black/80 text-base mb-5">
        Table of Contents
      </div>
      {courseData?.curriculum?.map((data) => (
        <div
          key={data.id}
          className={`mb-4 ${
            expanded === data.id
              ? "bg-purple8 dark:bg-purple2 py-3 rounded-2xl"
              : ""
          }`}
        >
          <div
            className={`flex items-center justify-between space-x-2 px-4 cursor-pointer`}
            onClick={() =>
              setExpanded((prev) => (prev === data.id ? undefined : data.id))
            }
          >
            <h5
              className={`mb-0 font-urbanist ${
                expanded === data.id ? "font-semibold" : "font-medium"
              } text-base dark:text-white text-black3`}
            >
              {data.sectionName}
            </h5>
            <ChevronDownIcon className="h-4 w-4 text-gray-800" />
          </div>
          {expanded === data.id ? (
            <div className="px-4 py-2 transition-all ease-in-out duration-300">
              {data?.lectures?.length > 0 ? (
                data?.lectures?.map((lecture) => (
                  <div
                    key={lecture.id}
                    className="flex items-center space-x-2 cursor-pointer"
                    onClick={() => setSectionContent(lecture)}
                  >
                    <div className="w-16">
                      <div className="h-9 w-9 bg-purple9 rounded-full flex items-center justify-center">
                        <PlayIcon className="h-4 w-4 dark:purple3 text-purple2" />
                      </div>
                    </div>
                    <div className="w-full">
                      <p className="font-urbanist font-normal dark:text-white text-gray22 text-sm">
                        {lecture.lectureName}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <span className="font-inter font-normal dark:text-white text-gray22 text-sm">
                  No Lecture Available
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default CourseDetailsSidebar;
