import React, { useContext, useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import { UserContext } from "../../../context/user";

const PaypalButtonsRender = ({
  setShowModal,
  amount,
  planName,
  planValidity,
  selectedPaymentMethod,
}) => {
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);
  // error state
  const [error, setError] = useState("");
  // paypal button loading state hook provided by @paypal/react-paypal-js
  const [{ isPending, isRejected }] = usePayPalScriptReducer();

  const createOrder = async (data) => {
    try {
      return axios
        .post(
          `${getURLs("paypal-checkout")}/create-order`,
          {
            description: `Your Vaisage ${planValidity} subscription`,
            amount: amount,
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then((res) => {
          return res?.data?.order?.id;
        })
        .catch((err) => {
          if (
            err?.response?.status === 400 ||
            err?.response?.status === 401 ||
            err?.response?.status === 500
          ) {
            setError(err?.response?.data?.message);
          }
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setError(error?.message);
    }
  };

  // custom handler func. to check whether the payment is successful or not
  const onApprove = async (data) => {
    // Order is captured on the server and the response is returned to the browser
    return axios
      .post(
        `${getURLs("paypal-checkout")}/capture-paypal-order`,
        {
          orderID: data?.orderID,
          planName,
          planValidity,
          subAmount: amount,
          selectedPaymentMethod,
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        // res.json();
        if (res.status === 200) {
          updateUser({
            ...userInfo,
            subscriptionDetails: res?.data?.subscriptionDetails,
          });
          setShowModal(true);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 ||
          err?.response?.status === 401 ||
          err?.response?.status === 500
        )
          setError(err?.response?.data?.message);
        console.log(err);
      });
  };
  return (
    amount &&
    planName &&
    planValidity &&
    selectedPaymentMethod &&
    Object.keys(userInfo)?.length && (
      <div className="flex flex-col items-center w-full mt-4">
        {error?.length > 0 && (
          <span className="font-inter font-medium text-xs sm:text-sm text-red-400 block mb-2">
            {error}
          </span>
        )}
        {isPending ? (
          <span className="text-center w-full text-black font-medium font-inter">
            Please wait, Paypal loading...
          </span>
        ) : isRejected ? (
          <span className="font-inter font-medium text-xs sm:text-sm text-red-400 block mb-2">
            Failed to load Paypal checkout
          </span>
        ) : (
          <PayPalButtons
            className="max-w-xl w-full"
            fundingSource="paypal"
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={onApprove}
          />
        )}
      </div>
    )
  );
};

export default PaypalButtonsRender;
