// import node module libraries
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const Pagination = ({
  previousPage,
  pageCount,
  pageIndex,
  gotoPage,
  nextPage,
}) => {
  return (
    <div className="pt-6 pb-6">
      <nav className="flex items-center w-full">
        <div className="flex items-center justify-center mb-0 w-full space-x-4">
          <button
            onClick={() => previousPage()}
            className="mx-1 px-4 py-2 border rounded-md hover:bg-[#744efd] hover:text-white text-gray-500"
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          {Array.from(Array(pageCount).keys()).map((page) => (
            <div
              key={page}
              className={`${
                pageIndex === page ? "bg-[#744efd] text-white" : "text-black/90"
              } px-5 py-2 border rounded-md font-inter font-normal text-sm`}
            >
              <button className="" onClick={() => gotoPage(page)}>
                {page + 1}
              </button>
            </div>
          ))}

          <button
            onClick={() => nextPage()}
            className="mx-1 px-4 py-2 border rounded-md hover:bg-[#744efd] hover:text-white text-gray-500"
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Pagination;
