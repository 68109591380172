import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

const CourseNotes = ({ handleSaveNote, savedNotes }) => {
  const [note, setNote] = useState("");

  const handleSave = () => {
    handleSaveNote(note);
    setNote("");
  };
  return (
    <div className="mt-5">
      <h5 className="font-urbanist font-semibold text-sm text-black dark:text-white block mb-4">
        Create a note
      </h5>

      <textarea
        rows={8}
        placeholder="You can save your notes here..."
        className="outline-none bg-gray37 dark:bg-purple2 px-4 py-2 dark:placeholder:text-white placeholder:text-gray30 dark:text-white text-gray30 text-sm w-full rounded-xl block mb-4"
      />

      <button
        className="border border-blue-400 text-blue-400 text-sm px-6 py-2 font-urbanist font-medium bg-white dark:bg-darkBgColor1 rounded-3xl"
        onClick={handleSave}
      >
        Save
      </button>

      {savedNotes?.length > 0 && (
        <div className="flex flex-col mt-12">
          {savedNotes?.map((note, index) => (
            <div key={note.id}>
              <div className="flex items-center space-x-2">
                <p className="text-black dark:text-whiite text-xs font-urbanist font-semibold">{`Notes ${
                  index + 1
                }`}</p>
                <PencilSquareIcon className="text-blue-400 h-4 w-4 cursor-pointer" />
              </div>
              <div className="bg-gray37 dark:bg-purple2 px-4 py-2 dark:text-white text-gray30 text-sm w-full rounded-xl">
                <p className="font-urbanist font-normal text-sm text-gray30">
                  {note.note}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseNotes;
