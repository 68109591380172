import React from "react";

const ShowOrEditInput = ({
  editActive,
  name,
  inputIdentifierName,
  inputValue,
  inputType,
  handleInputChange,
}) => {
  return !editActive ? (
    <div className="px-2 py-2 dark:bg-purple17 dark:text-white bg-gray-100/70 rounded-md font-urbanist font-medium text-sm md:text-base text-blue25 mb-5 whitespace-nowrap truncate max-w-md min-h-[40px]">
      {name}
    </div>
  ) : (
    <input
      className="px-2 py-2 dark:bg-purple17 dark:text-white bg-gray-100/70 rounded-md font-urbanist font-medium text-sm md:text-base text-blue25 mb-5 whitespace-nowrap truncate max-w-md"
      value={inputValue}
      type={inputType}
      name={inputIdentifierName}
      onChange={handleInputChange}
    />
  );
};

export default ShowOrEditInput;
