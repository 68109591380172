import { UserProvider } from "./user";
import { AppDataProvider } from "./appData";
import { InstructorDataProvider } from "./instructorData";
import { DarkModeProvider } from "./darkModeContext";

export default [
  UserProvider,
  AppDataProvider,
  InstructorDataProvider,
  DarkModeProvider,
];
