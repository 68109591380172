import React, { Fragment, useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";

const AddDescription = ({
  currentDescriptionObj,
  setCourseCurriculum,
  setShowDescriptionModal,
  showdescriptionModal,
  setDescriptionEditObj,
  id,
}) => {
  const [description, setDescription] = useState(
    currentDescriptionObj?.description
  );

  const addDescription = (e, lecturId) => {
    e.preventDefault();

    let updatedSections;
    setCourseCurriculum((prev) => (updatedSections = [...prev]));
    for (let section of updatedSections) {
      if (section.id === id) {
        for (let lecture of section.lectures) {
          if (lecture.id === currentDescriptionObj?.id) {
            lecture.description = description;
            break; // Stop searching once found
          }
        }
        break; // Stop searching once found
      }
    }

    setCourseCurriculum(updatedSections);
    handleClose();
  };

  const handleClose = () => {
    setShowDescriptionModal(false);
    setDescriptionEditObj({});
  };

  return (
    <Fragment>
      <CustomModal
        show={showdescriptionModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {showdescriptionModal && (
          <>
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Add Description
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <div className="mb-3">
                <input
                  type="text"
                  as="textarea"
                  placeholder="Add Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full"
                />
              </div>
            </div>
            <div className="w-full flex items-center space-x-4 px-6 pb-5">
              <button
                className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                onClick={addDescription}
              >
                Add Description
              </button>
              <button
                className="bg-white px-4 py-2 text-black/70 text-sm font-inter font-medium rounded-md border-gray22 border"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </>
        )}
      </CustomModal>
    </Fragment>
  );
};

export default AddDescription;
