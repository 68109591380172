import React, { useContext, useEffect, useRef, useState } from "react";
import StripeLogo from "../../images/stripe.svg";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { UserContext } from "../../context/user";
import { getURLs } from "../../urlConfig";
import PaymentForm from "./PaymentForm";

const StripeCheckout = ({
  planName,
  subscriptionAmount,
  subscriptionTimeline,
}) => {
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);

  const effectRan = useRef(false);

  const [clientSecret, setClientSecret] = useState("");

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  //   modal state for payment success
  const [showModal, setShowModal] = useState(false);

  const setPremiumSubscription = (intentSecret) => {
    axios
      .post(
        getURLs("set-subscription"),
        {
          planName: planName,
          subAmount: subscriptionAmount,
          planValidity: subscriptionTimeline,
          intentId: intentSecret,
          paymentMode: "stripe",
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        updateUser({ ...userInfo, subscriptionDetails: res.data });
        setShowModal(true);
      });
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (
      Object.keys(userInfo)?.length &&
      subscriptionAmount &&
      !effectRan?.current
    ) {
      axios
        .post(
          getURLs("stripe-checkout"),
          {
            amount: subscriptionAmount * 100,
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then((res) => {
          setClientSecret(res.data.clientSecret);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      effectRan.current = true;
    };
  }, [subscriptionAmount, userInfo]);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="w-full flex flex-col items-center space-y-4 px-2 py-8">
      <div className="flex flex-col space-y-2 w-full max-w-xl">
        <button className="w-full flex items-center justify-center font-medium font-poppins bg-white text-stripeColor border border-stripeColor py-3 px-4 rounded hover:bg-gray-100 hover:transition-all ease-in-out">
          <img
            src={StripeLogo}
            alt="PayPal Logo"
            className="w-12 object-contain"
          />
        </button>
      </div>
      <div className="max-w-xl w-full mt-4">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              setPremiumSubscription={setPremiumSubscription}
              showModal={showModal}
              setShowModal={setShowModal}
              planName={planName}
              subscriptionAmount={subscriptionAmount}
              subscriptionTimeline={subscriptionTimeline}
            />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default StripeCheckout;
