// import node module libraries
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../context/user";
import { InstructorDataContext } from "../context/instructorData";
import axios from "axios";
import { getURLs } from "../urlConfig";
import AddNewQuizQuestion from "../components/InstructorQuizes/AddNewQuizQuestion/AddNewQuizQuestion";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {
  ClockIcon,
  ListBulletIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import QuizAllQuestions from "../components/InstructorQuizes/QuizAllQuestions/QuizAllQuestions";

const QuizDetails = () => {
  // fetching users state
  const {
    state: { userInfo },
  } = useContext(UserContext);

  // fetching instructor context
  const { editQuiz, getAllQuizes, deleteQuizQuestion } = useContext(
    InstructorDataContext
  );
  // add question modal state
  const [modalShow, setModalShow] = useState(false);

  // getting the payload
  const { state } = useLocation();
  // edit and delete question modal states
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openedQuiz, setOpenedQuiz] = useState({});
  const [currentQuiz, setCurrentQuiz] = useState({});

  // selected option state
  const [selectedAnswers, setSelectedAnswers] = useState({});

  // handleoption selection
  function handleAnswerSelection(questionId, answerIndex) {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answerIndex + 1,
    }));
  }

  // confirm delete handler
  const handleConfirmDelete = () => {
    axios
      .put(
        getURLs("delete-quiz-question"),
        {
          quizId: state,
          questionId: openedQuiz?.id,
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // updating data
          const updatedData = {
            ...currentQuiz,
            questions: currentQuiz?.questions?.filter(
              (item) => item.id !== openedQuiz?.id
            ),
          };
          // setting data
          setCurrentQuiz(updatedData);
          // deleteing from global object
          deleteQuizQuestion({
            quizId: state,
            questionId: openedQuiz?.id,
          });
          // setting state of modal
          setOpenDeleteModal(false);
          setOpenedQuiz({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // edit question handler
  const addQuestionHandler = (updatedQuestionData) => {
    axios
      .put(
        getURLs("edit-quiz-question"),
        {
          quizId: state,
          questionId: openedQuiz?.id,
          updatedQuestionData: updatedQuestionData,
          isEditing: openEditModal,
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        // if request successful

        // updating state
        let updatedQuiz;
        if (openEditModal) {
          // for editing question
          const updatedQuestions = currentQuiz?.questions?.map((item) => {
            if (item.id === openedQuiz?.id) {
              return { ...item, ...updatedQuestionData };
            } else {
              return item;
            }
          });

          updatedQuiz = {
            ...currentQuiz,
            questions: updatedQuestions,
          };
        } else {
          // for adding question
          updatedQuiz = {
            ...currentQuiz,
            questions: [...currentQuiz.questions, updatedQuestionData],
          };
        }
        setCurrentQuiz(updatedQuiz);
        // updating context
        editQuiz({
          quizId: state,
          questionId: openedQuiz?.id,
          updatedQuestionData: updatedQuestionData,
          isEditing: openEditModal,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetching question data from id
  useEffect(() => {
    if (state) {
      axios
        .get(getURLs("get-quiz"), {
          params: { state },
          withCredentials: true,
          headers: { "auth-token": userInfo?.authToken },
        })
        .then((res) => {
          getAllQuizes([res?.data?.quizData]);
          setCurrentQuiz(res?.data?.quizData);
        })
        .catch((err) => console.log(err));
    }
  }, [state, userInfo]);

  return (
    <>
      <div className="mb-4 bg-white border rounded px-4 py-6">
        <div className="lg:flex justify-between items-center">
          <div className="flex items-center space-x-4 mb-4 lg:mb-0">
            <img
              src={currentQuiz?.image}
              alt=""
              className="rounded w-24 h-24 object-contain"
            />
            <div className="flex flex-col space-y-1">
              <h3 className="text-black font-inter font-semibold text-lg lg:text-xl">
                {currentQuiz?.title}
              </h3>
              <div className="flex items-center space-x-2">
                <span className="flex items-center">
                  <span className="me-2 align-middle">
                    <ListBulletIcon className="h-5 w-5 text-gray-400" />
                  </span>
                  {currentQuiz?.questions?.length}
                </span>
                <span className="flex items-center">
                  <span className="me-2 align-middle">
                    <ClockIcon className="h-5 w-5 text-gray-400" />
                  </span>
                  {currentQuiz?.duration}
                </span>
                <span className="flex items-center">
                  <span className="me-2 align-middle">
                    <NewspaperIcon className="h-5 w-5 text-gray-400" />
                  </span>
                  Result
                </span>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block">
            {/* add question */}
            <button
              className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
              onClick={() => setModalShow(true)}
            >
              Add new Questions
            </button>
          </div>
        </div>
      </div>
      {/* current quiz questions */}
      <QuizAllQuestions
        currentQuiz={currentQuiz}
        setOpenDeleteModal={setOpenDeleteModal}
        setOpenEditModal={setOpenEditModal}
        setOpenedQuiz={setOpenedQuiz}
        selectedAnswers={selectedAnswers}
        setSelectedAnswers={setSelectedAnswers}
        handleAnswerSelection={handleAnswerSelection}
      />

      {/* add or edit question modal*/}
      <AddNewQuizQuestion
        show={openEditModal || modalShow}
        usedForEditing={openEditModal}
        onHide={() => {
          setModalShow(false);
          setOpenEditModal(false);
          setOpenedQuiz({});
        }}
        userInfo={userInfo}
        addQuestionHandler={addQuestionHandler}
        currentQuestionState={openedQuiz}
      />
      {/* confirm delete question modal*/}
      <ConfirmDeleteModal
        showModal={openDeleteModal}
        closeModal={() => {
          setOpenDeleteModal(false);
          setOpenedQuiz({});
        }}
        handleConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete this question`}
      />
    </>
  );
};

export default QuizDetails;
