import React, { Fragment, useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import {
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

const ContextAwareToggle = ({
  children,
  eventKey,
  callback,
  lectureId,
  currentLectureName,
  setCourseCurriculum,
  courseCurriculum,
  id,
  setExpanded,
}) => {
  // state to open edit modal
  const [openEditLectureModal, setOpenEditLectureModal] = useState(false);

  // lecture name state
  const [newLectureName, setNewLectureName] = useState(currentLectureName);
  // edit lecture name
  const editLecture = () => {
    let updatedSections;
    setCourseCurriculum((prev) => (updatedSections = [...prev]));
    updatedSections = updatedSections?.map((section) => {
      if (section.id === id) {
        return {
          ...section,
          lectures: section.lectures.map((lecture) => {
            if (lecture.id === lectureId) {
              return {
                ...lecture,
                lectureName: newLectureName,
              };
            }
            return lecture;
          }),
        };
      }
      return section;
    });

    setCourseCurriculum(updatedSections);
  };

  // delete a lecture
  const deleteLecture = () => {
    let updatedSections;
    updatedSections = [...courseCurriculum];
    console.log(updatedSections);
    updatedSections = updatedSections?.map((section) => {
      if (section.id === id) {
        return {
          ...section,
          lectures: section.lectures.filter(
            (lecture) => lecture.id !== lectureId
          ),
        };
      }
      return section;
    });

    setCourseCurriculum(updatedSections);
  };

  const handleClose = () => setOpenEditLectureModal(false);

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <h5 className="mb-0">
          <span
            className="align-middle p-1"
            onClick={() =>
              setExpanded((prev) =>
                prev === lectureId ? undefined : lectureId
              )
            }
          >
            {children}
          </span>
        </h5>
        <div className="flex items-center space-x-2">
          <PencilSquareIcon
            className="text-gray-400 h-3 w-3 cursor-pointer"
            onClick={() => setOpenEditLectureModal(true)}
          />

          <TrashIcon
            className="text-gray-400 h-3 w-3 cursor-pointer"
            onClick={deleteLecture}
          />

          <ChevronDownIcon
            className="text-gray-400 h-3 w-3 cursor-pointer"
            onClick={() =>
              setExpanded((prev) =>
                prev === lectureId ? undefined : lectureId
              )
            }
          />
        </div>
      </div>

      <CustomModal
        show={openEditLectureModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {openEditLectureModal && (
          <>
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Edit Lecture Name
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <div className="mb-3" controlId="formaddnewlecture">
                <input
                  type="text"
                  as="textarea"
                  placeholder="Add Description"
                  value={newLectureName}
                  onChange={(e) => setNewLectureName(e.target.value)}
                  className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full"
                />
              </div>
            </div>
            <div className="w-full flex items-center space-x-4 px-6 pb-5">
              <button
                className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                onClick={editLecture}
              >
                Edit Lecture
              </button>
              <button
                className="bg-white px-4 py-2 text-black/70 text-sm font-inter font-medium rounded-md border-gray22 border"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </>
        )}
      </CustomModal>
    </Fragment>
  );
};

export default ContextAwareToggle;
