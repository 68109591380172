import React from "react";
import MathJax from "react-mathjax2";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeMathjax from "rehype-mathjax";
import remarkGfm from "remark-gfm";
import "./Markdown.css";

const MarkdownRenderWithMath = ({ text }) => {
  return (
    <div className="markdown-container text-sm break-words">
      <MathJax.Context
        input="ascii"
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
        options={{
          asciimath2jax: {
            useMathMLspacing: false,
            delimiters: [
              ["$$", "$$"],
              ["$", "$"],
              ["\\(", "\\)"],
            ],
          },
        }}
      >
        <MathJax.Text
          className="w-full"
          text={
            <Markdown
              rehypePlugins={[rehypeMathjax, rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              children={text}
              components={{}}
            />
          }
        />
      </MathJax.Context>
    </div>
  );
};

export default MarkdownRenderWithMath;
