import React, { useContext, useEffect } from "react";
import { AppDataContext } from "../context/appData";
import ImageWithText from "../components/PolicyPagesComponents/ImageWithText/ImageWithText";

const Disclosure = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col px-4 md:px-8 lg:px-16 py-5 bg-white dark:bg-darkBgColor1">
      <ImageWithText
        img={appData?.utilityImg}
        heading={appData?.disclosureAgreementPageData?.heading}
        text={
          appData?.disclosureAgreementPageData?.subheading +
          " " +
          appData?.disclosureAgreementPageData?.description
        }
      />
      {/* <div className="lg:px-8 px-4 py-16 relative">
        <p className="font-inter font-medium text-base text-gray-800 block mb-4">
          <span className="font-semibold">
            {appData?.disclosureAgreementPageData?.subheading}{" "}
          </span>{" "}
          <br /> <br />
          {appData?.disclosureAgreementPageData?.description}
        </p>
      </div> */}
    </div>
  );
};

export default Disclosure;
