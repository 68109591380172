import React, { useContext, useEffect, useState } from "react";
import ChooseSubscription from "../components/ChooseSubscription";
import SubscriptionCards from "../components/SubscriptionCards";
import SubscriptionFaq from "../components/SubscriptionFaq";
import { AppDataContext } from "../context/appData";

const Pricing = () => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const [pricingPageData, setPricingPageData] = useState();

  const [subscriptionValue, setSubscriptionValue] = useState("monthly");
  const [selectedPlan, setSelectedPlan] = useState(1);

  useEffect(() => {
    setPricingPageData(appData?.pricingPageData);
  }, [appData?.pricingPageData]);

  return (
    <div className="relative bg-white dark:bg-darkBgColor1">
      <section className="mb-8 pt-8">
        <ChooseSubscription
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          pageData={pricingPageData}
        />
      </section>

      <section className="mb-8 xl:mb-32">
        <SubscriptionCards
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          pageData={pricingPageData}
        />
      </section>
      <div className="absolute bottom-[55%] transform left-0 bg-neonColorBlue2 blur-[100px] right-1/2 h-[30%]" />
      <div className="absolute bottom-[55%] transform right-0 bg-neonPurpleColor blur-[100px] left-1/2 h-[30%]" />

      <section className="pb-8 xl:pb-32">
        <SubscriptionFaq pageData={pricingPageData} />
      </section>
    </div>
  );
};

export default Pricing;
