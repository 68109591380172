import React from "react";
import Tab from "./Tab/Tab";
import { PencilSquareIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import ReactGA from "react-ga4";
import DarkModeToggle from "../DarkModeToggle";
import { useNavigate } from "react-router-dom";
import { TabsData } from "../../utils/staticData";

const DashboardSidebar = ({
  userInfo,
  selectedTab,
  setSelectedTab,
  searchesLeft,
  handleLogout,
}) => {
  const navigate = useNavigate();

  const handleTabClick = (tabID, tabTitle) => {
    ReactGA.event({
      category: "Dashboard Tab Click",
      action: `Clicked on ${tabTitle} tab`,
      label: `${tabTitle} Rendered`, // optional
    });

    setSelectedTab(tabID);
  };
  return (
    <div className="relative flex flex-col dark:bg-darkBgColor1 bg-white items-center px-8 py-2 w-full ">
      <div
        className="hidden md:inline-block border rounded-lg w-full mb-4 cursor-pointer"
        style={{
          background:
            "linear-gradient(white, white) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
          border: "2px solid transparent",
        }}
        onClick={() => navigate("/my-profile")}
      >
        <div className="px-4 py-2 flex items-center space-x-2 bg-white dark:bg-purple2 rounded-md">
          {userInfo?.profileImage ? (
            <img
              loading="lazy"
              src={userInfo?.profileImage}
              alt="profile"
              className="h-10 object-contain rounded-full"
            />
          ) : (
            <UserCircleIcon className="h-10 dark:text-white text-gray16" />
          )}

          <p className="font-inter font-bold text-sm dark:text-white text-blue34 whitespace-nowrap truncate max-w-[100px]">
            {userInfo?.name}
          </p>

          <PencilSquareIcon className="text-purple1 h-5 w-5 cursor-pointer" />
        </div>
      </div>

      {TabsData.map((tab) => (
        <Tab
          key={tab.id}
          index={tab.id}
          selected={selectedTab}
          title={tab.text}
          Icon={tab.icon}
          clickHandler={handleTabClick}
        />
      ))}

      <div className="mt-4 pb-5">
        <DarkModeToggle useInDashboard />
      </div>
    </div>
  );
};

export default DashboardSidebar;
