import React from "react";

const CustomRadioButton = ({ selected, value, handleChange }) => {
  return (
    <div
      className={`flex items-center justify-center h-6 w-6 rounded-full ${
        selected ? "bg-purple1 border-purple1" : "bg-white border-gray19"
      } border`}
      onClick={() => handleChange(value)}
    >
      <div className="h-3 w-3 rounded-full bg-white" />
    </div>
  );
};

export default CustomRadioButton;
