import React from "react";

const Tab = ({ Icon, title, selected, clickHandler, index }) => {
  return (
    <div
      className={`flex items-center space-x-4 cursor-pointer px-4 py-2 md:py-3 rounded-r-lg w-full max-w-xs ${
        selected === index
          ? "bg-gray33 dark:bg-purple4 border-l-2 border-l-blue31"
          : "bg-inherit"
      }`}
      onClick={() => clickHandler(index, title)}
    >
      <Icon
        className={`h-6 w-6 
         ${selected === index ? "text-blue31" : "text-blue29 dark:text-white"}
        `}
      />

      <p
        className={`font-urbanist text-[12px] sm:text-sm ${
          selected === index
            ? "text-white font-bold"
            : "text-blue29 font-medium dark:text-white"
        }`}
        style={{
          backgroundImage:
            selected === index &&
            "linear-gradient(0deg, #2898FF, #2898FF),linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          WebkitBackgroundClip: "text",
          color: selected === index && "transparent",
        }}
      >
        {title}
      </p>
    </div>
  );
};

export default Tab;
