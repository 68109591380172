import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButtonsRender from "./PaypalButtonsRenderer";
import PaymentSuccessfulModal from "../../modal/PaymentSuccessfulModal";

const PaypalCheckout = ({
  planName,
  subscriptionAmount,
  subscriptionTimeline,
}) => {
  // navigate state
  const navigate = useNavigate();
  // initial options for paypal
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  // payment sucessful modal state
  const [showModal, setShowModal] = useState(false);

  const handleClosePopup = () => {
    navigate("/my-profile", { replace: true });
    setShowModal(false);
  };

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        {/* custom modal as paypal button state hook can only be used inside PaypalScriptProvider */}
        <PaypalButtonsRender
          setShowModal={setShowModal}
          amount={subscriptionAmount}
          planName={planName}
          planValidity={subscriptionTimeline}
          selectedPaymentMethod={"paypal"}
        />
      </PayPalScriptProvider>
      {showModal && (
        <PaymentSuccessfulModal handleClosePopup={handleClosePopup} />
      )}
    </>
  );
};

export default PaypalCheckout;
