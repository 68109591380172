import React, { useContext, useState } from "react";
import AuthImageWithHeading from "../components/AuthImageWithHeading";
import { AppDataContext } from "../context/appData";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { UserContext } from "../context/user";

const VerifyOtp = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  // user context to update
  const { updateUser } = useContext(UserContext);

  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state;
  const [otpInput, setOtpInput] = useState("");

  const [otpSubmit, setOtpSubmit] = useState(false);
  const [otpReseding, setOtpResending] = useState(false);
  const [resendOtpSuccess, setResendOtpSuccess] = useState(false);
  const [formError, setFormError] = useState({ show: false, message: "" });

  const handleResentOtp = () => {
    setOtpResending(true);
    axios
      .post(getURLs("resend-otp"), {
        email: data?.email,
      })
      .then((res) => {
        if (res.status === 200) {
          setResendOtpSuccess(true);
          setOtpResending(false);
          setFormError({
            show: false,
            message: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setResendOtpSuccess(false);
        setOtpResending(false);
        setFormError({
          show: true,
          message: err?.message,
        });
      });
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (otpInput.trim(" ").length === 0) {
      setFormError({
        show: true,
        message: "Please enter a valid email address",
      });

      return;
    }
    setOtpSubmit(true);

    if (data?.isSigningup) {
      const userRole = data?.role || ["student"];
      axios
        .post(
          getURLs("signupUrl"),
          {
            name: data.name,
            email: data.email,
            password: data.password,
            otp: otpInput,
            role: userRole,
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res?.data?.authToken) {
            // if auth token received get user detais
            const axiosReq = axios.create({
              headers: {
                "auth-token": res?.data?.authToken,
              },
              withCredentials: true,
            });
            axiosReq
              .post(getURLs("getUserDetails"), {}, { withCredentials: true })
              .then((response) => {
                if (response?.data?.user) {
                  updateUser({
                    ...response?.data?.user,
                    authToken: res?.data?.authToken,
                  });
                  localStorage.setItem("persist", true);
                  navigate("/dashboard", {
                    replace: true,
                  });
                  setOtpSubmit(false);
                }
                setOtpSubmit(false);
              })
              .catch((err) => {
                if (
                  err?.response?.status === 400 ||
                  err?.response?.status === 401 ||
                  err?.response?.status === 500
                )
                  setFormError({
                    show: true,
                    message: err?.response?.data?.message || err?.message,
                  });
                setOtpSubmit(false);
                console.log(err);
              });
          }
        })
        .catch((err) => {
          if (
            err?.response?.status === 400 ||
            err?.response?.status === 401 ||
            err?.response?.status === 500
          )
            setFormError({ show: true, message: err?.response?.data?.message });
          setOtpSubmit(false);
          console.log(err);
        });
    } else {
      axios
        .post(getURLs("verify-otp"), { otp: otpInput, email: data?.email })
        .then((res) => {
          if (res.status === 200) {
            setOtpSubmit(false);
            navigate("/set-new-password", {
              state: data?.email,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setFormError({
            show: true,
            message: err?.response?.data?.message || err?.message,
          });
          setOtpSubmit(false);
        });
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center lg:items-start py-5 dark:bg-darkBgColor1">
      <AuthImageWithHeading
        imageSrc={appData?.loginPageData?.sectionImage}
        heading={appData?.loginPageData?.heading}
      />

      <div className="lg:basis-1/2 w-full flex flex-col items-center relative mt-4 lg:mt-0">
        <form
          className="z-10 flex flex-col justify-center border dark:border-none bg-white dark:bg-purple2 shadow-shadow13 dark:shadow-none rounded-[30px] w-full max-w-md px-6 py-8"
          onSubmit={handleOtpSubmit}
        >
          <h2 className="font-urbanist font-bold text-lg dark:text-white text-black block mb-5">
            Verify OTP
          </h2>

          <p className="font-urbanist font-medium text-sm text-gray-500 block mb-10">
            {`We’ve sent a 4 digit code to your ${data?.email}`}
          </p>

          <span className="font-urbanist font-semibold dark:text-white text-black text-base block mb-1">
            Enter OTP
          </span>
          {/*  email input */}
          <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-2">
            <input
              className="flex-grow rounded-lg outline-none px-2 py-2 text-sm lg:text-base placeholder:text-xs font-inter font-normal text-gray-600 dark:text-white bg-transparent"
              type="text"
              placeholder="Enter 4 Digit Code"
              value={otpInput}
              onChange={(e) => setOtpInput(e.target.value)}
            />
          </div>

          <button
            className="self-start font-urbanist font-normal text-xs text-blue27 disabled:text-blue27/60 cursor-pointer"
            onClick={handleResentOtp}
            disabled={otpReseding}
          >
            Resend OTP
          </button>

          {resendOtpSuccess && (
            <p className="mt-2 text-blue-500 text-xs font-urbanist font-medium dark:text-white">
              OTP resend successfully
            </p>
          )}

          <div className="w-full flex items-center justify-center mt-5">
            <button
              className="w-full px-2 py-2 mt-4 max-w-xl font-urbanist font-bold text-white text-xs md:text-base xl:text-lg rounded-lg"
              onClick={handleOtpSubmit}
              style={{
                background: !otpSubmit
                  ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                  : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
              }}
              disabled={otpSubmit}
            >
              Continue
            </button>
          </div>

          {formError?.show && (
            <span className="text-xs sm:text-sm font-urbanist text-red-500 font-medium mt-2 block">
              {formError?.message}
            </span>
          )}
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
