import React, { useContext, useEffect, useState } from "react";
import SuccessAbout from "../components/SuccessAbout";
import SuccessAdvantages from "../components/SuccessAdvantages";
import AboutProject from "../components/AboutProject";
import AboutProject2 from "../components/AboutProject2";
import { AppDataContext } from "../context/appData";

const Success = () => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const [successPageData, setSuccesspageData] = useState({});

  useEffect(() => {
    setSuccesspageData(appData?.successPageData);
  }, [appData?.successPageData]);

  return (
    <div className="bg-bgColor4">
      <section className="mb-8 xl:mb-16">
        <SuccessAbout appData={successPageData} />
      </section>

      <section className="mb-8 xl:mb-32">
        <SuccessAdvantages appData={successPageData} />
      </section>

      <section className="bg-bgColor5 pb-96 md:pb-56">
        <AboutProject appData={successPageData} />
      </section>

      <section className="bg-bgColor4 pt-72 md:pt-56 pb-14">
        <AboutProject2 appData={successPageData} />
      </section>
    </div>
  );
};

export default Success;
