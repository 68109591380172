import React from "react";
import { Link } from "react-router-dom";

const AdminSidebarItem = ({ title, selected, routeTo, Icon }) => {
  return (
    <Link to={`${routeTo}`}>
      <div
        className={`flex space-x-2 items-center ${
          selected ? "text-white" : "text-gray-300"
        } hover:text-white cursor-pointer`}
      >
        <Icon className={`h-5 w-5`} />
        <span className={`font-inter font-normal text-sm md:text-base`}>
          {title}
        </span>
      </div>
    </Link>
  );
};

export default AdminSidebarItem;
