import React, { useContext, useState } from "react";
import QuizPagination from "../components/QuizAttempt/QuizPagination/QuizPagination";
// import QuizTimer from "../components/QuizAttempt/QuizTimer/QuizTimer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QuizProgress from "../components/QuizAttempt/QuizProgress/QuizProgress";
import QuizQuestion from "../components/InstructorQuizes/QuizQuestion/QuizQuestion";
// import { convertMinutesToHoursAndMinutes } from "../utils/utils";
import { getURLs } from "../urlConfig";
import axios from "axios";
import { UserContext } from "../context/user";
import ImageUploadingModal from "../components/ImageUploadingModal";

const QuizAttempt = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();
  const selectedQuiz = location.state;
  const [currentQuestionNo, SetCurrentQuestionNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(1);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = selectedQuiz?.questions.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const nPages = Math.ceil(selectedQuiz?.questions?.length / recordsPerPage);
  // const { hours, minutes } = convertMinutesToHoursAndMinutes(
  //   selectedQuiz?.duration,
  //   true
  // );

  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [quizSubmitting, setQuizSubmitting] = useState(false);

  function handleSelectAnswer(questionId, answerIndex) {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answerIndex + 1,
    }));
  }

  const submitQuiz = () => {
    setQuizSubmitting(true);
    axios
      .post(
        getURLs("submit-quiz"),
        { quizId: selectedQuiz?._id, selectedAnswers },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        setQuizSubmitting(false);
        navigate("/quiz/result", { state: res.data, replace: true });
      })
      .catch((err) => {
        setQuizSubmitting(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="px-4 py-3 md:px-8 xl:px-32 md:py-5 bg-gray-100/20">
        <div className="mb-4">
          <div>
            {/* Question Title + Timer */}
            <div className="flex justify-between items-center border-b pb-3 mb-3">
              <div className="flex items-center">
                {/* quiz img */}
                <img
                  src={selectedQuiz?.image}
                  alt=""
                  className="h-24 w-24 object-contain rounded-md"
                />
                {/* quiz content */}
                <div className="ms-3">
                  <h3 className="mb-0">{selectedQuiz?.title}</h3>
                </div>
              </div>
              {/* Timer */}
              {/* <QuizTimer
                hours={hours}
                minutes={minutes}
                seconds={0}
                submitQuiz={submitQuiz}
              /> */}
            </div>

            {/* Progress */}
            <QuizProgress
              currentQuestion={currentQuestionNo}
              totalQuestion={selectedQuiz?.questions?.length}
            />

            {/* Question(s) */}
            <div className="mt-5">
              <span>Question {currentQuestionNo}</span>
              {currentRecords?.[0] && (
                <QuizQuestion
                  item={currentRecords?.[0]}
                  handleAnswerSelection={handleSelectAnswer}
                  selectedAnswers={selectedAnswers}
                  setSelectedAnswers={setSelectedAnswers}
                />
              )}
            </div>
          </div>
        </div>

        {/* Quiz Pagination */}
        <QuizPagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentQuestionNo={SetCurrentQuestionNo}
          setCurrentPage={setCurrentPage}
          submitQuiz={submitQuiz}
        />
      </div>

      <ImageUploadingModal
        modalOpen={quizSubmitting}
        message={`Please wait we are evaluating your response...`}
      />
    </>
  );
};

export default QuizAttempt;
