// import node module libraries
import React, {
  useMemo,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";

// import MDI icons
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";

// import utility file

import axios from "axios";
import { UserContext } from "../../context/user";
import { InstructorDataContext } from "../../context/instructorData";
import { ClockIcon } from "@heroicons/react/24/outline";
import LevelIcon from "./LevelIcon/LevelIcon";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import Pagination from "./Pagination/Pagination";
import { getURLs } from "../../urlConfig";
import { numberWithCommas } from "../../utils/utils";
import ActionMenu from "../ActionMenu";

const InstructorCourses = () => {
  // getting user context
  const {
    state: { userInfo },
  } = useContext(UserContext);
  // getting the courses context
  const {
    state: { instructorCourses },
    getAllCourses,
    deleteCourse,
  } = useContext(InstructorDataContext);

  // delete course modal state
  const [openDeleteModal, setOpenDeleteModal] = useState({
    show: false,
    idToDelete: "",
  });

  //   search text state
  const [searchText, setSearchText] = useState("");
  const [toggleMenu, setToggleMenu] = useState({ activeId: 0 });

  const handleToggleClick = (id) => {
    setToggleMenu((prev) =>
      prev?.activeId === id ? { activeId: 0 } : { activeId: id }
    );
  };

  const deleteInstructorCourse = (id) => {
    axios
      .post(
        getURLs("delete-instructor-course"),
        {
          idToDelete: id,
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // update courses
          deleteCourse(id);
          setOpenDeleteModal({ show: false, idToDelete: "" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetching all the courses for the instructor
  useEffect(() => {
    // getting instructor info
    if (
      userInfo?.role?.find((data) => data === "instructor" || data === "admin")
    ) {
      // getting the courses
      axios
        .get(getURLs("get-instructor-courses"), {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          getAllCourses(res?.data?.allCourses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);
  const [position, setPosition] = useState({ s: 0, y: 0 });

  const columns = useMemo(
    () => [
      {
        accessor: "image",
        Header: "Courses",
        Cell: ({ value, row }) => {
          return (
            <div className="flex flex-col md:flex-row items-start space-x-0 space-y-2 md:space-y-0 md:space-x-4">
              <div>
                <Link to="#">
                  <img
                    src={value}
                    alt="course_thumbnail"
                    className="rounded-md h-20 w-20 object-contain"
                  />
                </Link>
              </div>
              <div className="lg:ms-3 mt-2 lg:mt-0">
                <h4 className="mb-1 text-base font-inter font-medium text-black">
                  <Link to="#" className="text-inherit">
                    {row?.original?.title}
                  </Link>
                </h4>
                <div className="mb-0 flex items-center space-x-2">
                  <p className="flex items-center space-x-2">
                    <ClockIcon className="h-4 w-4 mr-2" />
                    {row?.original?.duration}
                  </p>
                  <p className="flex items-center space-x-2">
                    <LevelIcon level={row.original.level} />
                    {row?.original?.level}
                  </p>
                </div>
                {/* {row?.original?.progress && (
                  <ProgressBar
                    variant="success"
                    className="mt-2"
                    now={row.original.progress}
                    style={{ height: "3px" }}
                  />
                )} */}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "sales",
        Header: "Students",
        Cell: ({ value, row }) => {
          return numberWithCommas(value);
        },
      },

      {
        accessor: "rating",
        Header: "Rating",
        Cell: ({ value, row }) => {
          return (
            <Fragment>
              <p className="text-orange-200 flex items-center font-inter font-normal">
                {value}
                <Icon path={mdiStar} size={0.6} />
                <span className="text-gray20">
                  ({numberWithCommas(row.original.votes)})
                </span>
              </p>
            </Fragment>
          );
        },
      },

      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value }) => {
          return (
            <div
              bg={`${
                value === "Draft"
                  ? "info"
                  : value === "Live"
                  ? "success"
                  : value === "Deleted"
                  ? "danger"
                  : "warning"
              } `}
            >
              {value}
            </div>
          );
        },
      },
      {
        accessor: "action",
        Header: "",
        Cell: ({ row }) => {
          return (
            <ActionMenu
              handleToggleClick={handleToggleClick}
              id={row?.original?._id}
              courseObj={row?.original}
              setOpenDeleteModal={setOpenDeleteModal}
              showMenu={toggleMenu?.activeId === row?.original?._id?.toString()}
              closeActionMenu={() => setToggleMenu({ activeId: undefined })}
              position={position}
              setPosition={setPosition}
              menuPlacement={"bottom-4 -left-28"}
            />
          );
        },
      },
      {
        accessor: "level",
        Header: "",
        show: false,
      },
      {
        accessor: "votes",
        Header: "",
        show: false,
      },
      {
        accessor: "title",
        Header: "",
        show: false,
      },
      {
        accessor: "duration",
        Header: "",
        show: false,
      },
    ],
    [userInfo, toggleMenu]
  );

  const data = useMemo(() => instructorCourses, [instructorCourses]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    // setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 6,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  // const sortby = [
  //   { value: "Date Created", label: "Date Created" },
  //   { value: "Newest", label: "Newest" },
  //   { value: "High Rated", label: "High Rated" },
  //   { value: "Law Rated", label: "Law Rated" },
  //   { value: "High Earned", label: "High Earned" },
  // ];

  return (
    <div className="flex flex-col bg-white rounded-md shadow-sm">
      <div className="">
        <div className="mb-3 lg:mb-0 border-b px-8 py-4 space-y-2">
          <h3 className="mb-0 text-lg font-inter font-semibold text-black/90">
            Courses
          </h3>
          <p className="mb-0 text-sm text-gray-400 font-inter font-normal">
            Manage your courses and its update like live, draft and insight.
          </p>
        </div>
        <div className="px-4 py-4">
          <input
            className="w-full bg-white px-4 py-2 placeholder:text-gray-500 text-gray-500 text-sm focus-none outline-none border border-gray19 rounded-md"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search your Courses"
          />
        </div>
        <div className="p-0 pb-5 w-full">
          <div>
            <div className="overflow-x-auto">
              <table
                {...getTableProps()}
                className="whitespace-nowrap align-middle w-full min-w-[600px] overflow-x-auto"
                cellPadding={20}
                cellSpacing={10}
              >
                <thead className="bg-gray21 border-b">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className="text-start text-sm font-inter font-medium text-black"
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-gray21">
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="text-gray20 align-middle"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pagination @ Footer */}
          <Pagination
            previousPage={previousPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            nextPage={nextPage}
          />
        </div>
      </div>
      {/* confirm uquiz delete modal */}
      <ConfirmDeleteModal
        showModal={openDeleteModal?.show}
        closeModal={() => {
          setOpenDeleteModal({ show: false, idToDelete: "" });
        }}
        handleConfirm={() =>
          deleteInstructorCourse(openDeleteModal?.idToDelete)
        }
        message={`Are you sure you want to delete this course`}
      />
    </div>
  );
};

export default InstructorCourses;
