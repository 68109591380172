import React from "react";
import { Link } from "react-router-dom";
import LevelIcon from "../InstructorCourses/LevelIcon/LevelIcon";
import {
  BookmarkIcon,
  // ClockIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
// import Ratings from "../NewReviewsSection/Ratings/Ratings";
// import { numberWithCommas } from "../../utils/utils";

const CourseCard = ({ data }) => {
  return (
    <div className="mb-4 hover:shadow-md dark:bg-purple2 bg-white border dark:border-[#3C006C] dark:shadow-shadow12 rounded-lg pt-2 pb-4">
      <Link to={`/course-details/${data?._id}`} state={data}>
        <div className="h-1/2 w-full flex ">
          <div className="w-full h-full px-2 relative">
            <img
              src={data?.image}
              alt="course_thumbnail"
              className="w-full h-full object-cover rounded-md"
            />

            <div
              className={`absolute top-2 left-4 px-3 py-1 font-urbanist text-white font-medium text-xs sm:text-base ${
                data?.price === undefined ||
                data?.price <= 0 ||
                data?.price - data?.discount <= 0
                  ? "bg-background8 "
                  : "bg-purple1"
              } rounded-2xl `}
            >
              {data?.price === undefined ||
              data?.price <= 0 ||
              data?.price - data?.discount <= 0
                ? "Free"
                : "$" + (data?.price - data?.discount)}
            </div>
          </div>
        </div>
        {/* Card body  */}
        <div className="px-4 py-3">
          <h3 className="text-base dark:text-white text-black/80 font-urbanist font-semibold mb-2 flex flex-wrap line-clamp-2 max-h-16">
            {data?.title}
          </h3>
          <div className="flex items-center space-x-2 font-inter font-normal text-gray20">
            {/* <div className="flex items-center space-x-1">
              <ClockIcon className="h-4 w-4 text-gray-700" />
              {data?.duration}
            </div> */}
            <div className="flex items-center space-x-1">
              <LevelIcon level={data?.level} />
              {data?.level}
            </div>
          </div>
          {/* <div
            className={`flex items-center ${
              data?.price === undefined ||
              data?.price <= 0 ||
              data?.discount === undefined
                ? "mb-5"
                : ""
            }`}
          >
            <span className="text-orange-200 me-1 mb-1 flex items-center">
              <Ratings rating={data?.rating} size="0.92rem" />
            </span>
            <span className="text-ornage-200 me-1">
              {" "}
              {data?.ratings?.toFixed(1)}
            </span>
            <span className="fs-6 text-sm text-gray19 font-inter font-normal">
              {" "}
              ({numberWithCommas(data?.ratingby)})
            </span>
          </div>
          <div
            className={`mt-3 ${
              data?.price === undefined ||
              data?.price <= 0 ||
              data?.discount === undefined
                ? "hidden"
                : "flex items-center space-x-2"
            }`}
          >
            <span className="text-black/90 font-semibold font-inter text-base fw-bold">
              ${data?.price - data?.discount}
            </span>{" "}
            <del className="fs-6 text-gray19 font-inter font-normal text-sm">
              ${data?.price}
            </del>
          </div> */}
        </div>
      </Link>
      {/* Card Footer */}
      <div className="border-t dark:border-t-purple4">
        <div className="px-4 py-2 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {data?.instructor_id?.image ? (
              <img
                src={data?.instructor_id?.image}
                className="h-7 w-7 object-cover rounded-full"
                alt="instructor_profile"
              />
            ) : (
              <UserCircleIcon className="h-7 w-7 dark:text-white text-gray20" />
            )}
            <span className="font-urbanist font-medium text-xs sm:text-sm text-blue25 dark:text-white">
              {data?.instructor_id?.name}
            </span>
          </div>
          <div className="col-auto">
            <BookmarkIcon className="h-7 w-7 dark:text-white text-gray20" />
          </div>
        </div>
        {/* <span className={`${showprogressbar ? "" : "d-none"}`}>
        {" "}
        <ProgressBar
          variant="success"
          now={item.progress}
          className="mt-3"
          style={{ height: "5px" }}
        />
      </span> */}
      </div>
    </div>
  );
};

export default CourseCard;
