import { UPDATE_VIEW_MODE } from "./keys";

export const initialState = {
  darkMode: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_VIEW_MODE: {
      return { ...state, darkMode: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
