import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";

const BlogBreadCrum = ({ currentPageTitle }) => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(`${path}`);
  };
  return (
    <div className="flex items-center space-x-1 px-10 py-4">
      <p
        className="text-xs font-medium text-black dark:text-white font-urbanist cursor-pointer"
        onClick={() => handleNavigation("/")}
      >
        Home
      </p>
      <ChevronRightIcon className="h-4 w-4 text-black dark:text-white" />
      <p
        className="text-xs font-medium text-black dark:text-white font-urbanist cursor-pointer"
        onClick={() => handleNavigation("/knowledge-center")}
      >
        Blog
      </p>
      <ChevronRightIcon className="h-4 w-4 text-black dark:text-white" />
      <p
        className="text-xs font-medium font-urbanist w-full max-w-sm truncate whitespace-nowrap"
        style={{
          backgroundImage:
            "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        {currentPageTitle}
      </p>
    </div>
  );
};

export default BlogBreadCrum;
