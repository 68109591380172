import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";

const ExternalCourseDetailsSidebar = ({
  courseData,
  setSectionContent,
  sectionContent,
}) => {
  const [chapterExpanded, setChapterExpanded] = useState(
    courseData?.grades[0]?.chapters?.[0]?._id
  );
  const [headingExpanded, setHeadingExpanded] = useState(
    courseData?.grades[0]?.chapters?.[0]?.headings[0]?._id
  );
  return (
    <div className="bg-white dark:bg-darkBgColor1 rounded-md px-2 py-4 h-full">
      <div className="border-b dark:border-b-purple2 px-4 pb-2 font-urbanist font-semibold dark:text-white text-black/80 text-base mb-5">
        Table of Contents
      </div>
      {courseData?.grades[0]?.chapters?.map((data) => (
        <div
          key={data._id}
          className={`mb-4 ${
            chapterExpanded === data._id
              ? "bg-purple8 dark:bg-purple2 py-3 rounded-2xl"
              : ""
          }`}
        >
          <div
            className={`flex items-center justify-between space-x-2 px-4 cursor-pointer`}
            onClick={() =>
              setChapterExpanded((prev) =>
                prev === data._id ? undefined : data._id
              )
            }
          >
            <h5
              className={`mb-0 font-urbanist ${
                chapterExpanded === data._id ? "font-semibold" : "font-medium"
              } text-base dark:text-white text-black3`}
            >
              {data.name}
            </h5>
            <ChevronDownIcon className="h-4 w-4 text-gray-800" />
          </div>
          {chapterExpanded === data._id ? (
            <div className="px-4 py-2 transition-all ease-in-out duration-300">
              {data?.headings?.length > 0 ? (
                data?.headings?.map((lecture) => (
                  <div key={lecture._id}>
                    <div
                      className="flex items-center space-x-2 cursor-pointer mb-2 w-max"
                      onClick={() =>
                        setHeadingExpanded((prev) =>
                          prev === lecture._id ? undefined : lecture._id
                        )
                      }
                    >
                      <p className="font-urbanist font-normal dark:text-white text-gray22 text-sm">
                        {lecture.name}
                      </p>
                      <ChevronDownIcon className="h-4 w-4 text-gray-800" />
                    </div>
                    {headingExpanded === lecture._id && (
                      <div className="space-y-2">
                        {lecture?.topics?.length > 0 &&
                          lecture.topics.map((topic) => (
                            <div
                              key={topic._id}
                              className="flex items-center space-x-2 cursor-pointer"
                              onClick={() => setSectionContent(topic)}
                            >
                              <div className="w-16">
                                <div className="h-9 w-9 bg-purple9 rounded-full flex items-center justify-center">
                                  <PlayIcon className="h-4 w-4 dark:purple3 text-purple2" />
                                </div>
                              </div>
                              <div className="w-full">
                                <p
                                  className={`font-urbanist ${
                                    sectionContent?.id === topic?.id
                                      ? "font-bold"
                                      : "font-normal"
                                  } dark:text-white text-gray22 text-sm`}
                                >
                                  {topic.name}
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <span className="font-inter font-normal dark:text-white text-gray22 text-sm">
                  No Lecture Available
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default ExternalCourseDetailsSidebar;
