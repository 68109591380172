// import node module libraries
import { Fragment } from "react";
import CustomRadioButton from "../../CustomRadioButton";

const QuizQuestion = (props) => {
  const { item } = props;

  const type = props?.interface === "checkbox" ? "checkbox" : "radio";

  return (
    <Fragment>
      <h3 className="mb-3 mt-1 font-semibold text-lg md:text-xl xl:text-3xl font-inter">
        {item.title}
      </h3>

      <div>
        <div className="border rounded-md mb-4">
          {item?.options?.map((option, index) => {
            return (
              <div
                key={index}
                className={`flex items-center space-x-4 px-4 py-4 ${
                  Number(props?.selectedAnswers?.[item?.id]) === index + 1
                    ? "bg-gray21"
                    : "bg-white"
                } hover:bg-gray21 border-b cursor-pointer ${
                  index === 0
                    ? "hover:rounded-tl hover:rounded-tr"
                    : index + 1 === item.options.length
                    ? "hover:rounded-bl hover:rounded-br"
                    : ""
                }`}
                onClick={() => props?.handleAnswerSelection(item?.id, index)}
              >
                <CustomRadioButton
                  type={type}
                  selected={props?.selectedAnswers?.[item?.id] === index + 1}
                  value={index}
                  handleChange={(value) =>
                    props.handleAnswerSelection(item?.id, value)
                  }
                />
                <p className="text-gray22 font-inter font-normal text-sm">
                  {option}
                </p>
              </div>
            );
          })}
        </div>
        {item?.questionImage && (
          <img
            src={item?.questionImage}
            alt="question_thumbnail"
            className="rounded w-24 h-24 object-contain"
          />
        )}
      </div>
    </Fragment>
  );
};

export default QuizQuestion;
