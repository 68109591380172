// DarkModeToggle.js
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useContext } from "react";
import DarkModeIcon from "../../images/darkModeIcon.png";
import { DarkModeContext } from "../../context/darkModeContext";

const DarkModeToggle = ({ useInDashboard }) => {
  const {
    state: { darkMode },
    updateViewMode,
  } = useContext(DarkModeContext);

  useEffect(() => {
    updateViewMode(getInitialMode());
  }, []);

  useEffect(() => {
    document.body.classList.toggle("dark", darkMode);
    saveMode(darkMode);
  }, [darkMode]);

  function getInitialMode() {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : prefersDarkMode();
  }

  function prefersDarkMode() {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  }

  function saveMode(value) {
    localStorage.setItem("darkMode", JSON.stringify(value));
  }

  const toggleMode = () => {
    updateViewMode(!darkMode);
  };

  const toggleLightMode = () => {
    updateViewMode(false);
  };

  const toggleDarkMode = () => {
    updateViewMode(true);
  };

  return !useInDashboard ? (
    <div
      className={`w-16 py-3 relative rounded-2xl border-2 border-gray-400`}
      style={{
        background:
          darkMode &&
          "linear-gradient(#19002D, #19002D) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
        border: darkMode && "2px solid transparent",
      }}
    >
      <div
        className={`rounded-full w-8 h-8 flex items-center justify-center absolute ${
          darkMode ? "-right-1" : "-left-1"
        } -top-1 cursor-pointer`}
        style={{
          background: darkMode
            ? "#FFFFFF"
            : "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
        }}
        onClick={toggleMode}
      >
        {!darkMode ? (
          <SunIcon className="text-white h-6 w-6" />
        ) : (
          <img
            src={DarkModeIcon}
            alt="dark_mode_icon"
            className="object-contain"
          />
        )}
      </div>
    </div>
  ) : (
    <div className="flex items-center bg-gray34 dark:bg-purple13 rounded-md">
      <div
        className={`flex items-center rounded-l-md px-4 py-2 text-sm font-urbanist font-medium cursor-pointer ${
          !darkMode
            ? "bg-blue31 rounded-md text-white"
            : "bg-inherit text-black2 dark:text-white"
        }`}
        onClick={toggleLightMode}
      >
        <SunIcon
          className={`${
            !darkMode ? "text-white" : "text-black2 dark:text-white"
          } h-6 w-6 mr-2`}
        />
        Light
      </div>
      <div
        className={`flex items-center rouded-r-md px-4 py-2 text-sm font-urbanist font-medium cursor-pointer ${
          darkMode
            ? "bg-blue31 rounded-md text-white"
            : "bg-inherit text-black2 dark:text-white"
        }`}
        onClick={toggleDarkMode}
      >
        <MoonIcon
          className={`${darkMode ? "text-white" : "text-black2"} h-6 w-6 mr-2`}
        />
        Dark
      </div>
    </div>
  );
};

export default DarkModeToggle;
