import { Bars3Icon, UserCircleIcon } from "@heroicons/react/24/outline";
import React, { useContext, useState } from "react";
import { UserContext } from "../../context/user";
import AdminSidebar from "../AdminSidebar";
import { AppDataContext } from "../../context/appData";

const AdminHeader = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const {
    state: { appData },
  } = useContext(AppDataContext);

  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <div
        className={`${
          showSidebar
            ? "fixed bg-gray-700 bottom-0 top-[72px] left-0 overflow-y-auto opacity-100 translate-x-0 z-[20]"
            : "w-0 opacity-0 -translate-x-200"
        } transition-all ease-in-out duration-300 w-1/2 md:w-[20%] lg:w-[15%]`}
      >
        {showSidebar && <AdminSidebar />}{" "}
      </div>
      <div
        className={`flex bg-white shadow-md top-0 sticky items-center justify-between w-full px-4 py-2 lg:py-1`}
      >
        <div className="flex items-center space-x-2">
          <Bars3Icon
            className="h-10 text-black cursor-pointer"
            onClick={() => setShowSidebar((prev) => !prev)}
          />
          {Object.keys(appData).length ? (
            <img
              loading="lazy"
              src={appData?.homePageData?.logoUrl}
              alt="logo"
              className="w-44 h-14 lg:h-16 object-contain"
            />
          ) : (
            <span>Loading...</span>
          )}
          {/* <Search /> */}
        </div>

        {/* right */}
        <div className="flex items-center space-x-2">
          <p className="font-inter font-semibold text-black text-base truncate max-w-xs">
            {userInfo?.name}
          </p>
          {userInfo?.profileImage ? (
            <img
              src={userInfo?.profileImage}
              alt="profile_pic"
              className="h-9 w-9 rounded-full object-cover"
            />
          ) : (
            <UserCircleIcon className="h-9 w-9 text-gray-600" />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
