import React from "react";

const BlogConclusion = ({ blogDetails }) => {
  return (
    <div className="bg-gray37 dark:bg-purple24 px-5 py-4 rounded-md">
      <h5 className="text-black dark:text-white font-urbanist font-semibold text-xl md:text-2xl xl:text-3xl">
        Conclusion
      </h5>
      <p
        className="font-urbanist font-normal text-gray30 dark:text-white text-sm block py-5"
        dangerouslySetInnerHTML={{ __html: blogDetails?.conclusion }}
      />
    </div>
  );
};

export default BlogConclusion;
