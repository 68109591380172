import React, { useContext, useState } from "react";
import ShowQuestion from "../ShowQuestion/ShowQuestion";
import ShowAnswer from "../ShowAnswer/ShowAnswer";
import {
  ArrowPathIcon,
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { saveUserFeedbackAction } from "../../../utils/utils";
import { UserContext } from "../../../context/user";
import ShowFeedbackInputModal from "../ShowFeedbackInputModal/ShowFeedbackInputModal";

const ShowPreviousChats = ({
  histories,
  animate = false,
  handleRegenerate,
}) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [showFeedbackIcons, setShowFeedbackIcons] = useState(null);
  const [copiedText, setCopiedText] = useState(false);
  const [showFeedbackInputModal, setShowFeedbackInputModal] = useState({
    show: false,
    feedbackForChat: null,
  });
  const [feedbackResponseSubmitted, setFeedbackResponseSubmitted] =
    useState(false);
  const [submittingFeedback, setSubmittingFeedback] = useState(false);

  const handleCopyAnswer = (chatToCopy) => {
    saveUserFeedbackAction(
      "copy",
      {
        bot_id: chatToCopy?.[3],
        chat_id: chatToCopy?.[4],
      },
      userInfo?.authToken
    )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    navigator.clipboard.writeText(chatToCopy?.[1]);
    setCopiedText(true);
    setTimeout(() => {
      setCopiedText(false);
    }, 500);
  };

  const handleFeedbackSubmit = (feedback) => {
    if (submittingFeedback) return;
    setSubmittingFeedback(true);
    saveUserFeedbackAction(
      "chat_feedback",
      {
        bot_id: showFeedbackInputModal?.feedbackForChat?.[3],
        chat_id: showFeedbackInputModal?.feedbackForChat?.[4],
        feedback,
      },
      userInfo?.authToken
    )
      .then((res) => {
        setFeedbackResponseSubmitted(true);
        setSubmittingFeedback(true);
      })
      .catch((err) => {
        console.log(err);
        setFeedbackResponseSubmitted(false);
        setSubmittingFeedback(false);
      });
  };

  return (
    <div>
      {histories?.length > 0 &&
        histories?.map((history, index) => {
          return (
            <div
              key={index}
              onMouseEnter={() => setShowFeedbackIcons(index)}
              onMouseLeave={() => setShowFeedbackIcons(null)}
            >
              <ShowQuestion question={history[0]} />

              {history?.[2]?.length > 0 && (
                <div className="flex items-center space-x-2 flex-wrap">
                  {history[2]?.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt="searched_img"
                      className="h-80 w-80 object-contain"
                    />
                  ))}
                </div>
              )}
              <div className="flex items-start space-x-2">
                {/* logo */}
                <img
                  loading="lazy"
                  src="/logo512.png"
                  alt="logo"
                  className="h-9 object-cover -ml-1 md:ml-0 mt-1"
                />

                <ShowAnswer answer={history[1]} animate={animate} />
              </div>

              {/* {showFeedbackIcons === index && ( */}
              <div
                className={`${
                  showFeedbackIcons === index
                    ? "w-full opacity-100"
                    : "w-0 opacity-0"
                } flex items-center space-x-3 my-2`}
              >
                {copiedText ? (
                  <ClipboardDocumentCheckIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <ClipboardIcon
                    className="h-5 w-5 text-gray-500 cursor-pointer"
                    onClick={() => handleCopyAnswer(history)}
                  />
                )}
                {index === histories.length - 1 && (
                  <ArrowPathIcon
                    className="h-5 w-5 text-gray-500 cursor-pointer"
                    onClick={handleRegenerate}
                  />
                )}
                <HandThumbDownIcon
                  onClick={() =>
                    setShowFeedbackInputModal({
                      show: true,
                      feedbackForChat: history,
                    })
                  }
                  className="h-5 w-5 text-gray-500 cursor-pointer"
                />
              </div>
              {/* )} */}
            </div>
          );
        })}

      <ShowFeedbackInputModal
        feedbackResponseSubmitted={feedbackResponseSubmitted}
        handleFeedbackSubmit={handleFeedbackSubmit}
        setShowFeedbackInputModal={setShowFeedbackInputModal}
        showFeedbackInputModal={showFeedbackInputModal}
        submittingFeedback={submittingFeedback}
      />
    </div>
  );
};

export default ShowPreviousChats;
