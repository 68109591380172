import React from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import "./tagsInput.css";

const GKTagsInput = ({ defaulttags, handleChange, name }) => {
  const [tags, setTags] = React.useState(defaulttags);

  const handleTagChange = (newTags) => {
    const event = { target: { name: name, value: newTags } };
    handleChange(event);
    setTags(newTags);
  };

  return <ReactTagInput tags={tags} onChange={handleTagChange} />;
};

export default GKTagsInput;
