import React, { useState } from "react";
import ReactPlayer from "react-player";
import CourseDetailsTabs from "./CourseDetailsTabs/CourseDetailsTabs";
import CourseOverview from "./CourseOverview/CourseOverview";
import CourseNotes from "./CourseNotes/CourseNotes";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const CourseDetailsSectionContent = ({ courseData, sectionContent }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [creatingNote, setCreatingNote] = useState(false);

  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <div className="w-full mb-5 lg:mb-0 lg:basis-3/4 px-5 py-2 lg:border-l lg:border-l-gray-200 lg:dark:border-l-purple2">
      <h2 className="font-semibold font-urbanist dark:text-white text-black/90 text-lg mb-5">
        {sectionContent?.lectureName}
      </h2>

      <div className="w-full h-full px-2 py-2 dark:bg-purple2 bg-gray42 rounded-2xl lg:rounded-none lg:bg-inherit lg:dark:bg-inherit lg:p-0">
        <div className="h-[200px] sm:h-[300px] md:h-[500px]">
          {sectionContent?.lectureContent?.videoLink ? (
            <ReactPlayer
              url={sectionContent?.lectureContent?.videoLink}
              width="100%"
              height="100%"
              controls
              style={{
                marginBottom: "16px",
              }}
            />
          ) : (
            <div className="h-full w-full flex items-center justify-center font-urbanist font-medium text-black dark:text-white text-center">
              No Video avaialable for this lecture
            </div>
          )}
        </div>
        <div className="lg:hidden block">
          <CourseOverview
            courseData={courseData}
            sectionContent={sectionContent}
          />
        </div>
      </div>

      <button
        className={`lg:hidden ${
          creatingNote
            ? "bg-blue-400 dark:bg-purple2 text-white dark:text-blue-400"
            : "bg-purple10 dark:bg-purple2 text-blue-400 dark:text-blue-400"
        } flex items-center space-x-2 rounded-[30px] my-4 px-4 py-2`}
        onClick={() => setCreatingNote((prev) => !prev)}
      >
        <PencilSquareIcon className="h-4 w-4" />
        <p className={`font-urbanist font-semibold text-sm`}>
          {creatingNote ? "Hide Notes" : "Create notes"}
        </p>
      </button>

      <div className="lg:hidden block">{creatingNote && <CourseNotes />}</div>

      <div className="hidden lg:block">
        <CourseDetailsTabs
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      </div>

      <div className="hidden lg:block">
        {selectedTab === 1 ? (
          <CourseOverview
            courseData={courseData}
            sectionContent={sectionContent}
          />
        ) : selectedTab === 2 ? (
          <CourseNotes />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CourseDetailsSectionContent;
