import React from "react";
import SubscriptionToggle from "./SubscriptionToggle/SubscriptionToggle";

const ChooseSubscription = ({
  pageData,
  subscriptionValue,
  setSubscriptionValue,
}) => {
  return (
    <div className="flex flex-col items-center px-4 lg:px-16 xl:px-64 relative z-10">
      <div className="flex flex-col items-center space-y-4">
        <h4 className="text-xs font-urbanist font-bold text-gray28 uppercase tracking-widest">
          {pageData?.chooseSubscriptionSection?.pageTitle}
        </h4>
        <h1 className="dark:text-white text-black2 text-2xl md:text-3xl lg:text-4xl font-urbanist font-semibold">
          {pageData?.chooseSubscriptionSection?.heading}
        </h1>

        <p className="font-inter font-normal text-xs sm:text-sm dark:text-white text-gray5 max-w-xl text-center">
          {pageData?.chooseSubscriptionSection?.subheading}
        </p>

        {/* <SubscriptionSlider
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
        /> */}

        <SubscriptionToggle
          setSubscriptionValue={setSubscriptionValue}
          subscriptionValue={subscriptionValue}
        />
      </div>
    </div>
  );
};

export default ChooseSubscription;
