import GKTagsInput from "../../GKTagsInput";

const Settings = (props) => {
  const { previous } = props;

  return (
    <>
      {/* Card */}
      <div className="w-full mb-3 bg-white shadow-md rounded-md border flex flex-col items-start">
        <div className="border-b px-6 py-3 w-full">
          <h4 className="text-base font-inter font-semibold text-black">
            Basic Information
          </h4>
        </div>
        {/* Card body */}
        <div className="px-6 py-3 flex flex-col space-y-4 w-full">
          <GKTagsInput
            name="tags"
            defaulttags={props?.data?.tags || []}
            handleChange={props.handleChange}
          />
        </div>
      </div>

      <div className="flex justify-between mb-22">
        {props?.previous && (
          <button
            className="bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
            onClick={previous}
            disabled={props?.disableButton}
          >
            Previous
          </button>
        )}

        <button
          className="bg-red-500 disabled:bg-red-500/50 px-4 py-2 text-white text-sm font-inter font-medium rounded-md mx-2 md:mx-0"
          onClick={props.handleSubmit}
          disabled={props?.disableButton}
        >
          {props?.usedForEdit ? "Save Changes" : "Submit For Review"}
        </button>
      </div>
    </>
  );
};
export default Settings;
