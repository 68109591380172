import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import "./BlogDetails.css";
import BlogCard from "../components/BlogPageComponents/BlogCard";
import BlogDetailsCard from "../components/BlogPageComponents/BlogDetailsCard/BlogDetailsCard";
import BlogBreadCrum from "../components/BlogPageComponents/BlogBreadCrum/BlogBreadCrum";

const BlogDetails = () => {
  const params = useParams();
  const [recommendedBlogs, setRecommendedBlogs] = useState([]);
  const [blogDetails, setBlogDetails] = useState({});

  useEffect(() => {
    // fetch recommended blogs
    if (Object.keys(blogDetails).length > 0) {
      axios
        .get(getURLs("fetch-blogs"), {
          params: { page: 1, search: "", categories: blogDetails?.categories },
        })
        .then((res) => {
          // set the recommended blogs
          // Handle response data
          const blogs = res.data.blogs || [];
          const filteredCategoryBlogs = blogs?.filter(
            (blog) => blog?._id !== blogDetails?._id
          );
          // Select any random 4 blogs
          const selectedBlogs = filteredCategoryBlogs
            .sort(() => Math.random() - 0.5)
            .slice(0, 4);

          // Set the recommended blogs
          setRecommendedBlogs(selectedBlogs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [blogDetails]);

  useEffect(() => {
    axios
      .get(getURLs("fetch-blog"), {
        params: { urlSlug: params?.slug },
      })
      .then((res) => {
        setBlogDetails(res.data?.blog);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params?.slug]);

  return (
    <div className="bg-white dark:bg-darkBgColor1">
      <BlogBreadCrum currentPageTitle={blogDetails?.title} />
      {/* Blog Details Section */}
      <BlogDetailsCard blogDetails={blogDetails} />

      {/* Related Articles Section */}
      <div className="py-6 px-4 ">
        <h2 className="text-2xl lg:text-3xl font-urbanist font-bold text-gray-800 dark:text-white mb-4">
          Related Articles
        </h2>
        {recommendedBlogs?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 place-items-center sm:place-items-start gap-4 md:gap-6">
            {recommendedBlogs?.map((blog) => (
              <BlogCard
                key={blog?._id}
                id={blog?._id}
                title={blog?.title}
                description={blog?.description}
                createdAt={blog?.createdAt}
                updatedAt={blog?.updatedAt}
                author={blog?.author}
                categories={blog?.categories}
                recommendedFor={blog?.recommendedFor}
                image={blog?.image}
                authorImage={blog?.authorImage}
                authorName={blog?.authorName}
                readTime={blog?.readTime}
                urlSlug={blog?.urlSlug}
              />
            ))}
          </div>
        ) : (
          <h4 className="font-normal font-urbanist text-start w-full text-base text-black1 dark:text-white">
            More blogs are not available for this category
          </h4>
        )}
      </div>
    </div>
  );
};

export default BlogDetails;
