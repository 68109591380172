import React, { Fragment, useState } from "react";
import ContextAwareToggle from "../ContextAwareToggle";
import AddDescription from "../AddDescription";
import AddLectureContent from "../AddLectureContent";

const CustomAccordianActions = ({
  setCourseCurriculum,
  id,
  accordionItems,
  courseCurriculum,
}) => {
  const [showdescriptionModal, setShowDescriptionModal] = useState(false);
  const [descriptionEditObj, setDescriptionEditObj] = useState({});

  // article modal state
  const [showArticleModal, setShowArticleModal] = useState(false);

  const [expanded, setExpaned] = useState(undefined);

  const generateKey = (pre) => {
    pre = pre.toLowerCase();
    pre = pre.replace(" ", "_");
    return `${pre}_${new Date().getTime()}`;
  };

  return (
    <Fragment>
      <div>
        {accordionItems?.map((item, index) => (
          <div
            key={generateKey(item.lectureName + index)}
            className="px-2 py-2 mb-1 shadow-none bg-white mt-2"
          >
            <div className="bg-transparent border-0 p-0">
              <div className="border-0">
                <h3 className="mb-0 font-bold text-black/70 font-inter text-sm">
                  <ContextAwareToggle
                    eventKey={item.id + index}
                    lectureId={item.id}
                    currentLectureName={item.lectureName}
                    id={id}
                    setCourseCurriculum={setCourseCurriculum}
                    courseCurriculum={courseCurriculum}
                    setExpanded={setExpaned}
                  >
                    {item.lectureName}
                  </ContextAwareToggle>
                </h3>
              </div>
            </div>
            {item.id === expanded && (
              <div>
                <div className="p-4 flex flex-col space-y-2">
                  <p className="text-sm text-black font-inter font-normal">
                    Description: {item?.description}
                  </p>
                  <p className="text-sm text-black font-inter font-normal">
                    Lecture Content Title: {item?.lectureContent?.lessonTitle}
                  </p>
                  <div className="flex items-center space-x-2">
                    <button
                      className="bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md mb-1 w-max"
                      onClick={() => {
                        setShowDescriptionModal(true);
                        setDescriptionEditObj(item);
                      }}
                    >
                      Add Description +
                    </button>
                    <button
                      className="bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md mb-1 w-max"
                      onClick={() => {
                        setShowArticleModal(true);
                        setDescriptionEditObj(item);
                      }}
                    >
                      Add Lecture Content +
                    </button>
                  </div>
                </div>
              </div>
            )}
            <AddDescription
              currentDescriptionObj={descriptionEditObj}
              setDescriptionEditObj={setDescriptionEditObj}
              id={id}
              setCourseCurriculum={setCourseCurriculum}
              setShowDescriptionModal={setShowDescriptionModal}
              showdescriptionModal={showdescriptionModal}
            />
            <AddLectureContent
              currentObj={descriptionEditObj}
              setCurrentObj={setDescriptionEditObj}
              id={id}
              setCourseCurriculum={setCourseCurriculum}
              setShowArticleModal={setShowArticleModal}
              showArticleModal={showArticleModal}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default CustomAccordianActions;
