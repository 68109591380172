import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { UserContext } from "../context/user";
import { Helmet } from "react-helmet-async";
import SocialLogin from "../components/SocialLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { AppDataContext } from "../context/appData";
import AuthImageWithHeading from "../components/AuthImageWithHeading";

const Login = () => {
  // app info
  const {
    state: { appData },
  } = useContext(AppDataContext);
  // update user func. from context
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);
  // navigate state for navigation
  const navigate = useNavigate();

  // form data
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // error state
  const [error, setError] = useState("");
  // social login error state
  const [socialLoginError, setSocialLoginError] = useState("");

  // login loading state
  const [loginLoading, setLoginLoading] = useState(false);

  // click handler to go to signup page
  const handleSignupNavigation = () => {
    navigate("/signup", { replace: true });
  };

  // function to handle input changes
  const handleInputChange = (name, e) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  // submit for handler func.
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!formData?.email) {
      return setError("Email cannot be empty");
    }
    if (!formData?.password) {
      return setError("Password cannot be empty");
    }
    setLoginLoading(true);
    axios
      .post(
        getURLs("loginUrl"),
        {
          email: formData.email,
          password: formData.password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.authToken) {
          const axiosReq = axios.create({
            headers: {
              "auth-token": res?.data?.authToken,
            },
          });
          axiosReq
            .post(getURLs("getUserDetails"))
            .then((response) => {
              if (response?.data?.user) {
                updateUser({
                  ...response?.data?.user,
                  authToken: res?.data?.authToken,
                });
                localStorage.setItem("persist", true);
              }
            })
            .catch((err) => {
              if (
                err?.response?.status === 400 ||
                err?.response?.status === 401 ||
                err?.response?.status === 500
              )
                setError(err?.response?.data?.message);

              if (err) console.log(err);
            });
          navigate("/dashboard");
          setLoginLoading(false);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 ||
          err?.response?.status === 401 ||
          err?.response?.status === 500
        )
          setError(err?.response?.data?.message);
        console.log(err);
        setLoginLoading(false);
      });
  };

  useEffect(() => {
    setError("");
  }, [formData]);

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      navigate("/dashboard");
    }
  }, [userInfo]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Helmet>
        <title>Vaisage - Signin</title>
        <link rel="canonical" href="https://www.vaisage.com/login" />
      </Helmet>
      <div
        className="flex flex-col lg:flex-row items-center justify-center lg:items-start px-4 pt-5 pb-10 space-x-0 lg:space-x-4 bg-[#f8f9fa] lg:bg-white dark:bg-darkBgColor1"
        // style={{ backgroundImage: `url(${SideBG})` }}
      >
        <AuthImageWithHeading
          imageSrc={appData?.loginPageData?.sectionImage}
          heading={appData?.loginPageData?.heading}
        />

        <div className="lg:basis-1/2 w-full flex flex-col items-center relative mt-4 lg:mt-0">
          <form
            className="z-10 flex flex-col justify-center border dark:border-none dark:shadow-none bg-white dark:bg-purple2 shadow-shadow13 rounded-[30px] space-y-5 w-full max-w-md px-6 py-8"
            onSubmit={handleFormSubmit}
          >
            {/* social login buttons */}
            <SocialLogin setSocialLoginError={setSocialLoginError} />

            <div className="flex items-center justify-between space-x-4">
              <div className="w-1/4 border-b border-b-gray9" />
              <p className="font-urbanist font-normal opacity-70 text-gray6 dark:text-gray39 text-xs flex-grow-1 text-center">
                or Sign in with Email
              </p>
              <div className="w-1/4 border-b border-b-gray9" />
            </div>

            <div className="flex flex-col items-start">
              <p className="font-urbanist font-normal text-xs dark:text-white text-blue25">
                Email Address
              </p>
              {/*  email input */}
              <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border border-gray-200 dark:border-purple12 rounded-lg w-full px-2 mt-2 mb-8">
                <UserIcon className="h-4 w-4 dark:text-white" />
                <input
                  className="flex-grow outline-none border-none px-2 py-2 text-sm lg:text-base placeholder:text-xs font-urbanist font-normal text-gray-600 dark:text-white bg-transparent"
                  type="email"
                  value={formData.email}
                  onChange={(e) => handleInputChange("email", e)}
                />
              </div>

              <p className="font-urbanist font-normal text-xs dark:text-white text-blue25">
                Password
              </p>
              {/* password input */}
              <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-4">
                <LockClosedIcon className="h-4 w-4 dark:text-white" />
                <input
                  className="flex-grow outline-none border-none px-2 py-2 text-sm font-urbanist font-normal text-gray-600 dark:text-white bg-transparent"
                  type="password"
                  value={formData.password}
                  onChange={(e) => handleInputChange("password", e)}
                />
              </div>

              <div className="flex items-center justify-between w-full">
                <div className="flex items-center space-x-2">
                  <input type="checkbox" />
                  <span className="font-urbanist font-normal text-xs dark:text-white text-blue26">
                    Remember me
                  </span>
                </div>

                <span
                  className="font-urbanist font-normal text-xs text-blue27 cursor-pointer"
                  onClick={() => navigate("/forget-password")}
                >
                  Forgot your password?
                </span>
              </div>

              {error?.length > 0 && (
                <span className="text-xs sm:text-sm font-urbanist text-red-500 font-medium mt-2 block">
                  {error}
                </span>
              )}

              {socialLoginError?.length > 0 && (
                <span className="text-xs sm:text-sm font-urbanist text-red-500 font-medium mt-2 block">
                  {socialLoginError}
                </span>
              )}
              <div className="w-full flex items-center justify-center mt-5">
                <button
                  className="w-full px-2 py-2 mt-4 max-w-xl font-urbanist font-bold text-white text-xs md:text-base xl:text-lg rounded-lg"
                  onClick={handleFormSubmit}
                  style={{
                    background: !loginLoading
                      ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                      : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                  }}
                  disabled={loginLoading}
                >
                  Log in
                </button>
              </div>
            </div>

            <p className="font-urbanist font-normal text-[10px] text-gray10">
              not registered yet?{" "}
              <span
                className="text-blue27 underline underline-offset-2 cursor-pointer"
                onClick={handleSignupNavigation}
              >
                Create an acccount
              </span>{" "}
            </p>
          </form>
          {/* <div className="absolute top-24 bottom-0 bg-purple11 blur-[120px] w-full h-full dark:bg-neonPurpleColor dark:opacity-60" /> */}
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
