import { Fragment, useEffect, useState } from "react";
import CustomAccordianActions from "../../CustomAccordianActions";
import { generateUniqueId } from "../../../utils/utils";
import CustomModal from "../../CustomModal/CustomModal";
import { TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";

const AddLecture = ({ setCourseCurriculum, id }) => {
  const [show, setShow] = useState(false);
  const [lectureName, setLectureName] = useState("");

  const addLecture = (e) => {
    e.preventDefault();

    setCourseCurriculum((prev) =>
      prev?.map((item, index) => {
        if (index === id) {
          return {
            ...item,
            lectures: [
              ...item?.lectures,
              { lectureName: lectureName, id: generateUniqueId() },
            ],
          };
        } else {
          return item;
        }
      })
    );
    setLectureName("");
    handleClose();
  };

  const handleClose = () => {
    setLectureName("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <button
        className="bg-white border border-purple1 px-4 py-2 text-purple1 text-sm font-inter font-medium rounded-md w-max hover:text-white hover:bg-purple1 transition-all ease-in-out duration-200 mt-3"
        onClick={handleShow}
      >
        Add Lecture +
      </button>
      <CustomModal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {show && (
          <>
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Add New Lecture
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Add new lecture"
                  value={lectureName}
                  onChange={(e) => setLectureName(e.target.value)}
                  className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full"
                />
              </div>
            </div>
            <div className="w-full flex items-center space-x-4 px-6 pb-5">
              <button
                className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                onClick={addLecture}
              >
                Add New Lecture
              </button>
              <button
                className="bg-white px-4 py-2 text-black/70 text-sm font-inter font-medium rounded-md border-gray22 border"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </>
        )}
      </CustomModal>
    </Fragment>
  );
};

const AddSection = ({ setCourseCurriculum }) => {
  const [show, setShow] = useState(false);
  const [sectionName, setSectionName] = useState();

  const addSection = (e) => {
    e.preventDefault();
    const sectionToBeAdded = {
      id: generateUniqueId(),
      sectionName: sectionName,
      lectures: [],
    };
    setCourseCurriculum((prev) => [...prev, sectionToBeAdded]);
    setSectionName("");
    handleClose();
  };

  const handleClose = () => {
    setSectionName("");
    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  return (
    <Fragment>
      <button
        className="bg-white border border-purple1 px-4 py-2 text-purple1 text-sm font-inter font-medium rounded-md w-max hover:text-white hover:bg-purple1 transition-all ease-in-out duration-200"
        onClick={handleShow}
      >
        Add Section
      </button>
      <CustomModal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {show && (
          <>
            {/* modal header */}
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Add New Section
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            {/* modal body */}
            <div className="w-full flex flex-col px-6 py-5">
              <div className="pb-0">
                <div className="mb-3">
                  <input
                    type="text"
                    placeholder="Add new section"
                    value={sectionName}
                    onChange={(e) => setSectionName(e.target.value)}
                    className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                  onClick={addSection}
                >
                  Add New Section
                </button>
                <button
                  className="bg-white px-4 py-2 text-black/70 text-sm font-inter font-medium rounded-md border-gray22 border"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </>
        )}
      </CustomModal>
    </Fragment>
  );
};

const Curriculum = (props) => {
  const { next, previous, usedForEdit, handleChange } = props;
  const [courseCurriculum, setCourseCurriculum] = useState(
    props?.data?.curriculum ? props?.data?.curriculum : []
  );

  const handleNextClick = () => {
    const event = { target: { name: "curriculum", value: courseCurriculum } };
    handleChange(event);
    next();
  };

  const deleteSection = (idToDelete) => {
    setCourseCurriculum((prev) =>
      prev?.filter((item) => item.id !== idToDelete)
    );
  };

  useEffect(() => {
    if (usedForEdit) {
      const event = { target: { name: "curriculum", value: courseCurriculum } };
      handleChange(event);
    }
  }, [courseCurriculum, usedForEdit]);

  return (
    <>
      {/* Card */}
      <div className="w-full mb-3 bg-white shadow-md rounded-md border flex flex-col items-start">
        <div className="border-b px-6 py-3 w-full">
          <h4 className="text-base font-inter font-semibold text-black">
            Curriculum
          </h4>
        </div>
        {/* Card body */}
        <div className="px-6 py-3 flex flex-col space-y-4 w-full">
          {courseCurriculum?.map((curriculum, index) => (
            <div className="bg-gray21 rounded p-2 mb-4" key={index}>
              <div className="flex items-center space-x-4 justify-between">
                <h4>{curriculum?.sectionName}</h4>
                <TrashIcon
                  className="h-4 w-4 md:h-5 md:w-5 text-red-500 cursor-pointer"
                  onClick={() => deleteSection(curriculum.id)}
                />
              </div>
              {/* Item list */}
              <CustomAccordianActions
                setCourseCurriculum={setCourseCurriculum}
                id={curriculum?.id}
                accordionItems={curriculum?.lectures}
                courseCurriculum={courseCurriculum}
              />
              <AddLecture
                setCourseCurriculum={setCourseCurriculum}
                id={index}
              />
            </div>
          ))}
          <AddSection setCourseCurriculum={setCourseCurriculum} />
        </div>
      </div>
      {/* Button */}
      {props?.next && props?.previous && (
        <div className="flex justify-between">
          <button
            className="bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
            onClick={previous}
          >
            Previous
          </button>
          <button
            className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
            onClick={handleNextClick}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};
export default Curriculum;
