import { useContext, useState } from "react";
import { InstructorDataContext } from "../../../context/instructorData";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import ImageUploadingModal from "../../ImageUploadingModal";
import AddNewQuizQuestion from "../AddNewQuizQuestion/AddNewQuizQuestion";
import CustomModal from "../../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import FormSelect from "../../FormSelect";

const AddNewQuiz = (props) => {
  // getting quiz context
  const { addQuiz } = useContext(InstructorDataContext);
  const [quizTitle, setQuizTitle] = useState("");
  const [quizImage, setQuizImage] = useState("");
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [timeToComplete, setTimeToComplete] = useState(5);

  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  // image url state
  const [securedUrl, setSecuredUrl] = useState("");
  const [savedImageUrl, setSavedImageUrl] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadingError, setImageUploadingError] = useState({
    show: false,
    message: "",
  });

  // disabled state
  const [quizAdding, setQuizAdding] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setQuizImage(e.target.files[0]);
      axios
        .post(
          getURLs("image-upload-url"),
          { imageFor: "quizQuestionImages" },
          {
            headers: { "auth-token": props?.userInfo?.authToken },
            withCredentials: true,
          }
        )
        .then((res) => {
          setSecuredUrl(res?.data?.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUpload = async () => {
    if (quizImage) {
      setImageUploading(true);
      // have to do it fetch for s3.
      fetch(securedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: quizImage,
      })
        .then((res) => {
          const image = securedUrl?.split("?")[0];
          setSavedImageUrl(image);
          setImageUploading(false);
          setImageUploadingError({ show: false, message: "" });
        })
        .catch((err) => {
          console.log(err);
          setImageUploading(false);
          setImageUploadingError({
            show: true,
            message: err?.message || "Failed to upload Image",
          });
        });
    } else {
      setImageUploadingError({
        show: true,
        message: "Please select an image to upload",
      });
    }
  };

  const addQuestionHandler = (question) => {
    setQuizQuestions((prev) => [...prev, question]);
  };

  const addIntructorQuiz = (e) => {
    // e.preventDefault();
    setQuizAdding(true);
    const quizDataObj = {
      title: quizTitle,
      image: savedImageUrl,
      questions: quizQuestions,
      answerSelectionType: "single",
      interface: "radio",
      duration: timeToComplete,
    };
    axios
      .post(
        getURLs("add-quiz"),
        { quizData: quizDataObj },
        {
          headers: {
            "auth-token": props?.userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setQuizTitle("");
          setQuizQuestions([]);
          setQuizImage("");
          setTimeToComplete("");
          setSavedImageUrl("");
          setImageUploading(false);
          addQuiz(res?.data?.quizAdded);
          setShowAddQuestionModal(false);
          setQuizAdding(false);
          props.openQuizAddedModal(true);
          props.onHide();
        }
      })
      .catch((err) => {
        console.log(err);
        setQuizAdding(false);
      });
  };

  const closeModal = () => {
    setQuizTitle("");
    setQuizQuestions([]);
    setQuizImage(null);
    setShowAddQuestionModal(false);
    setSavedImageUrl("");
    setImageUploading(false);
    setQuizAdding(false);
    setTimeToComplete("5");
    props.onHide();
  };

  const quizTimings = [
    { value: 5, label: "5 Minutes" },
    { value: 10, label: "10 Minutes" },
    { value: 15, label: "15 Minutes" },
    { value: 30, label: "30 Minutes" },
    { value: 45, label: "45 Minutes" },
    { value: 60, label: "1 Hour" },
    { value: 75, label: "1 Hour 15 Minutes" },
    { value: 90, label: "1 Hour 30 Minutes" },
    { value: 120, label: "2 Hours" },
  ];

  return (
    <>
      <CustomModal
        show={props.showQuizModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {props.showQuizModal && (
          <div className="flex flex-col space-y-1 overflow-y-auto w-full">
            {/* header */}
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Add Quiz
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={closeModal}
              />
            </div>

            {/* body */}
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <form>
                <div className="mb-5">
                  {/* quiz title */}
                  <div className="mb-3 flex flex-col space-y-1">
                    <label
                      htmlFor="quiz-title"
                      className="font-medium font-inter text-black/90 text-sm"
                    >
                      Quiz Title
                    </label>
                    <input
                      placeholder="Quiz title"
                      id="quiz-title"
                      value={quizTitle}
                      onChange={(e) => setQuizTitle(e.target.value)}
                      className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18"
                    />
                  </div>
                  {/* quiz image */}
                  <label className="font-medium font-inter text-black/90 text-sm mb-1 block">
                    Quiz cover image
                  </label>
                  <div className="mb-3 flex flex-col space-y-2">
                    <div className="flex items-center justify-between">
                      <input
                        id="inputfavicon"
                        type="file"
                        className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-normal placeholder:text-gray20 text-gray18 flex-1 "
                        onChange={handleFileChange}
                      />
                      <label
                        className="cursor-pointer border border-gray19 outline-none rounded-tr-md rounded-br-md text-base text-gray20 px-4 py-2.5"
                        onClick={handleUpload}
                      >
                        Upload
                      </label>
                    </div>
                    <p className="text-xs text-gray22 font-inter font-normal">
                      Upload your quiz image here. It must meet our quiz image
                      quality standards to be accepted. Important guidelines:
                      750x440 pixels; .jpg, .jpeg,. gif, or .png. no text on the
                      image.
                      <br />
                      <br />
                      Please click the upload button to upload
                    </p>
                    <p>
                      {imageUploadingError?.show && (
                        <span className="block mt-2 text-sm md:text-base font-inter font-medium text-red-500">
                          {imageUploadingError?.message}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="mb-3 flex flex-col space-y-2">
                    <label
                      className="font-medium font-inter text-black/90 text-sm"
                      htmlFor="timeToComplete"
                    >
                      Time to complete the quiz
                    </label>
                    {console.log(timeToComplete)}
                    <div className="w-full px-4 py-2 border border-gray19 rounded-md">
                      <FormSelect
                        id="timeToComplete"
                        options={quizTimings}
                        className="text-sm font-inter font-normal placeholder:text-gray20 text-gray18 w-full outline-none"
                        defaultselected={timeToComplete}
                        onChange={(e) => setTimeToComplete(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* quizQuestions */}
                  {quizQuestions?.map((question, index) => (
                    <h4 className="mb-0" key={index}>
                      Q.{index + 1} {question?.title}
                    </h4>
                  ))}

                  {/* add quiz question */}
                  <div className="mt-5">
                    <button
                      className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowAddQuestionModal(true);
                      }}
                    >
                      Add a Question
                    </button>
                  </div>
                </div>

                {savedImageUrl && (
                  <img
                    src={savedImageUrl}
                    alt="courseImage"
                    className="w-full h-28 object-contain"
                  />
                )}
              </form>
            </div>

            <div className="flex items-center justify-end space-x-4 pb-5 w-full px-4">
              <button
                className="bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md disabled:bg-purple1/50"
                onClick={addIntructorQuiz}
                disabled={quizAdding}
              >
                Add Quiz
              </button>
            </div>
          </div>
        )}
      </CustomModal>

      <AddNewQuizQuestion
        userInfo={props?.userInfo}
        addQuestionHandler={addQuestionHandler}
        show={showAddQuestionModal}
        onHide={() => setShowAddQuestionModal(false)}
      />

      {/* image uploading modal */}
      <ImageUploadingModal
        modalOpen={imageUploading}
        title={`Image Uploading`}
        message={`Please wait while image is getting uploaded...`}
      />
    </>
  );
};

export default AddNewQuiz;
