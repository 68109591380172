import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Outlet, useLocation } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import ReactGA from "react-ga4";

const HomeLayout = () => {
  const router = useLocation();
  const showCommonHeaderAndFooter =
    router.pathname !== "/dashboard" &&
    router.pathname !== "/checkout" &&
    router.pathname !== "/terms-of-use" &&
    router.pathname !== "/privacy-policy" &&
    router.pathname !== "/copyright-policy" &&
    router.pathname !== "/cookie-policy" &&
    router.pathname !== "/disclosure-agreement" &&
    router.pathname !== "/payment-confirmation";
  const isAdmin = router.pathname.includes("/admin");

  useEffect(() => {
    // Track initial page view
    ReactGA.send({
      hitType: "pageview",
      page: window?.location?.pathname + window?.location?.search,
      // title: `${
      //   window?.location?.pathname === "/"
      //     ? "Home"
      //     : window?.location?.pathname.slice(1)
      // } Page`,
    });
  }, []); // Run this effect only once, similar to componentDidMount

  useEffect(() => {
    // Track page view on route change
    ReactGA.send({
      hitType: "pageview",
      page: window?.location?.pathname + window?.location?.search,
      // title: `${
      //   window?.location?.pathname === "/"
      //     ? "Home"
      //     : window?.location?.pathname.slice(1)
      // } Page`,
    });
  }, [router.pathname]);

  return (
    <>
      {isAdmin ? <AdminHeader /> : showCommonHeaderAndFooter && <Header />}
      <Outlet />
      {showCommonHeaderAndFooter && <Footer />}
    </>
  );
};

export default HomeLayout;
