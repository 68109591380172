import React, { useEffect } from "react";
import CircularProgress from "../components/CircularProgress";
import { Link, useLocation, useNavigate } from "react-router-dom";

const QuizResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const quizResult = location.state;

  useEffect(() => {
    if (!quizResult) {
      navigate("/dashboard", { replace: true });
    }

    return () => {
      location.state = undefined;
    };
  }, [quizResult]);

  return (
    <div className="bg-white rounded-md px-4 py-2">
      <div className="p-10 text-center">
        <div className="mb-4">
          <h2 className="text-lg sm:text-2xl lg:text-3xl font-inter font-semibold text-black/90 mb-3">
            {quizResult?.scorePercentage >= 50
              ? "🎉 Congratulations. You passed!"
              : "😔 Sorry, You have failed the test."}
          </h2>
          <p className="mb-0 lg:px-14 font-inter font-normal text-gray20 text-sm md:text-base lg:text-lg">
            You are successfully completed the quiz. Now you click on finish and
            back to your quiz page.
          </p>
        </div>
        <div className="flex justify-center">
          <CircularProgress percent={quizResult?.scorePercentage} />
        </div>
        <div className="mt-3 font-inter font-normal text-gray20 text-sm">
          <span>
            Your Score:{" "}
            <span className="font-semibold text-black/80">
              {quizResult?.scorePercentage}% <br />
              {quizResult?.rawScore} correct out of {quizResult?.totalQuestions}
            </span>
          </span>
          <br />
          <span className="mt-2 block">
            Passing Score:{" "}
            <span className="font-semibold text-black/80">50%</span>
          </span>
        </div>
        <div className="mt-5">
          <Link
            to="/dashboard"
            className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
          >
            Finish
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
