// import node module libraries
import { useState } from "react";
import ReactQuill from "react-quill";

const ReactQuillEditor = (props) => {
  const [state, setState] = useState(props.initialValue);
  function handleChange(value) {
    const event = { target: { name: props?.name, value: value } };
    setState(value);
    props.onChange(event);
  }
  return (
    <ReactQuill
      style={{ ...props.style }}
      value={state}
      onChange={handleChange}
    />
  );
};

export default ReactQuillEditor;
