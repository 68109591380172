import React, { useContext } from "react";
import { DarkModeContext } from "../../../../context/darkModeContext";

const BlogCategoryFilterTab = ({
  category,
  selectedCategory,
  handleCategorySelection,
}) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  return (
    <div
      key={category?.name}
      className={`px-6 py-2 rounded-md  ${
        selectedCategory === category?.name?.toLowerCase()
          ? "bg-white shadow-lg"
          : ""
      } cursor-pointer `}
      style={{
        background:
          darkMode &&
          selectedCategory === category?.name?.toLowerCase() &&
          "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
      }}
      onClick={() => handleCategorySelection(category?.name)}
    >
      <span
        className="text-gray-700 dark:text-white font-urbanist font-medium text-sm sm:text-sm text-center"
        style={{
          backgroundImage:
            selectedCategory === category?.name?.toLowerCase() &&
            !darkMode &&
            "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          WebkitBackgroundClip: "text",
          color:
            selectedCategory === category?.name?.toLowerCase() &&
            !darkMode &&
            "transparent",
        }}
      >
        {category?.name}
      </span>
    </div>
  );
};

export default BlogCategoryFilterTab;
