import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import CourseDetailsSidebar from "../components/CourseDetailsSidebar";
import CourseDetailsSectionContent from "../components/CourseDetailsSectionContent";

const CourseDetails = () => {
  const location = useLocation();
  const courseData = location.state;
  const [expanded, setExpanded] = useState(courseData?.curriculum?.[0]?.id);
  const [sectionContent, setSectionContent] = useState(
    courseData?.curriculum?.[0]?.lectures?.[0]
  );

  return (
    <div className="bg-gray21 dark:bg-darkBgColor1 px-4 py-4">
      <div className="bg-white dark:bg-darkBgColor1 py-2 flex flex-col-reverse lg:flex-row items-start border border-gray-100 dark:border-purple2 rounded-3xl">
        {/* course content table */}
        <div className="lg:basis-1/4 w-full px-2">
          <CourseDetailsSidebar
            expanded={expanded}
            setExpanded={setExpanded}
            courseData={courseData}
            setSectionContent={setSectionContent}
          />
        </div>

        {/* selected tab content */}
        <CourseDetailsSectionContent
          courseData={courseData}
          sectionContent={sectionContent}
        />
      </div>
    </div>
  );
};

export default CourseDetails;
