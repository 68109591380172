import React, { useContext } from "react";
import Advantages from "../components/Adavantages";
import Features from "../components/Features";
import { AppDataContext } from "../context/appData";

const Approach = () => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);

  return (
    <div className="bg-background1">
      <section className="mb-8 xl:mb-32">
        <Advantages appData={appData} />
      </section>

      <section className="pb-8 xl:pb-32">
        <Features appData={appData} />
      </section>
    </div>
  );
};

export default Approach;
