import React, { useContext } from "react";
import RenderIcons from "./RenderIcons/RenderIcons";
import { DarkModeContext } from "../../context/darkModeContext";
import { AppDataContext } from "../../context/appData";

const SocialMediaIcons = () => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const {
    state: { appData },
  } = useContext(AppDataContext);

  const iconsData = appData?.homePageData?.footerSectionData?.iconsData;

  const handleNavigation = (path) => {
    window.location.href = path;
  };

  return (
    <div className="flex items-center space-x-2">
      {/* <!-- Facebook --> */}
      {iconsData?.map((icon) => (
        <div
          key={icon?.iconName}
          className="px-2 py-2 rounded-lg dark:bg-white bg-blue40 flex items-center justify-center"
          onClick={() => handleNavigation(icon?.goToLink)}
        >
          <RenderIcons
            iconName={icon?.iconName}
            iconColor={darkMode ? "#7C51FF" : "#0099FF"}
          />
        </div>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
