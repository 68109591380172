import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";

const OverviewDetails = ({
  courseData,
  containerRef,
  textStyle,
  sectionContent,
  isExpandable,
  expanded,
  toggleExpand,
}) => {
  return (
    <div>
      {/* dvivider */}
      <div className="w-full border-b border-b-gray-300 my-3" />

      <h3 className="font-urbanist font-semibold text-base dark:text-white text-black block mb-5">
        Course
      </h3>
      <div className="flex flex-col md:flex-row space-x-0 space-y-4 items-start md:space-y-0 md:space-x-4">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <h5 className="font-urbanist text-sm font-bold text-black dark:text-white">
              Skill level
            </h5>
            <div
              className={`${
                courseData?.level?.toLowerCase() === "advance"
                  ? "bg-blue41 text-blue27"
                  : courseData?.level?.toLowerCase() === "medium"
                  ? "bg-yellow2 text-yellow3"
                  : courseData?.level?.toLowerCase() === "beginner"
                  ? "bg-green2 text-green3"
                  : ""
              } px-4 py-1 rounded-[30px] font-urbanist font-medium text-xs uppercase`}
            >
              {courseData?.level}
            </div>
          </div>
          <p className="font-urbanist font-medium text-gray40 text-sm">
            {courseData?.studentsEnrolled || 0} students enrolled
          </p>
        </div>

        <div className="flex flex-col space-y-2">
          <h5 className="font-urbanist text-sm font-bold text-black dark:text-white">
            Certificate
          </h5>
          <div className="px-4 py-1 rounded-[30px] font-urbanist font-medium text-gray39 bg-gray41">
            Preview Certificate
          </div>
        </div>
      </div>
      {/* divider */}
      <div className="w-full border-b border-b-gray-300 my-3" />

      <h3 className="font-urbanist font-semibold text-base dark:text-white text-black block mb-5">
        Descripiton
      </h3>
      <div className="flex flex-col space-y-3">
        <div
          style={textStyle}
          ref={containerRef}
          className="font-urbanist font-normal dark:text-white text-black text-sm"
        >
          {sectionContent?.description}
        </div>
        {isExpandable && (
          <button
            onClick={toggleExpand}
            className="text-blue27 font-urbanist font-semibold text-sm flex items-center space-x-1 cursor-pointer w-max self-center"
          >
            <span>{expanded ? "Show less" : "Show more"}</span>
            {expanded ? (
              <ChevronUpIcon className="text-blue27 h-4 w-4 mt-0.5" />
            ) : (
              <ChevronDownIcon className="text-blue27 h-4 w-4 mt-0.5" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default OverviewDetails;
