import React from "react";
import { ChevronRightIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { profileSectionTabs } from "../../../utils/staticData";

const ProfileMenuOption = ({ userInfo, selectedOption, handleOptionClick }) => {
  return (
    <div className="bg-white md:bg-gray43 rounded-2xl border dark:border-purple12 w-full h-full py-6 dark:bg-purple16">
      {/* photo */}
      <div className="w-full flex flex-col items-center">
        {userInfo?.profileImage ? (
          <img
            src={userInfo?.profileImage}
            alt="profile"
            className="h-24 object-contain rounded-full"
          />
        ) : (
          <UserCircleIcon className="dark:text-white text-gray20 h-24 object-contain rounded-full" />
        )}

        <h4 className="font-semibold font-urbanist text-sm text-black dark:text-white">
          {userInfo?.name}
        </h4>

        {/* options */}
        <div className="flex flex-col space-y-4 w-full px-4 py-5">
          {profileSectionTabs.map((tab) => (
            <div
              key={tab.id}
              className={`flex items-center justify-between px-4 py-2 border-b ${
                selectedOption.selectedValue === tab.selectedValue
                  ? "bg-blue42 dark:bg-purple14 rounded-[52px] text-blue43 border-b-[#F1F1F1] dark:border-b-purple14"
                  : "bg-inherit dark:text-white text-black border-b-gray-200 dark:border-b-purple14 "
              } font-urbanist font-semibold text-sm cursor-pointer`}
              onClick={() => handleOptionClick(tab)}
            >
              <span>{tab.text}</span>
              <ChevronRightIcon className="flex md:hidden h-5 w-5" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileMenuOption;
