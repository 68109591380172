import React from "react";
import CustomModal from "../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";

// {/* data send to server successfully modal */}
const SubmitSuccessModal = ({ showModal, closeModal, message }) => {
  return (
    <CustomModal
      show={showModal}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      {showModal && (
        <>
          <div className="flex items-center justify-between border-b w-full py-4">
            <h4 className="text-base font-inter font-medium text-black pl-5">
              Success
            </h4>
            <XCircleIcon
              className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <div className="w-full flex flex-col px-6 pt-5 pb-2">
            <p>{message}</p>
          </div>
          <div className="w-full flex items-center space-x-4 px-6 pb-5">
            <button
              className="bg-white px-4 py-2 text-black/70 text-sm font-inter font-medium rounded-md border-gray22 border"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default SubmitSuccessModal;
