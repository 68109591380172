// import node module libraries
import { useEffect, useState } from "react";
import axios from "axios";
import ImageUploadingModal from "../../ImageUploadingModal";
import { getURLs } from "../../../urlConfig";

const CoursesMedia = (props) => {
  const { next, previous } = props;
  const [file, setFile] = useState(null);

  // image url states
  const [url, setUrl] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadingError, setImageUploadingError] = useState({
    show: false,
    message: "",
  });

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      setFile(e.target.files[0]);
      axios
        .post(
          getURLs("image-upload-url"),
          { imageFor: "courseImages" },
          {
            headers: { "auth-token": props?.userInfo?.authToken },
            withCredentials: true,
          }
        )
        .then((res) => {
          setUrl(res?.data?.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUpload = async () => {
    if (file) {
      // have to do it fetch for s3.
      setImageUploading(true);
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file,
      })
        .then((res) => {
          const image = url?.split("?")[0];
          setImageUrl(image);
          props.handleChange({ target: { name: "image", value: image } });
          setImageUploading(false);
          setImageUploadingError({ show: false, message: "" });
        })
        .catch((err) => {
          console.log(err);
          setImageUploading(false);
          setImageUploadingError({
            show: true,
            message: err?.message || "Failed to upload Image",
          });
        });
    } else {
      setImageUploadingError({
        show: true,
        message: "Please select an image to upload",
      });
    }
  };

  useEffect(() => {
    if (props?.usedForEdit) {
      setImageUrl(props?.data?.image);
    }
  }, [props?.usedForEdit, props?.data?.image]);

  return (
    <>
      <>
        {/* Card */}
        <div className="w-full mb-3 bg-white shadow-md rounded-md border flex flex-col items-start">
          <div className="border-b px-6 py-3 w-full">
            <h4 className="text-base font-inter font-semibold text-black">
              Courses Media
            </h4>
          </div>
          {/* Card body */}
          <div className="px-6 py-3 flex flex-col space-y-4 w-full">
            {/* Course cover image */}
            <label className="font-medium font-inter text-black/90 text-sm">
              Course cover image
            </label>
            <div className="flex flex-col space-y-1">
              <div className="flex items-center justify-between">
                <input
                  id="inputfavicon"
                  type="file"
                  className="px-4 py-2 outline-none border border-gray19 rounded-tl-md rounded-bl-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 flex-1 "
                  onChange={handleFileChange}
                />
                <label
                  className="cursor-pointer border border-gray19 outline-none rounded-tr-md rounded-br-md text-base text-gray20 px-4 py-2.5"
                  onClick={handleUpload}
                >
                  Upload
                </label>
              </div>
              <p className="text-xs text-gray20 font-inter font-medium">
                Upload your course image here. It must meet our course image
                quality standards to be accepted. Important guidelines; .jpg,
                .jpeg,. gif, or .png. no text on the image.
              </p>
              <p className="text-xs text-gray20 font-inter font-medium">
                Please click the upload button to upload
              </p>
              <p>
                {imageUploadingError?.show && (
                  <span className="login_error_text">
                    {imageUploadingError?.message}
                  </span>
                )}
              </p>
            </div>
            {/* Video URL  */}
            <div className="flex flex-col space-y-1 mt-1 mb-1">
              <input
                type="text"
                placeholder="Video URL"
                id="VideoURL"
                name="videoUrl"
                value={props?.data?.videoUrl}
                onChange={props.handleChange}
                className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full "
              />
              <p className="text-xs text-gray20 font-inter font-medium">
                Enter a valid video URL. Students who watch a well-made promo
                video are 5X more likely to enroll in your course.
              </p>
            </div>
            <div className="mb-3 mt-3">
              <input
                type="text"
                placeholder="Video Duration"
                id="VideoDuration"
                name="duration"
                value={props?.data?.duration}
                onChange={props.handleChange}
                className="px-4 py-2 outline-none border border-gray19 rounded-md text-sm font-inter font-medium placeholder:text-gray20 text-gray18 w-full"
              />
            </div>
            {imageUrl && (
              <img
                src={imageUrl}
                alt="courseImage"
                className="w-full h-28 object-contain"
              />
            )}
          </div>
        </div>

        {/* Button */}
        {props?.next && (
          <div className="flex justify-between">
            <button
              className="bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
              onClick={previous}
            >
              Previous
            </button>
            <button
              className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
              onClick={next}
            >
              Next
            </button>
          </div>
        )}
      </>

      {/* image uploading modal */}
      <ImageUploadingModal
        modalOpen={imageUploading}
        message={`Please wait while image is getting uploaded...`}
      />
    </>
  );
};
export default CoursesMedia;
