import React from "react";

const BlogDetailsHeader = ({ blogDetails }) => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center space-x-4 bg-gray35 dark:bg-darkBgColor1 px-4 py-5">
      <div className="md:basis-1/2 w-full px-6 py-4">
        <p className="text-sm text-purple20 font-urbanist dark:text-gray-300 mb-4">
          {new Date(blogDetails?.createdAt).toDateString()} -{" "}
          {blogDetails?.readTime}
        </p>
        <h1 className="text-3xl lg:text-4xl font-urbanist font-bold text-gray-800 dark:text-white mb-4">
          {blogDetails?.title}
        </h1>

        <p className="font-urbanist font-normal text-base text-gray30 block mb-5">
          {blogDetails?.shortDescription}
        </p>

        <div className="flex items-center space-x-2">
          <img
            src={blogDetails?.authorImage}
            alt="author_profile"
            className="h-8 w-8 rounded-full object-cover"
          />

          <div className=" flex flex-col space-y-1">
            <p className="font-semibold text-black1 dark:text-white text-sm">
              {blogDetails?.author}
            </p>
            <p className="font-normal font-urbanist text-sm text-gray30">
              {blogDetails?.authorDesignation}
            </p>
          </div>
        </div>
      </div>
      <div className="md:basis-1/2 w-full flex items-center justify-center">
        <img
          src={blogDetails?.image}
          alt="blog_image"
          className="w-[250px] h-[250px] md:w-[500px] md:h-[350px] object-cover rounded-2xl"
        />
      </div>
    </div>
  );
};

export default BlogDetailsHeader;
