import React, { useContext } from "react";
import { AppDataContext } from "../../context/appData";
import { DarkModeContext } from "../../context/darkModeContext";

const LearningExperienceSection = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  return (
    <div className="dark:bg-darkBgColor1 bg-gray25 px-4 md:px-14 lg:px-24 flex flex-col md:flex-row md:space-x-4 space-x-0 space-y-5 md:space-y-0 py-8 w-full">
      <div className="lg:basis-1/2 w-full max-w-xl flex items-center justify-center">
        <img
          src={
            darkMode
              ? appData?.homePageData?.learningExperienceSectionData
                  ?.sectionImgDark
              : appData?.homePageData?.learningExperienceSectionData?.sectionImg
          }
          alt="study_img"
          className="w-full h-full object-contain"
        />
      </div>

      <div className="md:basis-1/2 w-full flex flex-col items-center lg:items-start">
        <h1 className="text-xl md:text-2xl lg:text-3xl font-urbanist font-extrabold text-black2 dark:text-white block mb-5">
          {appData?.homePageData?.learningExperienceSectionData?.heading}
        </h1>

        {appData?.homePageData?.learningExperienceSectionData?.data?.map(
          (data, index) => (
            <div
              className="flex items-center space-x-1 w-full mb-3"
              key={index}
            >
              <div className="basis-1/5 w-full">
                <div className="h-16 w-16 sm:h-20 sm:w-20 flex items-center justify-center rounded-lg bg-gray31">
                  <img
                    src={data.img}
                    alt="icon"
                    className="h-10 w-10 object-contain"
                  />
                </div>
              </div>
              <div className="basis-4/5 w-full">
                <h3 className="text-black2 dark:text-white font-urbanist font-bold text-sm md:text-base">
                  {data.title}
                </h3>
                <p className="font-medium font-urbanist text-gray30 dark:text-white text-xs md:text-sm">
                  {data.description}
                </p>
              </div>
            </div>
          )
        )}
        <div></div>
      </div>
    </div>
  );
};

export default LearningExperienceSection;
