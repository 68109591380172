import React from "react";
import AvailableBotCard from "./AvailableBotCard/AvailableBotCard";

const AvailableBots = ({ pageData, setSelectedTab }) => {
  const handleCardClick = (index) => {
    if (index === 0) {
      setSelectedTab(5);
    } else if (index === 1) {
      setSelectedTab(6);
    } else if (index === 2) {
      setSelectedTab(7);
    } else if (index === 3) {
      setSelectedTab(8);
    } else if (index === 4) {
      setSelectedTab(9);
    } else if (index === 5) {
      setSelectedTab(10);
    } else if (index === 6) {
      setSelectedTab(11);
    }
  };
  return (
    <div className="mb-4 bg-background6 dark:bg-background7 rounded-lg py-4">
      <div className="flex flex-col space-y-4 px-4">
        <p className="font-inter font-semibold text-base dark:text-white text-blue25">
          {pageData?.dashboardTutorsData?.heading}
        </p>

        <div className="grid grid-cols-2 md:grid-cols-5 xl:grid-cols-7 gap-4">
          {pageData?.dashboardTutorsData?.availableTutors?.map(
            (data, index) => (
              <AvailableBotCard
                handleCardClick={handleCardClick}
                index={index}
                key={index}
                data={data}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default AvailableBots;
