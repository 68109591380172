// import node module libraries
import { Fragment } from "react";

const StepController = (props) => {
  const { currentStep, steps } = props;

  return (
    <section className="pb-12 bg-white shadow-md w-full px-4 md:px-10 xl:px-24">
      <div className="overflow-x-auto">
        <div className="flex flex-col">
          {/* Generating header for stepper */}
          <div className="flex items-center self-center justify-between w-full shadow-md py-4 px-6 rounded-b-md border overflow-x-auto">
            {steps.map((step, index) => {
              return (
                <Fragment key={step.id}>
                  <div className={`${step.id === currentStep ? "active" : ""}`}>
                    <button className="flex items-center space-x-2 font-inter font-normal text-gray-600 text-sm px-4 whitespace-nowrap">
                      <span
                        className={`rounded-full px-4 py-2 ${
                          step.id === currentStep
                            ? "bg-purple1 text-white"
                            : "bg-gray21"
                        }`}
                      >
                        {step.id}
                      </span>
                      <span className="stepper-label">{step.title}</span>
                    </button>
                  </div>
                  {steps.length > step.id ? (
                    <div className="border-b border-b-gray-300 w-full"></div>
                  ) : (
                    ""
                  )}
                </Fragment>
              );
            })}
          </div>

          {/* Showing content for active step */}

          <div className="stepper-content mt-5 ">
            <div className="stepper-pane fade active">
              {steps[currentStep - 1].content}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepController;
