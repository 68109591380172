import {
  AcademicCapIcon,
  BookOpenIcon,
  HandRaisedIcon,
  QuestionMarkCircleIcon,
  // TrophyIcon,
  HomeIcon,
  PowerIcon,
  BuildingLibraryIcon,
  // ArrowPathIcon,
  // BellIcon,
  // ChartPieIcon,
  // Cog6ToothIcon,
  // CurrencyDollarIcon,
  // LockClosedIcon,
  // ShoppingBagIcon,
  // StarIcon,
  // TrashIcon,
  // UserIcon,
  // UsersIcon,
} from "@heroicons/react/24/outline";
import {
  RectangleGroupIcon,
  CalculatorIcon,
  BeakerIcon,
  // ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/solid";

// instructor sidebar iitems
export const DashboardMenu = [
  {
    id: 1,
    title: "My Dashboard",
    link: "/instructor/dashboard",
    icon: HomeIcon,
  },
  {
    id: 2,
    title: "My Courses",
    link: "/instructor/instructor-my-courses/",
    icon: BookOpenIcon,
  },
  // {
  //   id: 3,
  //   title: "Reviews",
  //   link: "/instructor/instructor-reviews/",
  //   icon: StarIcon,
  // },
  // {
  //   id: 4,
  //   title: "Earnings",
  //   link: "/instructor/instructor-earnings/",
  //   icon: ChartPieIcon,
  // },
  // {
  //   id: 5,
  //   title: "Orders",
  //   link: "/instructor/instructor-orders/",
  //   icon: ShoppingBagIcon,
  // },
  // {
  //   id: 6,
  //   title: "Students",
  //   link: "/instructor/instructor-students/",
  //   icon: UsersIcon,
  // },
  // {
  //   id: 7,
  //   title: "Payouts",
  //   link: "/instructor/instructor-payouts/",
  //   icon: CurrencyDollarIcon,
  // },
  {
    id: 8,
    title: "Quiz",
    link: "/instructor/quiz/",
    icon: QuestionMarkCircleIcon,
  },
  // {
  //   id: 9,
  //   title: "Quiz Result",
  //   link: "/instructor/quiz/result/",
  //   icon: QuestionMarkCircleIcon,
  // },
];

export const AccountSettingsMenu = [
  // {
  //   id: 1,
  //   title: "Edit Profile",
  //   link: "/instructor/instructor-edit-profile/",
  //   icon: Cog6ToothIcon,
  //   value: "settings",
  // },
  // {
  //   id: 2,
  //   title: "Security",
  //   link: "/instructor/instructor-security/",
  //   icon: UserIcon,
  //   value: "user",
  // },
  // {
  //   id: 3,
  //   title: "Social Profiles",
  //   link: "/instructor/instructor-social-profiles/",
  //   icon: ArrowPathIcon,
  //   value: "socialProfiles",
  // },
  // {
  //   id: 4,
  //   title: "Notifications",
  //   link: "/instructor/instructor-notifications/",
  //   icon: BellIcon,
  //   value: "notifications",
  // },
  // {
  //   id: 5,
  //   title: "Profile Privacy",
  //   link: "/instructor/instructor-profile-privacy/",
  //   icon: LockClosedIcon,
  //   value: "profilePrivacy",
  // },
  // {
  //   id: 6,
  //   title: "Delete Profile",
  //   link: "/instructor/instructor-delete-profile/",
  //   icon: TrashIcon,
  //   value: "deleteProfile",
  // },
  {
    id: 7,
    title: "Sign Out",
    link: "/",
    icon: PowerIcon,
    value: "signout",
  },
];

//   user dashboard items import
export const TabsData = [
  {
    id: 1,
    text: "Dashboard",
    icon: RectangleGroupIcon,
  },
  // {
  //   id: 2,
  //   text: "All Courses",
  //   icon: BookOpenIcon,
  // },
  // {
  //   id: 3,
  //   text: "All Quizes",
  //   icon: QuestionMarkCircleIcon,
  // },
  // {
  //   id: 4,
  //   text: "Quiz Attempted",
  //   icon: TrophyIcon,
  // },
  {
    id: 5,
    text: "Math",
    icon: CalculatorIcon,
  },
  {
    id: 6,
    text: "Science",
    icon: BeakerIcon,
  },
  {
    id: 7,
    text: "English",
    icon: AcademicCapIcon,
  },
  {
    id: 8,
    text: "Social",
    icon: HandRaisedIcon,
  },
  {
    id: 9,
    text: "French",
    icon: AcademicCapIcon,
  },
  {
    id: 10,
    text: "Spanish",
    icon: AcademicCapIcon,
  },
  {
    id: 11,
    text: "Counselor",
    icon: BuildingLibraryIcon,
  },
];

// profile section items
export const profileSectionTabs = [
  {
    id: 0,
    text: "Profile",
    selectedValue: "profile",
  },
  {
    id: 1,
    text: "Photo",
    selectedValue: "photo",
  },
  {
    id: 2,
    text: "Subscriptions",
    selectedValue: "subscriptions",
  },
  {
    id: 3,
    text: "Payment Methods",
    selectedValue: "paymentMethods",
  },
  {
    id: 4,
    text: "Logout",
    selectedValue: "logout",
  },
];
