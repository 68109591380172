import React from "react";
import ShareBlog from "../ShareBlog/ShareBlog";
import BlogDetailsHeader from "./BlogDetailsHeader/BlogDetailsHeader";
import BlogConclusion from "./BlogConclusion/BlogConclusion";

const BlogDetailsCard = ({ blogDetails, hideSocialLink = false }) => {
  return (
    <div className="flex flex-col mb-8">
      {/* header */}
      <BlogDetailsHeader blogDetails={blogDetails} />
      {/* blog */}
      <div className="flex items-center justify-center py-10 px-4">
        <div className="w-full space-y-8 flex flex-col justify-center items-center">
          <div
            className={`blog_description font-urbanist text-base text-gray-700 dark:text-gray-300 leading-relaxed`}
            dangerouslySetInnerHTML={{ __html: blogDetails?.description }}
          />
          {Array.isArray(blogDetails?.content) ? (
            blogDetails?.content?.map((detail, index) => (
              <div
                className="flex flex-col md:flex-row items-center space-y-2 space-x-0 md:space-y-0 md:space-x-2"
                key={index}
              >
                <div className="flex flex-col space-y-2 md:basis-2/3 w-full">
                  <h3 className="font-semibold font-urbanist text-xl md:text-2xl block mb-3">
                    {detail?.subHeading}
                  </h3>
                  <p
                    className="blog_description font-urbanist text-base text-gray-700 dark:text-gray-300 leading-relaxed"
                    dangerouslySetInnerHTML={{ __html: detail?.content }}
                  />
                </div>
                <div className="md:basis-1/3 w-full px-5 py-5">
                  {detail?.subHeadingImage && (
                    <img
                      src={detail?.subHeadingImage}
                      alt="subheading_thumbnail"
                      className="w-full h-full max-h-[360px] object-contain"
                    />
                  )}
                </div>
              </div>
            ))
          ) : (
            <div
              className="blog_description max-w-xl font-urbanist text-base text-gray-700 dark:text-gray-300 leading-relaxed"
              dangerouslySetInnerHTML={{ __html: blogDetails?.content }}
            />
          )}

          {/* conclusion */}
          <BlogConclusion blogDetails={blogDetails} />

          {/* share blog */}
          {!hideSocialLink && <ShareBlog />}
        </div>
      </div>
    </div>
  );
};

export default BlogDetailsCard;
