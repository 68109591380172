import createDataContext from "../DataContext";
import reducer, { initialState } from "./reducer";
import { updateViewMode } from "./actions";

export const { Context: DarkModeContext, Provider: DarkModeProvider } =
  createDataContext(
    reducer,
    {
      updateViewMode,
    },
    initialState
  );
