import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";

const ShowFeedbackInputModal = ({
  showFeedbackInputModal,
  setShowFeedbackInputModal,
  feedbackResponseSubmitted,
  handleFeedbackSubmit,
  submittingFeedback,
}) => {
  const [feedback, setFeedback] = useState("");
  return (
    <CustomModal
      show={showFeedbackInputModal.show}
      onHide={() =>
        setShowFeedbackInputModal({ show: false, feedbackForChat: null })
      }
      centered
    >
      {showFeedbackInputModal.show &&
        (!feedbackResponseSubmitted ? (
          <div className="space-y-4 w-full px-4 py-3">
            <div className="flex items-center space-x-2 justify-between py-2 border-b border-b-gray-500">
              <p className="font-urbanist font-medium text-gray-700 dark:text-white">
                Please tell us your feedback
              </p>
              <XCircleIcon
                className="h-5 w-5 cursor-pointer text-gray-500 dark:text-white"
                onClick={() => setShowFeedbackInputModal(false)}
              />
            </div>

            <input
              placeholder="Enter feedback"
              className="px-2 py-2 dark:bg-purple17 dark:text-white bg-gray-100/70 rounded-md font-urbanist font-medium text-sm text-blue25 w-full outline-none"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />

            <div className="w-full flex items-center justify-center mt-5">
              <button
                className="w-full px-2 py-2 mt-4 max-w-xl font-urbanist font-bold text-white text-xs md:text-base xl:text-lg rounded-lg"
                onClick={() => handleFeedbackSubmit(feedback)}
                style={{
                  background: !submittingFeedback
                    ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                    : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                }}
                disabled={submittingFeedback}
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="px-5 py-5 flex items-center justify-between space-x-2 w-full">
            <p className="font-urbanist font-medium text-black1 dark:text-white">
              Your response has been submitted
            </p>
            <XCircleIcon
              className="h-5 w-5 cursor-pointer text-gray-500 dark:text-white"
              onClick={() =>
                setShowFeedbackInputModal({
                  show: false,
                  feedbackForChat: null,
                })
              }
            />
          </div>
        ))}
    </CustomModal>
  );
};

export default ShowFeedbackInputModal;
