import React from "react";
import InstructorNavigationMenu from "./InstructorNavigationMenu/InstructorNavigationMenu";

const InstructorSidebar = () => {
  return (
    <div className="flex flex-col bg-white shadow-md border rounded-md px-6 py-8">
      <InstructorNavigationMenu />
    </div>
  );
};

export default InstructorSidebar;
