import React, { useContext } from "react";
import AboutUs from "../components/AboutUs";
import { AppDataContext } from "../context/appData";

const WhatWeb = () => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);
  return (
    <div className="">
      <div className="bg-white dark:bg-darkBgColor1">
        <section className="pb-8 lg:pb-32">
          <AboutUs appData={appData} />
        </section>
      </div>
    </div>
  );
};

export default WhatWeb;
