import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

const BlogCard = (props) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/knowledge-center/${props?.urlSlug}`);
  };

  return (
    <div className="relative max-w-md w-full h-[500px] overflow-hidden rounded transform hover:scale-105 transition-transform duration-300 pb-16 z-10 shadow-lg bg-white/10">
      <img
        className="w-full h-60 object-cover rounded-lg"
        src={props?.image}
        alt={props?.title}
      />

      <div className="px-3 py-6 space-y-3 w-full h-full">
        <div className="flex items-center space-x-4 bg-gray23 dark:bg-purple22 py-2 px-4 w-max rounded-3xl">
          <span
            className="max-w-[150px] truncate inline-block rounded-full px-3 py-1 text-xs font-urbanist font-semibold text-white"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
          >
            {props?.recommendedFor}
          </span>

          <p className="text-purple20 font-urbanist font-medium text-sm">
            {props?.readTime} read
          </p>
        </div>
        <div
          className="flex items-start space-x-2 justify-between  mb-2"
          onClick={() => handleCardClick(props?.id)}
        >
          <p className="font-urbanist flex-1 font-semibold text-xl dark:text-white text-black1 cursor-pointer">
            {props?.title}
          </p>

          <ArrowUpRightIcon className="h-5 w-5 mt-2" />
        </div>

        <p
          className="font-urbanist font-normal dark:text-white text-gray30 text-sm line-clamp-2"
          dangerouslySetInnerHTML={{ __html: props?.description }}
        ></p>

        {/* author info */}
        <div className="absolute bottom-4 left-4 flex items-center space-x-2">
          <img
            src={props?.authorImage}
            alt="author_profile"
            className="h-8 w-8 rounded-full object-cover"
          />

          <div className=" flex flex-col space-y-1">
            <p className="font-semibold text-black1 dark:text-white text-sm">
              {props?.author}
            </p>
            <p className="font-urbanist font-medium text-gray30 text-sm">{`${new Date(
              props?.createdAt
            )?.toDateString()}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
