import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppDataContext } from "../../context/appData";
import Pagination from "../../components/InstructorCourses/Pagination/Pagination";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { Link } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import LevelIcon from "../../components/InstructorCourses/LevelIcon/LevelIcon";
import axios from "axios";
import { getURLs } from "../../urlConfig";
import { UserContext } from "../../context/user";
import { debounce, getSearchedCourses } from "../../utils/utils";

const tabs = ["All", "Approved", "Pending"];

const AdminAllCourse = () => {
  const [selectedCourseType, setSelectedCourseType] = useState("all");
  const [searchValue, setSearchValue] = useState("");

  const {
    state: { userInfo },
  } = useContext(UserContext);

  const {
    state: { allCoursesData },
    updateCourseData,
    initializeAllCoursesData,
  } = useContext(AppDataContext);
  const [currentCourses, setCurrentCourses] = useState(allCoursesData);
  const [approvedCourses, setApprovedCourses] = useState([]);
  const [pendingCourses, setPendingCourses] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const userRef = useRef(userInfo);

  useEffect(() => {
    userRef.current = userInfo;
  }, [userInfo]);

  const handleSearch = debounce((e) => {
    let updatedCourses;
    if (selectedCourseType === "all") {
      updatedCourses = getSearchedCourses(allCoursesData, e.target.value);
    } else if (selectedCourseType === "pending") {
      updatedCourses = getSearchedCourses(pendingCourses, e.target.value);
    } else if (selectedCourseType === "approved") {
      updatedCourses = getSearchedCourses(approvedCourses, e.target.value);
    }
    setCurrentCourses(updatedCourses);
  }, 200);

  const handleTabChange = (tabName) => {
    setSelectedCourseType(tabName);
    if (tabName === "all") {
      setCurrentCourses(allCoursesData);
    } else if (tabName === "pending") {
      const updatedData = allCoursesData?.filter(
        (data) => data.status === "pending"
      );
      setCurrentCourses(updatedData);
    } else if (tabName === "approved") {
      const updatedData = allCoursesData?.filter(
        (data) => data.status === "approved"
      );
      setCurrentCourses(updatedData);
    }
  };

  const handleStatusChange = (courseId, status) => {
    setDisableButton(true);
    axios
      .post(
        getURLs("change-status"),
        { courseId, status },
        {
          headers: {
            "auth-token": userRef?.current?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        // update status
        updateCourseData({ courseId, status });
        setDisableButton(false);
      })
      .catch((err) => {
        console.log(err);
        setDisableButton(false);
      });
  };

  useEffect(() => {
    if (allCoursesData?.length === 0 && Object.keys(userInfo).length) {
      axios
        .get(getURLs("get-all-courses"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          initializeAllCoursesData(res.data?.AllCoursesData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo, allCoursesData]);

  useEffect(() => {
    const pending = allCoursesData?.filter((data) => data.status === "pending");

    const approved = allCoursesData?.filter(
      (data) => data.status === "approved"
    );

    if (selectedCourseType === "all") {
      setCurrentCourses(allCoursesData);
    } else if (selectedCourseType === "pending") {
      setCurrentCourses(pending);
    } else if (selectedCourseType === "approved") {
      setCurrentCourses(approved);
    }

    setApprovedCourses(approved);
    setPendingCourses(pending);
  }, [allCoursesData]);

  const columns = useMemo(
    () => [
      {
        accessor: "image",
        Header: "Courses",
        Cell: ({ value, row }) => {
          return (
            <Link
              to={`/course-details/${row.original._id}`}
              state={row.original}
            >
              <div className="flex flex-col md:flex-row items-start space-x-0 space-y-2 md:space-y-0 md:space-x-4">
                <div>
                  <img
                    src={value}
                    alt="course_thumbnail"
                    className="rounded-md h-20 w-20 object-contain"
                  />
                  {/* </Link> */}
                </div>
                <div className="lg:ms-3 mt-2 lg:mt-0">
                  <h4 className="mb-1 text-base font-inter font-medium text-black">
                    {/* <Link to="#" className="text-inherit"> */}
                    {row?.original?.title}
                  </h4>
                  <div className="mb-0 flex items-center space-x-2">
                    <p className="flex items-center space-x-2">
                      <ClockIcon className="h-4 w-4 mr-2" />
                      {row?.original?.duration}
                    </p>
                    <p className="flex items-center space-x-2">
                      <LevelIcon level={row.original.level} />
                      {row?.original?.level}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        accessor: "creator",
        Header: "Instructor",
        Cell: ({ value, row }) => {
          return (
            <p className="font-inter font-normal text-sm text-gray20">
              {row.original.instructor_id.name}
            </p>
          );
        },
      },

      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value }) => {
          return (
            <div className="flex items-center space-x-2">
              <div
                className={`${
                  value === "Draft"
                    ? "info"
                    : value === "approved"
                    ? "bg-green-500"
                    : value === "rejected"
                    ? "bg-red-500"
                    : "bg-orange-500"
                } flex h-2 w-2 rounded-full`}
              ></div>
              <p>{value}</p>
            </div>
          );
        },
      },
      {
        accessor: "buttons",
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="flex items-center space-x-2">
              {row.original.status === "pending" ? (
                <div className="flex items-center space-x-2">
                  <button
                    className=" px-4 py-1 border rounded-md bg-inherit text-black/40 text-sm border-black/60 disabled:text-black/20"
                    onClick={() =>
                      handleStatusChange(row.original._id, "rejected")
                    }
                    disabled={disableButton}
                  >
                    Reject
                  </button>
                  <button
                    className="bg-green-600 rounded-md px-4 py-1 text-white font-medium text-sm disabled:bg-gray-400"
                    onClick={() =>
                      handleStatusChange(row.original._id, "approved")
                    }
                    disabled={disableButton}
                  >
                    Approve
                  </button>
                </div>
              ) : (
                <button
                  className="px-4 py-1 border rounded-md bg-gray22 text-white text-sm border-black/60 disabled:text-gray-300"
                  onClick={() =>
                    handleStatusChange(row.original._id, "pending")
                  }
                  disabled={disableButton}
                >
                  Change Status to Pending
                </button>
              )}

              <Link to={`/instructor/edit-course`} state={row?.original}>
                <button
                  className=" px-4 py-1 border rounded-md bg-inherit text-white text-sm bg-purple1"
                  disabled={disableButton}
                >
                  Edit Course
                </button>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => currentCourses, [currentCourses, disableButton]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    // setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 6,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex } = state;

  return (
    <div className="flex flex-col bg-bgColor3 px-4 py-5">
      <h1 className="font-inter font-semibold text-xl md:text-2xl block mb-4 text-black">
        All Courses
      </h1>
      {/* filter section */}
      <div className="bg-white rounded-md py-3">
        {/* tabs */}
        <div className="flex items-center space-x-4 border-b border-b-gray-400 px-4">
          {tabs.map((data, index) => (
            <span
              key={index}
              className={`py-4 ${
                selectedCourseType === data.toLowerCase()
                  ? "border-b-2 border-b-purple1 text-purple1"
                  : ""
              } font-inter font-normal text-gray20 text-sm cursor-pointer`}
              onClick={() => handleTabChange(data.toLowerCase())}
            >
              {data}
            </span>
          ))}
        </div>

        {/* search */}
        <form
          className="px-4 py-3 w-full"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <input
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch(e);
            }}
            placeholder="Search Courses"
            className="w-full px-2 py-2 border border-gray-300 rounded-md bg-white placeholder:text-gray20 text-gray20 outline-none text-sm"
          />
        </form>
      </div>

      <div className="p-0 pb-5 w-full">
        <div>
          <div className="overflow-x-auto">
            <table
              {...getTableProps()}
              className="whitespace-nowrap align-middle w-full min-w-[600px] overflow-x-auto"
              cellPadding={20}
              cellSpacing={10}
            >
              <thead className="bg-gray21 border-b">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="text-start text-sm font-inter font-medium text-black"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="bg-gray21">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="text-gray20 align-middle"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* Pagination @ Footer */}
        <Pagination
          previousPage={previousPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );
};

export default AdminAllCourse;
